import React, { memo, useState } from 'react'
import propTypes from 'prop-types'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CopyMaterialForm from '../../CopyMaterialForm'
import ThumbnailImage from '../../ThumbnailImage'
import { chooseMaterial } from '../../../store/books/bookAction'
import Button from '../../Button'
import ShareModal from '../../ShareBook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const PublicBooksList = ({ publicBooks }) => {
  const { trackEvent } = useMatomo()
  const [openShareModal, setOpenShareModal] = useState(false)
  const [openCopyModal, setOpenCopyModal] = useState(false)
  const [id, setId] = useState(null)
  const [oldName, setOldName] = useState(null)
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const books = useSelector((state) => state.books.books)

  const chooseBook = (id, oldName) => {
    setId(id)
    setOldName(oldName)
    setOpenCopyModal(true)
  }

  const closeCopyModal = () => {
    setOpenCopyModal(false)
    setOldName(null)
  }

  const shareBook = (id) => {
    dispatch(chooseMaterial(id))
    setOpenShareModal(true)
  }

  const viewStory = (id) => {
    dispatch(chooseMaterial(id))
    navigate('/esikatsele')
    trackEvent({ category: 'Kirja', action: `Muokattava kirja avattu (${id})` })
  }

  return (
    <>
      {books && publicBooks && publicBooks.length > 0 && (
        <ul className="book-list">
          {publicBooks.map((book) => {
            return (
              <li className="book-item" key={book.id}>
                <div className="book-container">
                  <div className="book-info-buttons-container">
                    <div className="book-info">
                      <span className="sr-only">Nimi: </span>
                      <h2 className="book-title">{book.name}</h2>
                      <p className="book-description">
                        <span className="sr-only">Kuvaus:</span>
                        {book.description}
                      </p>
                    </div>
                    <ThumbnailImage book={book} viewStory={viewStory} />
                  </div>

                  <div className="books-button-container">
                    <Button
                      onClick={() => viewStory(book.id)}
                      className="book-icon-button shared-book-icon-button"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'eye']}
                        className="edit-icon"
                        aria-hidden
                      />
                      Katso
                    </Button>
                    <Button
                      onClick={() => chooseBook(book.id, book.name)}
                      className="book-icon-button shared-book-icon-button"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'edit']}
                        className="edit-icon"
                        aria-hidden
                      />
                      Käytä pohjana
                    </Button>
                    <Button
                      onClick={() => {
                        shareBook(book.id)
                      }}
                      className="book-icon-button shared-book-icon-button"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'share-square']}
                        className="edit-icon"
                        aria-hidden
                      />
                      Jaa
                    </Button>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      )}
      {oldName && (
        <CopyMaterialForm
          openCopyModal={openCopyModal}
          closeCopyModal={closeCopyModal}
          id={id}
          oldName={oldName}
        />
      )}
      {openShareModal && (
        <ShareModal
          modalIsOpen={openShareModal}
          closeModal={() => setOpenShareModal(false)}
        />
      )}
    </>
  )
}

PublicBooksList.propTypes = {
  publicBooks: propTypes.instanceOf(Object),
}

PublicBooksList.defaultProps = {
  publicBooks: null,
}

export default memo(PublicBooksList)
