import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InstructionsSider from '../components/InstructionsSider'
import AddImage from '../components/AddImage'
import AddPageView from '../components/AddPageView'
import DeleteItemDialogue from '../components/DeleteItemDialogue'
import EditPage from '../components/EditPage'
import ModalContainer from '../components/ModalContainer'
import PagesSider from '../components/PagesSider'
import Templates from '../components/CreateBook/Templates'
import TopNavigation from '../components/Navigation/TopNavigation'
import { addAnonymousBook, updateBookState } from '../store/books/bookAction'
import { addCell } from '../store/cells/cellAction'
import { initializeAnonymousUser } from '../store/user/userAction'
import {
  addPage,
  deletePage,
  getNewCurrentPage,
  updatePage,
} from '../store/pages/pageAction'
import { addTextToState } from '../store/texts/textAction'
import { resetStore, rootLoadingReady } from '../store/root/rootAction'
import { anonymousGetId, getGridImageSizeAndPosition } from '../utils'

const TestPapuriContainer = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openColorModal, setOpenColorModal] = useState(false)
  const [openTemplateModal, setOpenTemplateModal] = useState(false)
  const [openDeletePageModal, setOpenDeletePageModal] = useState(false)
  const [showGrid, setShowGrid] = useState(false)
  const [deletedPageId, setDeletedPageId] = useState(null)
  const [openCardModal, setOpenCardModal] = useState(false)
  const pages = useSelector((state) => state.pages.pages)
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const chosenPage = useSelector((state) => state.pages.pages[deletedPageId])
  const texts = useSelector((state) => state.texts)
  const book = useSelector((state) => state.books.books['000'])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initializeAnonymousUser())
    dispatch(resetStore())
    dispatch(addAnonymousBook('000'))
    dispatch(rootLoadingReady())
    setDeletedPageId(null)
  }, [])

  const addCells = (page) => {
    let maxIndex
    if (page && page.type === 6) {
      maxIndex = 3
    } else if (page && page.type === 7) {
      maxIndex = 5
    } else if (page && page.type === 8) {
      maxIndex = 11
    }
    for (let i = 0; i <= maxIndex; i++) {
      const { x, y, w, h } = getGridImageSizeAndPosition(page.type, i)
      dispatch(
        addCell({
          pageId: page.id,
          imageId: null,
          soundId: null,
          position: i,
          x: x,
          y: y,
          w: w,
          h: h,
        })
      )
    }
  }

  const addNewTextArea = (page, x, y, width, height, resizable, draggable) => {
    const newTextArea = {
      id: anonymousGetId(texts),
      pageId: page ? page.id : currentPage.id,
      bgColor: 'transparent',
      x: x ? x : 0,
      y: y ? y : 0,
      w: width ? width : '30%',
      h: height ? height : '10%',
      z: 100,
      resizable,
      draggable,
      userId: null,
      html: '',
    }
    dispatch(addTextToState(newTextArea))
  }

  const addPositionedTextArea = (page, template) => {
    switch (template) {
      case 0:
        addNewTextArea(page, 0.52, 0.08, 0.425, 0.85, 0, 0)
        break
      case 1:
        addNewTextArea(page, 0.05, 0.08, 0.425, 0.85, 0, 0)
        break
      case 2:
        addNewTextArea(page, 0.18, 0.73, 0.64, 0.25, 0, 0)
        break
      default:
        break
    }
  }

  const setNewCurrentPage = () => {
    const filtered = Object.values(pages).filter((p) => p.id !== currentPage.id)
    if (currentPage.order === 0 && filtered.length >= 1) {
      dispatch(getNewCurrentPage(filtered[0].id))
    } else if (currentPage.order === 0 && filtered.length === 0) {
      dispatch(getNewCurrentPage(null))
    } else if (currentPage.order === filtered.length) {
      dispatch(getNewCurrentPage(filtered[filtered.length - 1].id))
    } else {
      dispatch(getNewCurrentPage(filtered[currentPage.order].id))
    }
    setDeletedPageId(null)
  }

  const deleteThisPage = () => {
    if (deletedPageId === currentPage.id) {
      setNewCurrentPage()
    }
    dispatch(deletePage(deletedPageId))
    updatePages()
    const updatedBook = {
      ...book,
      pages: book.pages.filter((p) => p !== deletedPageId),
    }
    dispatch(updateBookState(updatedBook))
    setOpenDeletePageModal(false)
  }

  const handleSubmit = (chosenTemplate) => {
    const template = parseInt(chosenTemplate)
    const newPage = {
      id: anonymousGetId(pages),
      order: Object.values(pages).length,
      type: template,
      bgColor: '#fff',
      bookId: null,
      texts: [],
      images: [],
      cells: [],
    }
    dispatch(addPage(newPage)).then(() => {
      addPositionedTextArea(newPage, template)
      if (template > 5) {
        addCells(newPage, template)
      }
    })
    setOpenTemplateModal(false)
  }

  const updatePages = () => {
    Object.values(pages)
      .filter((p) => p.id !== chosenPage.id)
      .forEach((page) => {
        if (page.order > chosenPage.order) {
          const updated = {
            ...page,
            order: page.order - 1,
          }
          dispatch(updatePage(updated))
        }
      })
  }

  return (
    <>
      <TopNavigation />
      <h1 className="sr-only">Kokeile Papuria</h1>
      {pages && currentPage && (
        <div className="edit-view">
          <InstructionsSider
            setOpenModal={setOpenModal}
            addNewTextArea={addNewTextArea}
            setOpenColorModal={setOpenColorModal}
            openTemplateModal={openTemplateModal}
            showGrid={showGrid}
            setShowGrid={setShowGrid}
          />
          <EditPage
            setModalIsOpen={setOpenModal}
            openColorModal={openColorModal}
            setOpenColorModal={setOpenColorModal}
            addNewTextArea={addNewTextArea}
            showGrid={showGrid}
          />
          <PagesSider
            setOpenTemplateModal={setOpenTemplateModal}
            openDeletePageModal={openDeletePageModal}
            setOpenDeletePageModal={setOpenDeletePageModal}
            setDeletedPageId={setDeletedPageId}
          />
          <>
            {openModal && (
              <ModalContainer
                modalIsOpen={openModal}
                closeModal={() => setOpenModal(false)}
                label="Valitse kuva tai video."
                styleName="choose-image"
              >
                <AddImage
                  closeModal={() => setOpenModal(false)}
                  openCardModal={openCardModal}
                  setOpenCardModal={setOpenCardModal}
                />
              </ModalContainer>
            )}
            {openDeletePageModal && (
              <DeleteItemDialogue
                openDeleteContentModal={openDeletePageModal}
                setOpenDeleteContentModal={setOpenDeletePageModal}
                deleteItem={deleteThisPage}
                itemId={deletedPageId}
                textContent={['Haluatko varmasti poistaa sivun?', 'Kyllä, poista']}
              />
            )}
          </>
        </div>
      )}
      {!currentPage && (
        <div className="edit-view">
          <div className="sider-container sider-instructions-container" tabIndex={0}>
            <div className="instructions-sider-content">
              <div className="instructions-sider-header">
                <h2 className="instructions-sider-title">Ohje</h2>
              </div>
              <div className="instructions-sider-text">
                <p>Kirjassa ei ole yhtään sivua.</p>
                <p>Voit lisätä uuden sivun painamalla Lisää sivu-napista.</p>
              </div>
            </div>
          </div>
          <AddPageView setOpenTemplateModal={setOpenTemplateModal} />
          <PagesSider
            setOpenTemplateModal={setOpenTemplateModal}
            openDeletePageModal={openDeletePageModal}
            setOpenDeletePageModal={setOpenDeletePageModal}
            setDeletedPageId={setDeletedPageId}
          />
        </div>
      )}
      {openTemplateModal && (
        <ModalContainer
          modalIsOpen={openTemplateModal}
          closeModal={() => setOpenTemplateModal(false)}
          label="Valitse sivupohja."
        >
          <Templates
            handleSubmit={handleSubmit}
            setOpenTemplateModal={setOpenTemplateModal}
          />
        </ModalContainer>
      )}
    </>
  )
}

export default TestPapuriContainer
