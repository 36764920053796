import { convertToObject } from '../../utils'
const initialState = {
  images: {},
  showAll: false,
}

export const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_IMAGES':
      if (!action.data) {
        return initialState
      } else {
        return {
          ...state,
          images: action.data,
        }
      }
    case 'ADD_IMAGE': {
      const id = action.data.id
      return {
        ...state,
        images: {
          ...state.images,
          [id]: action.data,
        },
      }
    }
    case 'TOGGLE_SHOW_IMAGES': {
      return {
        ...state,
        showAll: action.data,
      }
    }
    case 'UPDATE_IMAGE': {
      const key = action.data.id
      return {
        ...state,
        images: {
          ...state.images,
          [key]: action.data,
        },
      }
    }
    case 'UPDATE_IMAGES': {
      const images = { ...state.images }
      if (images && action.data) {
        const copyWithAddedImages = Object.values(images).concat(
          Object.values(action.data)
        )
        return {
          ...state,
          images: convertToObject(copyWithAddedImages),
        }
      }
      return state
    }
    case 'DELETE_IMAGE': {
      const images = { ...state.images }
      const filtered = Object.values(images).filter((p) => p.id !== action.data)
      return {
        ...state,
        images: convertToObject(filtered),
      }
    }
    case 'DELETE_PAGE': {
      const copy2 = Object.values(state.images).filter(
        (image) => image.pageId !== action.data
      )
      return {
        ...state,
        images: convertToObject(copy2),
      }
    }
    case 'PURGE':
      return initialState
    default:
      return state
  }
}
