import React, { memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import ModalContainer from '../../ModalContainer'
import './styles.scss'
import EditBookDetails from './EditBookDetails'
import { logoutUser } from '../../../store/user/userAction'

const EditPageHeader = ({ setOpenShareModal }) => {
  const { trackEvent } = useMatomo()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const downloadPdfLinkRef = React.useRef()

  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const previewBook = () => {
    navigate('/esikatsele')
    trackEvent({ category: 'Kirja', action: `Oma kirja avattu (${currentBookId})` })
  }

  const shareBook = () => {
    if (setOpenShareModal) setOpenShareModal(true)
  }

  const getActiveClasses = () => {
    if (!setOpenShareModal) {
      return 'page-header-icon-button inactive-button'
    }
    return 'page-header-icon-button'
  }

  const finishEditingBook = () => {
    if (user && user.anonymous) {
      dispatch(logoutUser())
    }
    navigate('/')
  }

  const downloadPdf = () => {
    if (downloadPdfLinkRef && downloadPdfLinkRef.current) {
      downloadPdfLinkRef.current.click()
      trackEvent({ category: 'Kirja', action: `PDF ladattu (${currentBookId})` })
    }
  }

  return (
    <>
      {user && book && book.id && (
        <div className="new-page-header-container">
          <div className="new-page-header-icons">
            <button
              className={getActiveClasses()}
              onClick={previewBook}
              aria-label="Esikatsele"
              tabIndex={0}
            >
              <div className="icon-button-content">
                <FontAwesomeIcon
                  icon={['far', 'eye']}
                  className="page-header-icon"
                  size="1x"
                />
                <span className="page-header-icon-text">Esikatsele</span>
              </div>
            </button>
            {setOpenShareModal ? (
              <>
                <button className="page-header-icon-button" onClick={downloadPdf}>
                  <div className="icon-button-content">
                    <FontAwesomeIcon
                      icon={['far', 'save']}
                      className="page-header-icon"
                    />
                    <span className="page-header-icon-text">
                      Tallenna{' '}
                      <span style={{ textTransform: 'uppercase' }}>&nbsp;pdf</span>
                    </span>
                  </div>
                </button>
                <a
                  style={{ display: 'none' }}
                  href={`${process.env.REACT_APP_BACKEND_URL}/books/${book.id}/pdf`}
                  download
                  ref={downloadPdfLinkRef}
                />
              </>
            ) : (
              <span className="page-header-icon-button inactive-button">
                <div className="icon-button-content">
                  <FontAwesomeIcon
                    icon={['far', 'save']}
                    className="page-header-icon"
                  />
                  <span className="page-header-icon-text">
                    Tallenna{' '}
                    <span style={{ textTransform: 'uppercase' }}>&nbsp;pdf</span>
                  </span>
                </div>
              </span>
            )}
            <button
              className={getActiveClasses()}
              onClick={() => shareBook()}
              tabIndex={0}
              aria-label="Jaa kirja"
            >
              <div className="icon-button-content">
                <FontAwesomeIcon
                  icon={['far', 'share-square']}
                  className="page-header-icon"
                  size="1x"
                />
                <span className="page-header-icon-text">Jaa</span>
              </div>
            </button>

            <button
              className={
                user && user.anonymous
                  ? 'page-header-icon-button inactive-button'
                  : 'page-header-icon-button'
              }
              onClick={() => setModalIsOpen(true)}
              tabIndex={0}
              aria-label="Muokkaa kirjan tietoja"
            >
              <div className="icon-button-content">
                <FontAwesomeIcon
                  icon={['far', 'edit']}
                  className="page-header-icon"
                  size="1x"
                />
                <span className="page-header-icon-text">Kirjan tiedot</span>
              </div>
            </button>
          </div>
          <button
            className="page-header-icon-button"
            onClick={finishEditingBook}
            tabIndex={0}
            aria-label="Sulje kirjan muokkaustila."
            role="link"
          >
            <div className="icon-button-content">
              <FontAwesomeIcon
                icon={['far', 'times-circle']}
                className="page-header-icon"
              />
              <span className="page-header-icon-text">Sulje kirja</span>
            </div>
          </button>
        </div>
      )}
      {modalIsOpen && book && book.name && (
        <ModalContainer
          modalIsOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          label="Muokkaa kirjan tietoja"
        >
          <EditBookDetails setModalIsOpen={setModalIsOpen} />
        </ModalContainer>
      )}
    </>
  )
}

EditPageHeader.propTypes = {
  setOpenShareModal: propTypes.func,
}

EditPageHeader.defaultProps = {
  setOpenShareModal: null,
}

export default memo(EditPageHeader)
