import React from 'react'
import OpikeLandingPage from '../components/OpikeLandingPage'

const OpikeContainer = () => {
  return (
    <div>
      <OpikeLandingPage />
    </div>
  )
}

export default OpikeContainer
