import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Button from '../Button'
import './styles.scss'
import ToolsSider from '../ToolsSider'
import { pageTypeIsGrid, pageTypeIsQuestion } from '../../utils'

const InstructionsSider = ({
  setOpenModal,
  setOpenColorModal,
  addNewTextArea,
  addNewBlock,
  addNewQuestion,
  openTemplateModal,
  showGrid,
  setShowGrid,
  removeVideo,
  setOpenUploadModal,
}) => {
  const [page, setPage] = useState(null)
  const [showInstructions, setShowInstructions] = useState(false)
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const location = useLocation()
  useEffect(() => {
    const regexForLink1 = /\/muokkaa/
    const regexForLink2 = /\/kokeile/
    if (location && location.pathname) {
      const path = location.pathname
      if (regexForLink1.test(path)) {
        setPage('/createpage')
      } else if (regexForLink2.test(path)) {
        setPage('/test')
      } else if (openTemplateModal) {
        setPage('/template')
      } else {
        setPage(location.pathname)
      }
    }
  }, [location, openTemplateModal, page])

  useEffect(() => {
    if (location && location.pathname && location.pathname === '/luo') {
      setShowInstructions(true)
    }
  }, [location])

  const getInstructionsForPage = () => {
    if (page) {
      switch (page) {
        case '/template':
          return <></>
        case '/createpage':
          return (
            <div>
              <Button
                className="toggle-instructions-button"
                onClick={() => setShowInstructions(!showInstructions)}
              >
                {showInstructions ? 'Näytä työkalut' : 'Näytä ohje'}
              </Button>
              {showInstructions ? (
                <>
                  {pageTypeIsGrid(currentPage.type) ? (
                    <div className="instructions-sider-text">
                      <p>Ruudukkopohjaan voi lisätä yhden kuvan ruutua kohden.</p>
                      <p>
                        Kuvapankkien kuvaan liitetään automaattisesti kuvateksti.
                        Kuvatekstin voi vaihtaa ruudun vasemmassa yläreunassa olevan
                        muokkausnapin kautta.
                      </p>
                      <p>
                        Saman muokkausnapin kautta pääsee myös nauhoittamaan ruutuun
                        äänen. Valmiissa ruudukossa ääni kuuluu, kun näpäyttää kuvaa.
                      </p>
                    </div>
                  ) : pageTypeIsQuestion(currentPage.type) ? (
                    <div className="instructions-sider-text">
                      <p>Kirjan sivut näkyvät näytön oikeassa reunassa.</p>
                      <p>Sivujen järjestystä voi vaihtaa raahaamalla.</p>
                      <p>
                        Sivun voi poistaa napsauttamalla ylänurkassa olevaa rastia.
                      </p>
                      <p>Uusia sivuja voi lisätä painamalla + -nappulaa.</p>
                      <p>
                        Kirjoita kyselyyn kysymys, sekä lisää oikeat ja väärät
                        vastausvaihtoehdot. Kirjan esikatselu- ja lukutilassa voit
                        vastata kysymykseen ja tarkistaa oikeat vastaukset.
                      </p>
                    </div>
                  ) : (
                    <div className="instructions-sider-text">
                      <p>Kirjan sivut näkyvät näytön oikeassa reunassa.</p>
                      <p>Sivujen järjestystä voi vaihtaa raahaamalla.</p>
                      <p>
                        Sivun voi poistaa napsauttamalla ylänurkassa olevaa rastia.
                      </p>
                      <p>Uusia sivuja voi lisätä painamalla + -nappulaa.</p>
                      <p>
                        Vapaassa pohjassa kuvia voi siirtää päällekkäin raahaamalla.
                        Viimeksi raahattu kuva siirtyy aina päällimmäiseksi.
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <ToolsSider
                  setOpenModal={setOpenModal}
                  setOpenColorModal={setOpenColorModal}
                  addNewTextArea={addNewTextArea}
                  addNewBlock={addNewBlock}
                  addNewQuestion={addNewQuestion}
                  showGrid={showGrid}
                  setShowGrid={setShowGrid}
                  setOpenUploadModal={setOpenUploadModal}
                  removeVideo={removeVideo}
                />
              )}
            </div>
          )
        case '/test':
          return (
            <div>
              <Button
                className="toggle-instructions-button"
                onClick={() => setShowInstructions(!showInstructions)}
              >
                {showInstructions ? 'Näytä työkalut' : 'Näytä ohje'}
              </Button>
              {showInstructions ? (
                <div className="instructions-sider-text">
                  <p>Kirjan sivut näkyvät näytön oikeassa reunassa.</p>
                  <p>Sivun voi poistaa napsauttamalla ylänurkassa olevaa rastia.</p>
                  <p>Uusia sivuja voi lisätä painamalla + -nappulaa.</p>
                  <p>
                    Kaikkia ominaisuuksia ei voi käyttää kokeiluversiossa.
                    Rekisteröitymällä voit tuoda omia ääniä, videoita ja kuvia, sekä
                    tallentaa ja jakaa tekemiäsi materiaaleja.
                  </p>
                </div>
              ) : (
                <ToolsSider
                  setOpenModal={setOpenModal}
                  setOpenColorModal={setOpenColorModal}
                  addNewTextArea={addNewTextArea}
                  addNewBlock={addNewBlock}
                  addNewQuestion={addNewQuestion}
                  showGrid={showGrid}
                  setShowGrid={setShowGrid}
                />
              )}
            </div>
          )
        default:
          return <></>
      }
    }
  }
  return (
    <div className="sider-container sider-instructions-container" tabIndex={0}>
      <div className="instructions-sider-content">
        <div className="instructions-sider-header">
          {showInstructions ? (
            <h2 className="instructions-sider-title">Ohje</h2>
          ) : (
            <h2 className="instructions-sider-title">Työkalut</h2>
          )}
        </div>
        {getInstructionsForPage()}
      </div>
    </div>
  )
}

InstructionsSider.propTypes = {
  setOpenModal: propTypes.func,
  addNewTextArea: propTypes.func,
  addNewBlock: propTypes.func,
  addNewQuestion: propTypes.func,
  setOpenColorModal: propTypes.func,
  openTemplateModal: propTypes.bool,
  showGrid: propTypes.bool,
  setShowGrid: propTypes.func,
  removeVideo: propTypes.func,
  setOpenUploadModal: propTypes.func,
}

InstructionsSider.defaultProps = {
  setOpenModal: () => {},
  addNewTextArea: () => {},
  setOpenColorModal: () => {},
  openTemplateModal: false,
  showGrid: false,
  setShowGrid: () => {},
  removeVideo: null,
  setOpenUploadModal: null,
}

export default memo(InstructionsSider)
