import React, { memo, useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Button'
import ValidatedTextInput from '../../ValidatedTextInput'
import { useForm } from 'react-hook-form'
import { getNewPassword } from '../../../api/users/users'

const ForgottenPassword = ({ setOpenPasswordModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [response, setResponse] = useState(null)

  const closeModal = () => {
    setOpenPasswordModal(false)
    setResponse(null)
  }

  const onSubmit = async (data) => {
    setResponse(null)
    try {
      const newpassword = data.email.trim().toLowerCase()
      const response = await getNewPassword(newpassword)
      setResponse(response)
    } catch (e) {
      setResponse(response)
    }
  }

  return (
    <div className="registration-form-container">
      <div className="login-form-content">
        <h1 className="login-page-header">Hanki uusi salasana</h1>
        <p>
          Voit tilata sähköpostiisi linkin, jonka kautta pääset vaihtamaan
          käyttäjätilisi salasanan. Anna sähköpostiosoite, jolla olet kirjautunut
          Papuriin.
        </p>
        <p>Jos et saa linkkiä sähköpostiisi, tarkista roskapostikansiosi.</p>
        <form className="flex-form" onSubmit={handleSubmit(onSubmit)}>
          <ValidatedTextInput
            label="Sähköpostiosoite"
            name="email"
            placeHolder="Kirjoita sähköpostiosoite..."
            labelClassName="hidden-label"
            inputClassName="text-input-field"
            {...register('email', { required: true })}
          />
          <div className="validation-error-text">
            {errors.email &&
              errors.email.type === 'required' &&
              'Kirjoita sähköpostiosoite.'}
          </div>
          <p
            className={
              response && response.error
                ? 'backend-response-text red-text'
                : 'backend-response-text success-text'
            }
          >
            {response && response.message}
          </p>
          <div className="modal-button-container">
            <Button type="submit" className="dialogue-button select-button">
              <div className="icon-button-content">
                <FontAwesomeIcon icon="sign-out-alt" className="info-icon" />
                Hanki uusi salasana
              </div>
            </Button>
            <Button className="dialogue-button gray-button" onClick={closeModal}>
              Sulje
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
ForgottenPassword.propTypes = {
  setOpenPasswordModal: propTypes.func.isRequired,
}

export default memo(ForgottenPassword)
