import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from '../../hooks/useDebounce'
import DraggableBlock from './DraggableBlock'
import { updateBook } from '../../store/books/bookAction'
import { updateBlock } from '../../store/blocks/blockAction'
import { removeEmptyHtmlTags } from '../../utils'
import './styles.scss'

const BlockContainer = ({ boxId, parent, setOpenModal, currentPage }) => {
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const blockbox = useSelector((state) => state.blocks[boxId])
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [editorContent, setEditorContent] = useState(null)
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (blockbox && blockbox.html && !editorContent) {
      setEditorContent(removeEmptyHtmlTags(blockbox.html))
    }
  }, [blockbox])

  const saveBlock = (html) => {
    if (blockbox) {
      const updated = {
        ...blockbox,
        html,
      }
      if (blockbox.html !== updated.html) {
        dispatch(updateBlock(updated))
        if (user && !user.anonymous) {
          const updatedBook = {
            ...book,
            updated: Date.now(),
          }
          dispatch(updateBook(updatedBook))
        }
      }
    }
    setEditorContent(html)
  }

  const debouncedEditorState = useDebounce(editorContent, 1000)

  const debouncedSaveBlock = async (html) => {
    saveBlock(html)
  }

  useEffect(() => {
    if (dirty) {
      debouncedSaveBlock(debouncedEditorState)
    }
  }, [debouncedEditorState])

  return (
    <>
      {blockbox && blockbox.draggable === 1 ? (
        <DraggableBlock
          boxId={blockbox.id}
          parent={parent}
          saveBlock={saveBlock}
          draggable={blockbox.draggable}
          editorContent={editorContent}
          setEditorContent={setEditorContent}
          setDirty={setDirty}
          setOpenModal={setOpenModal}
          pageType={currentPage?.type}
        />
      ) : null}
    </>
  )
}

BlockContainer.propTypes = {
  boxId: propTypes.number.isRequired,
  parent: propTypes.instanceOf(Object),
  setOpenModal: propTypes.func,
  currentPage: propTypes.instanceOf(Object),
}

BlockContainer.defaultProps = {
  parent: null,
}

export default memo(BlockContainer)
