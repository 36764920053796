import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { activateNewUser } from '../../../api/users/users'
import EditPassword from '../EditAccount/EditPassword'

const ActivateAccount = ({ userId, activationkey }) => {
  const [response, setResponse] = useState(null)

  const onSubmit = async (data) => {
    setResponse(null)
    const dataToSend = {
      key: activationkey,
      password1: data.password,
      password2: data.passwordConfirm,
    }
    const res = await activateNewUser(userId, dataToSend)
    setResponse(res)
  }

  return (
    <div className="centered-page-container">
      <div className="centered-page-content">
        <h2>Aktivoi käyttäjätunnus</h2>
        <p>
          Keksi itsellesi salasana ja kirjoita se molempiin allaoleviin kenttiin.
          Salasanan on oltava vähintään 8 merkkiä pitkä. Hyvässä salasanassa on
          pieniä ja isoja kirjaimia sekä erikoismerkkejä. Älä käytä samaa salasanaa
          kuin sähköpostitililläsi.
        </p>
        <EditPassword activate={true} onSubmit={onSubmit} response={response} />
        <Link className="link" to="/">
          Kirjautumissivulle
        </Link>
      </div>
    </div>
  )
}

ActivateAccount.propTypes = {
  userId: propTypes.string.isRequired,
  activationkey: propTypes.string.isRequired,
}

export default ActivateAccount
