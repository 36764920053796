import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { chooseOpikeMaterial } from '../../store/books/bookAction'
import Button from '../Button'
import './styles.scss'

const OpikeLandingPage = () => {
  const { key } = useParams()
  const opikeBookId = useSelector((state) => state.books.currentBook)
  const opikeBook = useSelector((state) => state.books.books[opikeBookId])
  const [bookName, setBookName] = useState(null)
  const [bookDesc, setBookDesc] = useState(null)
  const [bookUrl, setBookUrl] = useState(null)
  const [bookThumb, setBookThumb] = useState(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (key) {
      dispatch(chooseOpikeMaterial(key))
    }
  }, [key])

  useEffect(() => {
    if (opikeBook && opikeBook.url === key) {
      setBookName(opikeBook.name)
      setBookDesc(opikeBook.description)
      setBookUrl(`/lue/${opikeBook.id}/${opikeBook.url}`)
      setBookThumb(`/api/books/opike/thumb/${opikeBook.url}`)
    } else {
      setBookName(null)
      setBookDesc(null)
      setBookUrl(null)
      setBookThumb(null)
    }
  }, [opikeBook])

  return (
    <div className="opike-landing-page">
      <div className="opike-top-bar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55"
          height="55"
          viewBox="0 0 55 55"
        >
          <g fill="#000" fillRule="evenodd">
            <path d="M28.178 16.204h1.872V3.687h-1.872v12.517zM51.072 3.692h-6.536v12.504h6.519v-1.663H46.51v-4.011h4.342V8.926h-4.345V5.258h4.564V3.692zm-30.938 7.93c.316.015.545.03.774.037.922.027 1.82-.09 2.678-.45 2.68-1.126 2.994-4.334 1.931-5.924-.656-.982-1.633-1.504-2.761-1.595-1.353-.11-2.72-.042-4.079-.08-.412-.012-.522.12-.52.526.014 3.856.008 7.713.01 11.569 0 .164.016.327.024.492h1.943v-4.575zM35.562 9.4l-.141-.065V3.687h-1.933v12.501h1.945v-5.997l.126-.045c.11.134.225.264.329.403 1.328 1.786 2.653 3.574 3.984 5.359.087.117.208.294.318.297.807.026 1.615.014 2.491.014-.16-.214-.257-.352-.362-.483-1.504-1.886-3.005-3.774-4.517-5.654-.2-.248-.275-.42-.03-.708 1.079-1.274 2.132-2.57 3.193-3.86.484-.59.964-1.185 1.51-1.858-.743 0-1.375-.025-2.002.014-.21.013-.475.136-.605.298-1.182 1.47-2.339 2.959-3.505 4.441-.262.334-.534.661-.8.991zm-19.624.011c-.04-.34-.074-.978-.2-1.596-.44-2.174-1.65-3.67-3.877-4.193-2.505-.588-4.977.275-6.361 2.214-1.12 1.568-1.372 3.349-1.212 5.215.114 1.334.464 2.592 1.366 3.627 1.945 2.233 6.028 2.312 8.263.186 1.482-1.41 1.988-3.19 2.021-5.453zm38.994 21.63v15.15c-.03.132-.07.263-.087.397-.477 3.771-2.507 6.322-6.08 7.594-.976.348-2.021.502-3.035.744H.28C5.86 40.961 15.878 30.74 27.939 22.18c-.1.047-.159.07-.212.1-5.214 3.056-10.135 6.525-14.707 10.482A95.241 95.241 0 0 0 2.39 43.573c-.804.96-1.594 1.93-2.39 2.896V8.271c.048-.224.101-.448.144-.674.579-3.1 2.29-5.34 5.19-6.577C6.416.558 7.6.333 8.737 0h46.195v18.123c-.137-.016-.276-.059-.408-.042-1.548.201-3.107.344-4.639.631-4.88.913-9.48 2.636-13.89 4.905-5.01 2.58-9.56 5.81-13.675 9.648-4.084 3.81-7.628 8.067-10.414 12.924-1.313 2.287-2.405 4.67-2.976 7.264-.085.389-.14.784-.208 1.177.452-.642.804-1.306 1.158-1.969 1.526-2.862 3.277-5.566 5.562-7.894 4.323-4.406 9.265-7.93 14.873-10.519 4.554-2.1 9.304-3.488 14.314-3.914 2.99-.254 5.964-.15 8.91.473.46.096.928.157 1.393.234z"></path>
            <path d="M13.815 9.913c-.005 1.188-.2 2.233-.808 3.151-.805 1.215-1.983 1.67-3.403 1.539-1.41-.13-2.362-.912-2.823-2.212-.561-1.587-.627-3.215-.026-4.815.905-2.409 3.943-3.196 5.741-1.496.912.862 1.167 2.004 1.286 3.193.024.246.026.494.033.64M20.196 9.904V5.246c1.06-.046 2.133-.19 3.058.505 1.191.897.93 3.242-.42 3.928-.846.43-1.734.312-2.638.225"></path>
          </g>
        </svg>
      </div>
      <div className="opike-content">
        <div className="opike-col">
          <img
            className="opike-book-thumbnail"
            src={bookThumb}
            width="100%"
            height="auto"
            alt=""
          />
        </div>
        <div className="opike-col">
          <h2 className="h2-title">{bookName}</h2>
          <p>{bookDesc}</p>
          <Button
            className="opike-button"
            onClick={() => navigate(bookUrl, { state: { prevPage: 'opike' } })}
          >
            Avaa verkkokirja
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OpikeLandingPage
