import { convertToObject } from '../../utils'
const initialState = {}
export const textReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_TEXTS':
      if (!action.data) {
        return {}
      } else {
        return action.data
      }
    case 'ADD_TEXT': {
      const id = action.data.id
      return {
        ...state,
        [id]: action.data,
      }
    }
    case 'UPDATE_TEXT': {
      const key = action.data.id
      return {
        ...state,
        [key]: action.data,
      }
    }
    case 'UPDATE_TEXTS': {
      const texts = { ...state }
      if (texts && action.data) {
        const copyWithAddedTexts = Object.values(texts).concat(
          Object.values(action.data)
        )
        return convertToObject(copyWithAddedTexts)
      }
      return state
    }
    case 'DELETE_TEXT': {
      const texts = { ...state }
      const filtered = Object.values(texts).filter((p) => p.id !== action.data)
      return convertToObject(filtered)
    }
    case 'DELETE_PAGE': {
      const copy2 = Object.values(state).filter(
        (text) => text.pageId !== action.data
      )
      return convertToObject(copy2)
    }
    case 'PURGE':
      return initialState
    default:
      return state
  }
}
