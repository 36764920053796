import React, { memo } from 'react'
import propTypes from 'prop-types'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../Button'
import { logoutUser } from '../../../store/user/userAction'
import { startRootLoading } from '../../../store/root/rootAction'
import './styles.scss'

const LogOutModal = ({ setOpenModal }) => {
  const { trackEvent } = useMatomo()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const closeModal = () => {
    setOpenModal(false)
  }

  const exit = async () => {
    try {
      dispatch(startRootLoading())
      dispatch(logoutUser())
      navigate('/')
      trackEvent({ category: 'Käyttäjä', action: 'Kirjautui ulos' })
    } catch (e) {
      throw new Error(e)
    }
    closeModal()
  }

  return (
    <div className="logout-modal-content">
      <h2 style={{ fontSize: '1.5em' }}>Haluatko kirjautua ulos?</h2>
      <div className="modal-button-container">
        <Button className="dialogue-button select-button" onClick={exit}>
          Kirjaudu ulos
        </Button>
        <Button className="dialogue-button gray-button" onClick={closeModal}>
          Sulje
        </Button>
      </div>
    </div>
  )
}

LogOutModal.propTypes = {
  setOpenModal: propTypes.func.isRequired,
}

export default memo(LogOutModal)
