import React, { memo } from 'react'
import propTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import Button from '../Button'
import ModalContainer from '../ModalContainer'
import './styles.scss'

const ColorPicker = ({
  textbox,
  openColorModal,
  setOpenColorModal,
  changeFont,
  cancelAndCloseColorPicker,
  color,
  setColor,
  chooseColor,
}) => {
  const handleColorChange = (color) => {
    if (color === 'transparent') {
      setColor(color)
    } else {
      setColor(color.hex)
    }
  }

  return (
    <ModalContainer
      modalIsOpen={openColorModal}
      closeModal={() => setOpenColorModal(false)}
      label="Valitse väri"
      info
      className="color-modal"
    >
      <div className="color-picker-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        >
          <h1 className="color-picker-header">
            {changeFont ? 'Valitse tekstin väri' : 'Valitse taustaväri'}
          </h1>
          <div className="swatch-container">
            <div
              style={{
                width: '18px',
                height: '20px',
                borderRadius: '2px',
                background: color,
              }}
            />
          </div>
        </div>
        <SketchPicker
          color={color}
          onChangeComplete={handleColorChange}
          disableAlpha
          width="100%"
          presetColors={[
            '#D0021B',
            '#F5A623',
            '#F8E71C',
            '#8B572A',
            '#7ED321',
            '#417505',
            '#BD10E0',
            '#9013FE',
            '#4A90E2',
            '#50E3C2',
            '#B8E986',
            '#4A4A4A',
            '#9B9B9B',
            '#000000',
            '#FFFFFF',
          ]}
        />
        {!changeFont && textbox && (
          <Button
            className="dialogue-button gray-button single-button"
            onClick={() => handleColorChange('transparent')}
          >
            Läpinäkyvä tausta
          </Button>
        )}
        <div className="color-palette-buttons-container">
          <Button onClick={chooseColor} className="dialogue-button select-button">
            Valitse
          </Button>
          <Button
            onClick={cancelAndCloseColorPicker}
            className="dialogue-button gray-button"
          >
            Peruuta
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}

ColorPicker.propTypes = {
  textbox: propTypes.instanceOf(Object),
  openColorModal: propTypes.bool.isRequired,
  setOpenColorModal: propTypes.func.isRequired,
  changeFont: propTypes.bool.isRequired,
  cancelAndCloseColorPicker: propTypes.func,
  color: propTypes.string,
  setColor: propTypes.func.isRequired,
  chooseColor: propTypes.func.isRequired,
}

ColorPicker.defaultProps = {
  textbox: null,
  color: null,
  changeFont: false,
}

export default memo(ColorPicker)
