import {
  addAnswer as apiAddAnswer,
  updateAnswer as apiUpdateAnswer,
  deleteAnswer as apiDeleteAnswer,
} from '../../api/books/answers'
import { updateBook } from '../books/bookAction'

export const addAnswer = (answer) => {
  return async (dispatch, getState) => {
    const state = getState()
    const currentPage = state.pages.pages[answer.pageId]
    const currentPageAnswers = currentPage.answers.map((id) => state.answers[id])
    const currentAnswers = currentPageAnswers.filter(
      (el) => el.questionId === answer.questionId
    )
    const highestOrderInQuestion = (answers) => {
      if (!answers.length) return 0
      const orderValues = answers.map((el) => el.order)
      const highestOrderValue = Math.max(...orderValues)
      return highestOrderValue + 1
    }

    answer.correct = 1
    answer.order = highestOrderInQuestion(currentAnswers)
    answer.bgColor = 'transparent'
    const res = await apiAddAnswer(answer)
    dispatch({
      type: 'ADD_ANSWER',
      data: res.data,
    })
  }
}

export const addAnswerToState = (answer) => {
  return async (dispatch) => {
    dispatch({
      type: 'ADD_ANSWER',
      data: answer,
    })
  }
}

export const deleteAnswer = (id) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      await apiDeleteAnswer(id)
    }
    dispatch({
      type: 'DELETE_ANSWER',
      data: id,
    })
  }
}

export const initializeAnswers = (answers) => {
  return async (dispatch) => {
    dispatch({
      type: 'INIT_ANSWERS',
      data: answers,
    })
  }
}

export const updateAnswer = (updated) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiUpdateAnswer(updated)
      dispatch({
        type: 'UPDATE_ANSWER',
        data: res,
      })
    } else {
      dispatch({
        type: 'UPDATE_ANSWER',
        data: updated,
      })
    }
  }
}

export const updateAnswerStore = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_ANSWERS',
      data,
    })
  }
}

export const answerUpdateValueToCorrect = (answerId, book) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const allAnswers = state.answers
      const answer = allAnswers[answerId]
      if (answer && answer.correct === 0) {
        const updated = {
          ...answer,
          correct: 1,
        }
        const res = await apiUpdateAnswer(updated)
        dispatch({
          type: 'UPDATE_ANSWER',
          data: res,
        })
        const updatedBook = {
          ...book,
          updated: Date.now(),
        }
        dispatch(updateBook(updatedBook))
      }
    }
  }
}

export const answerUpdateValueToFalse = (answerId, book) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const allAnswers = state.answers
      const answer = allAnswers[answerId]
      if (answer && answer.correct === 1) {
        const updated = {
          ...answer,
          correct: 0,
        }
        const res = await apiUpdateAnswer(updated)
        dispatch({
          type: 'UPDATE_ANSWER',
          data: res,
        })
        const updatedBook = {
          ...book,
          updated: Date.now(),
        }
        dispatch(updateBook(updatedBook))
      }
    }
  }
}
