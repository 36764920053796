import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import DraggableQuestion from './DraggableQuestion'
import { useDebounce } from '../../hooks/useDebounce'
import { updateBook } from '../../store/books/bookAction'
import { updateQuestion } from '../../store/questions/questionAction'
import { removeEmptyHtmlTags } from '../../utils'
import './styles.scss'

const QuestionTool = ({ parent, questionId, currentPage }) => {
  const dispatch = useDispatch()

  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const question = useSelector((state) => state.questions[questionId])
  const user = useSelector((state) => state.user)

  const [editorContent, setEditorContent] = useState(null)
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (question && question.html && !editorContent) {
      setEditorContent(removeEmptyHtmlTags(question.html))
    }
  }, [question])

  const saveQuestion = (html) => {
    if (question) {
      const updated = {
        ...question,
        html,
      }
      if (question.html !== updated.html) {
        dispatch(updateQuestion(updated))
        if (user && !user.anonymous) {
          const updatedBook = {
            ...book,
            updated: Date.now(),
          }
          dispatch(updateBook(updatedBook))
        }
      }
    }
    setEditorContent(html)
  }

  /* Automaattinen viiveellä tallentaminen */
  const debouncedEditorState = useDebounce(editorContent, 1000)

  const debouncedSaveQuestion = async (html) => {
    saveQuestion(html)
  }

  useEffect(() => {
    if (dirty) {
      debouncedSaveQuestion(debouncedEditorState)
    }
  }, [debouncedEditorState])

  return (
    <>
      {question?.draggable === 1 ? (
        <DraggableQuestion
          questionId={question.id}
          parent={parent}
          saveQuestion={saveQuestion}
          editorContent={editorContent}
          setEditorContent={setEditorContent}
          setDirty={setDirty}
          pageType={currentPage?.type}
        />
      ) : null}
    </>
  )
}

QuestionTool.propTypes = {
  parent: propTypes.instanceOf(Object),
  questionId: propTypes.number.isRequired,
  currentPage: propTypes.instanceOf(Object),
}

QuestionTool.defaultProps = {
  parent: null,
}

export default memo(QuestionTool)
