import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import Button from '../../Button'
import AddImagePreview from '../AddImagePreview'
import dayjs from 'dayjs'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowImages } from '../../../store/images/imageAction'

const Recent = ({
  chooseImage,
  copyAndChooseImage,
  openCardModal,
  closeModal,
  setOpenCardModal,
  currentCellId,
}) => {
  const images = useSelector((state) => state.images.images)
  const blockImages = useSelector((state) => state.blockImages.images)
  const [chosenImage, setChosenImage] = useState(null)
  const [shownImages, setShownImages] = useState(null)
  const currentBookId = useSelector((state) => state.books.currentBook)
  const currentBook = useSelector((state) => state.books.books[currentBookId])
  const showAllImages = useSelector((state) => state.images.showAll)
  const dispatch = useDispatch()

  const previewImage = (result) => {
    setOpenCardModal(true)
    let url = result.url
    if (result.type === 0) {
      setChosenImage({ ...result, url: url })
    }
    setChosenImage(result)
  }

  const sortImagesByUpdateTimestamp = (a, b) => {
    const timeInMillisecondsA = dayjs(a.updated).valueOf()
    const timeInMillisecondsB = dayjs(b.updated).valueOf()
    return timeInMillisecondsB - timeInMillisecondsA
  }

  const removeDuplicatesAndSetImages = (list) => {
    let filenames = []
    let ids = []
    Object.values(list)
      .filter((i) => i.filename !== '//:0')
      .forEach((img) => {
        if (filenames.indexOf(img.filename) === -1) {
          filenames.push(img.filename)
          ids.push(img.id)
        }
      })
    setShownImages(list.filter((img) => ids.includes(img.id)))
  }

  useEffect(() => {
    if (currentBook && images && blockImages) {
      const combinedImages = Object.values(images).concat(Object.values(blockImages))
      const list = combinedImages
        .sort((a, b) => sortImagesByUpdateTimestamp(a, b))
        .filter((img) =>
          showAllImages ? true : currentBook.pages.includes(img.pageId)
        )
        .slice(0, 51)
      removeDuplicatesAndSetImages(list)
    }
  }, [currentBook, images, showAllImages])

  return (
    <Container>
      <Form className="search-buttons-container add-margins">
        <Form.Check
          type="checkbox"
          id="custom-checkbox"
          label="Näytä vain muokkaustilassa olevan kirjan kuvat"
          checked={!showAllImages}
          onChange={() => dispatch(toggleShowImages(!showAllImages))}
        />
      </Form>
      <Row xs={2} md={3}>
        {shownImages &&
          shownImages.map((image) => {
            return (
              <Col key={image.id}>
                <Button
                  className="choose-image-button"
                  onClick={() => previewImage(image)}
                >
                  <img
                    src={image.filename}
                    alt={image.alt}
                    width={image.width < image.height ? 'auto' : '100%'}
                    height="auto"
                    draggable="false"
                    className="image-result"
                  />
                </Button>
              </Col>
            )
          })}
      </Row>
      {openCardModal && (
        <AddImagePreview
          openCardModal={openCardModal}
          setOpenCardModal={setOpenCardModal}
          closeModal={closeModal}
          result={chosenImage}
          chooseImage={chooseImage}
          currentCellId={currentCellId}
          copyAndChooseImage={
            chosenImage && chosenImage.type === 1 ? copyAndChooseImage : null
          }
        />
      )}
    </Container>
  )
}

Recent.propTypes = {
  chooseImage: propTypes.func.isRequired,
  copyAndChooseImage: propTypes.func.isRequired,
  openCardModal: propTypes.bool.isRequired,
  closeModal: propTypes.func.isRequired,
  setOpenCardModal: propTypes.func.isRequired,
  currentCellId: propTypes.number,
}

Recent.defaultProps = {
  currentCellId: null,
}

export default memo(Recent)
