import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { HashRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store, persistor } from './store/store.js'
import * as serviceWorker from './serviceWorker'
import { ViewportProvider } from './hooks/useViewport'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import 'quill/dist/quill.snow.css'

const matomoInstance = createInstance({
  urlBase: 'https://stats.kvl.fi',
  siteId: 14,
  disabled: location.hostname === 'papuri.papunet.net' ? false : true,
})

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <Router>
          <ViewportProvider>
            <MatomoProvider value={matomoInstance}>
              <App />
            </MatomoProvider>
          </ViewportProvider>
        </Router>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
