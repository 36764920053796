import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import OwnMaterialList from './OwnBooksList'
import Button from '../Button'
import LoadingPage from '../LoadingPage'
import ModalContainer from '../ModalContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'
import CreateBook from '../CreateBook'
import {
  sortBooksAlphabetically,
  sortBooksInAscendingOrder,
} from '../../store/books/bookAction'

const OwnBooks = ({ deleteBook }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const books = useSelector((state) => (state.books ? state.books.books : {}))
  const loading = useSelector((state) => state.root.loading)
  const sortAlphabetically = useSelector((state) => state.books.sortAlphabetically)
  const sortAscending = useSelector((state) => state.books.sortAscending)
  const dispatch = useDispatch()

  const sortListAlphabeticallyAndToggleDirection = () => {
    if (!sortAlphabetically) {
      dispatch(sortBooksAlphabetically(true))
      dispatch(sortBooksInAscendingOrder(true))
    } else if (sortAlphabetically) {
      dispatch(sortBooksInAscendingOrder(!sortAscending))
    }
  }

  const sortListByLastUsedAndToggleDirection = () => {
    if (sortAlphabetically) {
      dispatch(sortBooksAlphabetically(false))
      dispatch(sortBooksInAscendingOrder(true))
    } else if (!sortAlphabetically) {
      dispatch(sortBooksInAscendingOrder(!sortAscending))
    }
  }

  const getClasses = () => {
    if (sortAlphabetically) {
      return 'sort-books-button sort-active-button'
    }
    return 'sort-books-button'
  }

  const getLastUsedClasses = () => {
    if (!sortAlphabetically) {
      return 'sort-books-button sort-active-button'
    }
    return 'sort-books-button'
  }

  return (
    <div className="two-part-page-container">
      <div className="two-part-page-content">
        <div className="instructions">
          <h1 className="page-header">Omat kirjat</h1>
          <p>
            Kun olet kirjautunut sisään, uusi kirja tallentuu automaattisesti omiin
            kirjoihisi.
          </p>
          <p>
            Löydät luomasi kirjat yläreunan &ldquo;Omat kirjat&rdquo;-painikkeen
            kautta aina, kun olet kirjautunut sisään.
          </p>
        </div>
        <div className="books-container">
          <div className="books-content">
            <div className="own-books-button-container">
              <Button
                className="select-button add-book-button"
                onClick={() => setModalIsOpen(true)}
              >
                <FontAwesomeIcon
                  icon={['far', 'plus-square']}
                  className="create-icon"
                  size="2x"
                />
                Luo uusi kirja
              </Button>
              <div className="sort-books-buttons-container">
                <Button
                  className={getLastUsedClasses()}
                  onClick={sortListByLastUsedAndToggleDirection}
                >
                  <FontAwesomeIcon
                    icon={
                      !sortAscending && !sortAlphabetically
                        ? 'arrow-up'
                        : 'arrow-down'
                    }
                    className="sort-books-icon"
                    size="2x"
                  />
                  Viimeksi käytetyt
                </Button>
                <Button
                  className={getClasses()}
                  onClick={sortListAlphabeticallyAndToggleDirection}
                >
                  <FontAwesomeIcon
                    icon={
                      sortAscending || !sortAlphabetically
                        ? 'sort-alpha-down'
                        : 'sort-alpha-down-alt'
                    }
                    className="sort-books-icon"
                    size="2x"
                  />
                  Aakkosjärjestys
                </Button>
              </div>
            </div>
          </div>
          {loading && <LoadingPage message="Ladataan kirjoja" />}
          {books && (
            <OwnMaterialList
              books={books}
              deleteBook={deleteBook}
              sortAlphabetically={sortAlphabetically}
              sortAscending={sortAscending}
            />
          )}
        </div>
      </div>
      {modalIsOpen && (
        <ModalContainer
          modalIsOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          label="Luo uusi kirja"
        >
          <CreateBook setModalIsOpen={setModalIsOpen} />
        </ModalContainer>
      )}
    </div>
  )
}

OwnBooks.propTypes = {
  deleteBook: propTypes.func.isRequired,
}

export default memo(OwnBooks)
