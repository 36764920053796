import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../../Button'
import ModalContainer from '../../../ModalContainer'
import ModalHeader from '../../../ModalHeader'
import UploadFile from '../../../UploadFile'
import ValidatedTextInput from '../../../ValidatedTextInput'
import { Tabs, Tab } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { updateCell } from '../../../../store/cells/cellAction'
import { updateImage } from '../../../../store/images/imageAction'
import { deleteSound } from '../../../../store/sounds/soundAction'

const EditCell = ({ openEditImageModal, setOpenEditImageModal, image, cell }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm()
  const dispatch = useDispatch()
  const [currentlyPlayingSound, setCurrentlyPlayingSound] = useState(false)
  //const [source, setSource] = useState(null)
  const [soundId, setSoundId] = useState(null)
  const sounds = useSelector((state) => state.sounds)
  const user = useSelector((state) => state.user)
  const audioRef = React.useRef()

  const closeWithoutSaving = () => {
    reset()
    setOpenEditImageModal(false)
  }

  const deleteSoundFromCell = (id) => {
    const updatedCell = { ...cell, soundId: null }
    dispatch(updateCell(updatedCell)).then(() => {
      dispatch(deleteSound(id))
    })
  }

  const onSubmit = (data) => {
    if (data) {
      const updated = {
        ...image,
        alt: data.alt,
        caption: data.caption,
      }
      dispatch(updateImage(updated))
    }
    if (soundId) {
      dispatch(updateCell({ ...cell, soundId: soundId }))
    }
    setOpenEditImageModal(false)
  }

  const togglePlaySound = () => {
    if (!currentlyPlayingSound) {
      audioRef.current.play()
      setCurrentlyPlayingSound(true)
    } else {
      audioRef.current.pause()
      setCurrentlyPlayingSound(false)
    }
  }

  return (
    <>
      {image && (
        <ModalContainer
          modalIsOpen={openEditImageModal}
          closeModal={() => setOpenEditImageModal(false)}
          label="Esikatsele ja lisää tekstivastine"
        >
          <div className="edit-cell-container">
            <ModalHeader
              headerText="Muokkaa tekstiä ja ääntä"
              onClick={() => setOpenEditImageModal(false)}
            />
            <Tabs defaultActiveKey="caption" id="uncontrolled-tab-example">
              <Tab eventKey="caption" title="Muokkaa tekstiä">
                <div className="add-alt-preview-image-container">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <ValidatedTextInput
                      onChange={() => {
                        clearErrors()
                      }}
                      label="Muokkaa kuvatekstiä."
                      name="caption"
                      labelClassName="input-label-class"
                      inputClassName="text-input-class"
                      value={image.caption || image.name}
                      {...register('caption', { maxLength: 255 })}
                    />
                    <div className="validation-error-text">
                      {errors.caption &&
                        errors.caption.type === 'maxLength' &&
                        'Kuvateksti voi olla korkeintaan 255 merkkiä pitkä.'}
                    </div>
                    <ValidatedTextInput
                      onChange={() => {
                        clearErrors()
                      }}
                      label="Muokkaa tekstivastinetta"
                      name="alt"
                      labelClassName="input-label-class"
                      inputClassName="text-input-class"
                      value={image.alt}
                      {...register('alt', { maxLength: 255 })}
                    />
                    <div className="validation-error-text">
                      {errors.alt &&
                        errors.alt.type === 'maxLength' &&
                        'Tekstivastine voi olla korkeintaan 255 merkkiä pitkä.'}
                    </div>
                    <small>
                      {' '}
                      Tekstivastine näkyy vain ruudunlukijaohjelman käyttäjille.
                    </small>
                    <div className="modal-button-container">
                      <Button
                        type="submit"
                        className="dialogue-button select-button"
                      >
                        Tallenna
                      </Button>
                      <Button
                        className="dialogue-button gray-button"
                        onClick={closeWithoutSaving}
                      >
                        Peruuta
                      </Button>
                    </div>
                  </form>
                </div>
              </Tab>
              <Tab eventKey="sound" title="Lisää kuvaan ääni.">
                {user && !user.anonymous ? (
                  <>
                    {cell && !cell.soundId ? (
                      <UploadFile
                        closeModal={() => {}}
                        type="audio"
                        text={[
                          'Sallitut tiedostomuodot: MP3, WAV, M4A,',
                          'AAC, OGG, 3GP, AMR, MOV, MP4, M4V.',
                        ]}
                        setSource={null}
                        setSoundId={setSoundId}
                        cell={cell}
                      />
                    ) : (
                      <div className="edit-cell-content">
                        <p>Kuvaan on lisätty ääni.</p>
                        <p>Esikuuntele:</p>
                        <Button
                          className="preview-audio-button"
                          onClick={() => togglePlaySound()}
                          label="Esikuuntele ääni."
                          style="text-align: 'center'"
                        >
                          {currentlyPlayingSound ? (
                            <FontAwesomeIcon
                              icon={['far', 'pause-circle']}
                              className="audio-icon"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="volume-up"
                              className="audio-icon"
                            />
                          )}
                          <audio
                            ref={audioRef}
                            src={sounds[cell.soundId].filename}
                            onEnded={() => setCurrentlyPlayingSound(false)}
                          />
                        </Button>
                        <Button
                          className="dialogue-button danger-button single-button"
                          onClick={() => deleteSoundFromCell(cell.soundId)}
                        >
                          Poista ääni
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <p style={{ marginTop: '2em' }}>
                    Ääniä voi lisätä vain kirjautuneena.
                  </p>
                )}
              </Tab>
            </Tabs>
          </div>
        </ModalContainer>
      )}
    </>
  )
}

EditCell.propTypes = {
  openEditImageModal: propTypes.bool.isRequired,
  setOpenEditImageModal: propTypes.func.isRequired,
  image: propTypes.instanceOf(Object).isRequired,
  cell: propTypes.instanceOf(Object).isRequired,
}

export default EditCell
