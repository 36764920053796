import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import Button from '../../Button'
import PreviewText from '../PreviewText'
import GridPage from '../../EditPage/GridPage'
import ReactPlayer from 'react-player'
import {
  getMP4,
  getWebM,
  getVideoThumbnail,
  pageTypeIsGrid,
  pageTypeIsFree,
  pageLayoutIsHorizontal,
  removeEmptyHtmlTags,
} from '../../../utils'
import { isMobile } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'
import { useViewport } from '../../../hooks/useViewport'
import {
  getParent,
  relativeFontSize,
  relativePaddingsForBlockRow,
} from '../../../utils'
import BlockImages from '../../Blocks/BlockImages'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import { ThumbsUp } from './ThumbsUp'

const PreviewPage = ({ page, hideCorrectAnswers }) => {
  const [parent, setParent] = useState(null)
  const [currentlyPlayingSound, setCurrentlyPlayingSound] = useState(null)
  const [playVideo, setPlayVideo] = useState(false)
  const [checkedAnswers, setCheckedAnswers] = useState([])
  const { width, height } = useViewport()

  const parentRef = React.useRef()
  const isChecked = (id) => (checkedAnswers.includes(id) ? true : false)

  useEffect(() => {
    setPlayVideo(false)
  }, [page])

  useEffect(() => {
    if (parentRef) {
      setParent(getParent(parentRef))
    }
  }, [page, parentRef, width, height])

  const handleAnswerStateChange = (id) => {
    const copyOfState = JSON.parse(JSON.stringify(checkedAnswers))
    if (copyOfState.includes(id)) {
      const index = copyOfState.indexOf(id)
      copyOfState.splice(index, 1)
      setCheckedAnswers(copyOfState)
    } else {
      copyOfState.push(id)
      setCheckedAnswers(copyOfState)
    }
  }

  const getHeight = (element) => {
    if (element.w === 0.3 && element.h === 0.3) {
      return 'auto'
    } else {
      return element.h * parent.height || element.height
    }
  }

  const getPositionAndSize = (element) => {
    if (element && parent) {
      const width = element.w * parent.width || element.width * parent.width
      const height = getHeight(element)
      return {
        position: 'absolute',
        top: element.y * parent.height,
        left: element.x * parent.width,
        width,
        height,
        backgroundColor: element.bgColor && element.bgColor,
        zIndex: element.z,
      }
    }
    return null
  }

  const getBlockPosition = (element) => {
    if (element && parent) {
      return {
        position: 'absolute',
        top: element.y * parent.height,
        left: element.x * parent.width,
        backgroundColor: element.bgColor && element.bgColor,
        zIndex: element.z,
      }
    }
    return null
  }

  const getSoundPosition = (sound) => {
    return {
      position: 'absolute',
      top: `${sound.y * 100}%`,
      left: `${sound.x * 100}%`,
    }
  }

  const togglePlaySound = (id) => {
    const element = document.getElementById(id)
    if (!currentlyPlayingSound) {
      element.play()
      setCurrentlyPlayingSound(id)
    } else {
      element.pause()
      setCurrentlyPlayingSound(null)
    }
  }

  const getPreviewWidth = () => {
    if (height > width) return '100vw'
    if (pageLayoutIsHorizontal(page.type) && width >= 2000) return '1400px'
    if (pageLayoutIsHorizontal(page.type) && width >= 1440) return '1000px'
    if (pageLayoutIsHorizontal(page.type)) return '130vh'
    if (!pageLayoutIsHorizontal(page.type)) return 'calc((100vh - 85px) / 1.414)'
  }

  const getPreviewHeight = () => {
    // Vaakapohjat
    if (pageLayoutIsHorizontal(page.type) && width >= 2000) return '875px'
    if (pageLayoutIsHorizontal(page.type) && width >= 1440) return '625px'
    if (pageLayoutIsHorizontal(page.type) && height > width) return '62.5vw'
    if (pageLayoutIsHorizontal(page.type)) return '81.25vh'
    // Pystypohjat
    // if (!pageLayoutIsHorizontal(page.type) && width >= 2000) return '1980px'
    // if (!pageLayoutIsHorizontal(page.type) && width >= 1440) return '1414px'
    if (!pageLayoutIsHorizontal(page.type) && height > width) return '141.4vw'
    if (!pageLayoutIsHorizontal(page.type)) return 'calc(100vh - 85px)'
  }

  const getClasses = () => {
    if (height > width || isMobile) return 'preview-page-content add-margins'
    return 'preview-page-content'
  }

  const getAnswerColor = (isCorrect) => {
    if (isCorrect) return '#94fd42'
    else return '#ff3f3f'
  }

  const getAnswerOutline = (isCorrect) => {
    if (isCorrect) return '3px solid black'
    else return 'none'
  }

  return (
    <div
      className={getClasses()}
      ref={parentRef}
      style={{ width: getPreviewWidth(), height: getPreviewHeight() }}
    >
      {page && parent && (
        <div
          style={{ backgroundColor: page.bgColor, width: '100%', height: '100%' }}
        >
          {page.blocks?.length
            ? page.blocks.map((block) => (
                <div
                  key={block.id}
                  className="blockbox-wrapper preview-remove-effects"
                  style={getBlockPosition(block)}
                >
                  <div
                    className="block-image-row"
                    style={{
                      fontSize: relativeFontSize(
                        block.scale,
                        parent.width,
                        parent.height,
                        page.type
                      ),
                      padding: relativePaddingsForBlockRow('image'),
                    }}
                  >
                    {page.blockImages?.filter((bi) => bi.blockId === block.id)
                      .length ? (
                      <BlockImages
                        images={page.blockImages
                          .filter((bi) => bi.blockId === block.id)
                          .sort((a, b) => a.order - b.order)}
                        pageWidth={parent.width}
                        pageHeight={parent.height}
                        scale={block.scale}
                        pageType={page.type}
                      />
                    ) : null}
                  </div>
                  {block.html ? (
                    <div
                      className="block-textbox preview-remove-effects"
                      style={{
                        fontSize: relativeFontSize(
                          block.scale,
                          parent.width,
                          parent.height,
                          page.type
                        ),
                      }}
                    >
                      <div
                        className="mce-content-body"
                        style={{
                          padding: relativePaddingsForBlockRow('text'),
                          minWidth: 'auto',
                          minHeight: 'auto',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: removeEmptyHtmlTags(block.html),
                        }}
                      ></div>
                    </div>
                  ) : null}
                </div>
              ))
            : null}
          {page.questions?.length
            ? page.questions.map((question) => (
                <div
                  key={question.id}
                  className="question-wrapper preview-remove-effects"
                  style={getBlockPosition(question)}
                >
                  {question.html ? (
                    <div
                      className="block-textbox preview-remove-effects"
                      style={{
                        fontSize: relativeFontSize(
                          question.scale,
                          parent.width,
                          parent.height,
                          page.type
                        ),
                      }}
                    >
                      <div
                        className="mce-content-body"
                        style={{
                          padding: relativePaddingsForBlockRow('text'),
                          minWidth: 'auto',
                          minHeight: 'auto',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: removeEmptyHtmlTags(question.html),
                        }}
                      ></div>
                      <div className="preview-answers-list-container">
                        {page.answers
                          ?.filter((el) => el.questionId === question.id)
                          .map((answer) => (
                            <div
                              className="preview-answer-container"
                              key={answer.id}
                            >
                              {!hideCorrectAnswers && answer.correct ? (
                                <span className="correct-answer-thumbs-up">
                                  <ThumbsUp />
                                </span>
                              ) : null}
                              <button
                                id={`answer-button-${answer.id}`}
                                className={
                                  isChecked(answer.id)
                                    ? 'preview-answer-button checked'
                                    : 'preview-answer-button'
                                }
                                onClick={() => handleAnswerStateChange(answer.id)}
                                style={{
                                  backgroundColor: hideCorrectAnswers
                                    ? answer.bgColor
                                    : getAnswerColor(answer.correct),
                                  outline: hideCorrectAnswers
                                    ? 'none'
                                    : getAnswerOutline(answer.correct),
                                  fontSize: relativeFontSize(
                                    question.scale * 0.8,
                                    parent.width,
                                    parent.height,
                                    page.type
                                  ),
                                }}
                              >
                                <>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: answer.html
                                        ? removeEmptyHtmlTags(answer.html)
                                        : '<p>&#8203;</p>',
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    icon={
                                      isChecked(answer.id) ? faCheckSquare : faSquare
                                    }
                                    className={
                                      isChecked(answer.id)
                                        ? 'answer-checked-icon'
                                        : 'answer-square-icon'
                                    }
                                    onClick={() =>
                                      handleAnswerStateChange(answer.id)
                                    }
                                  />
                                </>
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              ))
            : null}
          {page.images && page.images.length > 0 && !pageTypeIsGrid(page.type) ? (
            page.images.map((image) => (
              <div
                className="preview-image-container"
                key={image.id}
                style={getPositionAndSize(image)}
              >
                {image.draggable ? (
                  <img
                    src={image.filename}
                    alt={image.alt}
                    width={image.w ? '100%' : image.width}
                    height={image.h ? '100%' : image.height}
                    draggable="false"
                    tabIndex={0}
                  />
                ) : (
                  <img
                    src={image.filename}
                    alt={image.alt ? image.alt : ''}
                    width="100%"
                    height="100%"
                    className="uploaded-image-content"
                    tabIndex={0}
                  />
                )}
              </div>
            ))
          ) : (
            <>
              {pageTypeIsGrid(page.type) && <GridPage currentPreviewPage={page} />}
            </>
          )}
          {page.texts &&
            page.texts.length > 0 &&
            page.type !== 6 &&
            page.texts.map((box) => (
              <div key={box.id} style={getPositionAndSize(box)}>
                <div className="textbox-wrapper">
                  <PreviewText box={box} parent={getParent(parentRef)} />
                </div>
              </div>
            ))}
          {page.sounds &&
            page.sounds.length > 0 &&
            !pageTypeIsGrid(page.type) &&
            page.sounds.map((sound) => (
              <div
                className="audio-container"
                key={sound.id}
                style={getSoundPosition(sound)}
              >
                <Button
                  className="audio-button"
                  onClick={() => togglePlaySound(sound.id)}
                  key={sound.id}
                  label={
                    currentlyPlayingSound === sound.id
                      ? 'Lopeta äänen toisto.'
                      : 'Soita ääni.'
                  }
                >
                  {currentlyPlayingSound === sound.id ? (
                    <FontAwesomeIcon
                      icon={['far', 'pause-circle']}
                      className="audio-icon"
                      aria-hidden
                    />
                  ) : (
                    <FontAwesomeIcon icon="volume-up" className="audio-icon" />
                  )}
                  <audio
                    id={sound.id}
                    src={sound.filename}
                    onEnded={() => setCurrentlyPlayingSound(null)}
                  />
                </Button>
              </div>
            ))}
          {page.videos && page.videos.length > 0 && (
            <div
              className="video-container"
              style={getPositionAndSize(page.videos[0])}
              key={page.videos[0].id}
            >
              {!playVideo ? (
                <>
                  <Button
                    className="play-icon-button"
                    onClick={() => setPlayVideo(true)}
                  >
                    <FontAwesomeIcon
                      icon="play-circle"
                      className="play-button"
                      size="3x"
                      aria-hidden
                    />
                    <span className="sr-only">Toista video</span>
                  </Button>
                  <img
                    src={getVideoThumbnail(page.videos[0])}
                    alt=""
                    width={
                      page.videos[0].width >= page.videos[0].height ? '100%' : 'auto'
                    }
                    height="100%"
                    className={
                      !page.videos[0].draggable
                        ? 'thumbnail-video-content'
                        : undefined
                    }
                  />
                </>
              ) : (
                <ReactPlayer
                  url={[
                    getWebM(page.videos[0].filename),
                    getMP4(page.videos[0].filename),
                  ]}
                  controls
                  width="100%"
                  height={!pageTypeIsFree(page.type) ? '100%' : 'auto'}
                  tabIndex={0}
                  playing
                  onEnded={() => setPlayVideo(false)}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

PreviewPage.propTypes = {
  page: propTypes.instanceOf(Object).isRequired,
  hideCorrectAnswers: propTypes.bool,
}

export default memo(PreviewPage)
