import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import EditAccount from '../components/User/EditAccount'
import MainNavigationContainer from './MainNavigationContainer'
import { getUser } from '../api/users/users'
import { logoutUser } from '../store/user/userAction'

const EditAccountContainer = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    getUser().then((res) => {
      if (!res) {
        dispatch(logoutUser())
      }
    })
  }, [])
  return (
    <>
      <MainNavigationContainer />
      <EditAccount />
    </>
  )
}

export default memo(EditAccountContainer)
