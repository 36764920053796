export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_USER_TO_STATE':
      return action.data
    case 'USER_LOGIN_FAILED':
      return { ...state, error: action.data }
    case 'GET_USER':
      return state
    case 'REMOVE_USER_FROM_STATE':
      return action.data
    case 'UPDATE_USER':
      return action.data
    case 'USER_UPDATE_FAILED':
      return { ...state, error: action.data }
    default:
      return state
  }
}
