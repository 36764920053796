import {
  addBlockImage as apiAddBlockImage,
  deleteBlockImage as apiDeleteBlockImage,
  getBlockImages,
  updateBlockImage as apiUpdateBlockImage,
  blockImageMoveLeft as apiBlockImageMoveLeft,
  blockImageMoveRight as apiBlockImageMoveRight,
} from '../../api/books/blockImages'
import { anonymousGetId } from '../../utils'

export const addBlockImage = (url, width, height, alt, text, selectedBlockId) => {
  return async (dispatch, getState) => {
    const state = getState()
    const currentPageId = state.pages.currentPage
    const currentPage = state.pages.pages[currentPageId]
    const currentPageBlockImages = currentPage.blockImages.map(
      (id) => state.blockImages?.images[id]
    )
    const currentBlockImages = currentPageBlockImages.filter(
      (image) => image.blockId === selectedBlockId
    )
    const highestOrderInBlock = (blockImages) => {
      if (!blockImages.length) return 0
      const orderValues = blockImages.map((image) => image.order)
      const highestOrderValue = Math.max(...orderValues)
      return highestOrderValue + 1
    }
    const user = state.user
    const image = {
      blockId: selectedBlockId,
      pageId: currentPageId,
      userId: user.id,
      filename: url,
      size: 0,
      type: 0,
      order: highestOrderInBlock(currentBlockImages),
      width: width,
      height: height,
      alt: alt,
      caption: text,
    }
    if (!user.anonymous) {
      apiAddBlockImage(image).then((res) => {
        dispatch({
          type: 'ADD_BLOCK_IMAGE',
          data: res.data,
        })
      })
    } else {
      const imageWithId = { ...image, id: anonymousGetId(state.blockImages.images) }
      dispatch({
        type: 'ADD_BLOCK_IMAGE',
        data: imageWithId,
      })
    }
  }
}

export const deleteBlockImage = (id) => {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      if (state.user && !state.user.anonymous) {
        await apiDeleteBlockImage(id)
      }
      dispatch({
        type: 'DELETE_BLOCK_IMAGE',
        data: id,
      })
    } catch {
      dispatch({
        type: 'REMOVE_USER_FROM_STATE',
        data: null,
      })
    }
  }
}

export const initializeBlockImages = () => {
  return async (dispatch) => {
    const res = await getBlockImages()
    const imageList = Object.values(res.data).reduce(
      (list, a) => ({ ...list, [a.id]: a }),
      {}
    )
    dispatch({
      type: 'INIT_BLOCK_IMAGES',
      data: imageList,
    })
  }
}

export const toggleShowBlockImages = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'TOGGLE_SHOW_BLOCK_IMAGES',
      data,
    })
  }
}

export const updateBlockImage = (updated) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiUpdateBlockImage(updated)
      dispatch({
        type: 'UPDATE_BLOCK_IMAGE',
        data: res,
      })
    } else {
      dispatch({
        type: 'UPDATE_BLOCK_IMAGE',
        data: updated,
      })
    }
  }
}

export const blockImageMoveLeft = (imageId) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiBlockImageMoveLeft(imageId)
      dispatch({
        type: 'BLOCK_IMAGE_MOVE_LEFT',
        data: res,
      })
    }
  }
}

export const blockImageMoveRight = (imageId) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiBlockImageMoveRight(imageId)
      dispatch({
        type: 'BLOCK_IMAGE_MOVE_RIGHT',
        data: res,
      })
    }
  }
}

export const updateBlockImageStore = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_BLOCK_IMAGES',
      data,
    })
  }
}
