import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateBook } from '../../store/books/bookAction'
import { updatePage } from '../../store/pages/pageAction'
import PageHeader from './EditPageHeader'
import GridPage from './GridPage'
import Page from './Page'
import ColorPicker from '../ColorPicker'
import './styles.scss'
import { pageTypeIsGrid } from '../../utils'

const EditPage = ({
  setModalIsOpen,
  openColorModal,
  setOpenColorModal,
  showGrid,
  removeVideo,
  setOpenShareModal,
  setOpenModal,
}) => {
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const user = useSelector((state) => state.user)
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const [backgroundColor, setBackgroundColor] = useState(
    currentPage && currentPage.bgColor ? currentPage.bgColor : '#fff'
  )

  useEffect(() => {
    setBackgroundColor(currentPage.bgColor)
  }, [currentPage])

  const dispatch = useDispatch()
  const openModal = () => {
    setModalIsOpen(true)
  }

  const cancelAndCloseColorPicker = () => {
    setBackgroundColor(currentPage.bgColor)
    setOpenColorModal(false)
  }

  const saveBackgroundColor = () => {
    if (currentPage) {
      const updatedPage = { ...currentPage, bgColor: backgroundColor }
      dispatch(updatePage(updatedPage))
      if (user && !user.anonymous) {
        const updatedBook = {
          ...book,
          updated: Date.now(),
        }
        dispatch(updateBook(updatedBook))
      }
    }
    setOpenColorModal(false)
  }

  return (
    <div className="new-page-container" style={{ background: backgroundColor }}>
      {openColorModal && (
        <ColorPicker
          openColorModal={openColorModal}
          color={backgroundColor}
          setColor={setBackgroundColor}
          setOpenColorModal={setOpenColorModal}
          chooseColor={saveBackgroundColor}
          cancelAndCloseColorPicker={cancelAndCloseColorPicker}
        />
      )}
      <PageHeader setOpenShareModal={setOpenShareModal} />
      {pageTypeIsGrid(currentPage?.type) ? (
        <GridPage openModal={openModal} />
      ) : (
        <Page
          openModal={openModal}
          setOpenModal={setOpenModal}
          showGrid={showGrid}
          removeVideo={removeVideo}
        />
      )}
    </div>
  )
}

EditPage.propTypes = {
  modalIsOpen: propTypes.bool,
  setModalIsOpen: propTypes.func.isRequired,
  openColorModal: propTypes.bool.isRequired,
  setOpenColorModal: propTypes.func.isRequired,
  showGrid: propTypes.bool.isRequired,
  removeVideo: propTypes.func,
  setOpenShareModal: propTypes.func,
  setOpenModal: propTypes.func,
}

EditPage.defaultProps = {
  modalIsOpen: false,
  removeVideo: null,
  setOpenShareModal: null,
}

export default memo(EditPage)
