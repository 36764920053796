import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddImagePreview from '../AddImage/AddImagePreview'
import AudioInput from '../AudioInput'
import Button from '../Button'
import ModalHeader from '../ModalHeader'
import { addImage as apiAddImage } from '../../api/books/images'
import { addBlockImage as apiAddBlockImage } from '../../api/books/blockImages'
import { addSound as apiAddSound } from '../../api/books/sounds'
import { addVideo as apiAddVideo } from '../../api/books/videos'
import { deleteImage } from '../../store/images/imageAction'
import { addSound, addSoundToCell } from '../../store/sounds/soundAction'
import { addVideo, deleteVideo } from '../../store/videos/videoAction'
import { updateBook } from '../../store/books/bookAction'
import './styles.scss'
import { pageTypeIsGrid, pageTypeIsNotFreeOrGrid } from '../../utils'

const UploadFile = ({
  closeModal,
  text,
  type,
  chooseImage,
  setSource,
  cell,
  selectedBlockId,
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [warningText, setWarningText] = useState(null)
  const [file, setFile] = useState(null)
  const [showRecorder, setShowRecorder] = useState(false)
  const [chosenImage, setChosenImage] = useState(null)
  const [openCardModal, setOpenCardModal] = useState(false)
  const [percentageDownloaded, setPercentageDownloaded] = useState(0)
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const inputRef = useRef()
  const uploadNowRef = useRef()
  const handleClick = () => {
    inputRef.current.click()
  }

  useEffect(() => {
    if (file && uploadNowRef && uploadNowRef.current) {
      if (file.name.indexOf('.svg') === -1) {
        uploadNowRef.current.click()
      } else {
        setShowWarning(true)
        setWarningText('Kielletty tiedostomuoto.')
      }
    }
  }, [file, uploadNowRef])

  const uploadFile = async (e) => {
    e.preventDefault()
    let source = axios.CancelToken.source()
    const config = {
      cancelToken: source.token,
      onUploadProgress: (progressEvent) =>
        setPercentageDownloaded(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        ),
    }
    if (setSource) setSource(source)
    setShowWarning(false)
    const data = new FormData()
    data.append('file', file)
    if (file.size > 50000000) {
      setShowWarning(true)
    } else {
      if (file.type.indexOf('audio') !== -1) {
        try {
          setIsUploading(true)
          apiAddSound(data, config)
            .then((res) => {
              const newSound = {
                id: res.data.id,
                pageId: currentPageId,
                userId: user.id,
                draggable: cell ? 0 : 1,
                filename: res.data.filename,
                x: 0,
                y: 0,
              }
              if (cell) {
                dispatch(addSoundToCell(newSound, cell))
              } else {
                dispatch(addSound(newSound))
              }
              const updatedBook = {
                ...book,
                updated: Date.now(),
              }
              dispatch(updateBook(updatedBook))
              closeModal()
            })
            .then(() => {
              setIsUploading(false)
            })
        } catch (error) {
          setShowWarning(true)
          setWarningText('Tiedostoa ei voitu ladata.')
        }
      } else if (file.type.indexOf('video') !== -1) {
        if (currentPage.videos && currentPage.videos.length > 0) {
          setShowWarning(true)
          setWarningText(
            'Sivulla voi olla ainoastaan yksi video! Poista toinen video ensin.'
          )
        } else {
          try {
            setIsUploading(true)
            if (currentPage.videos && currentPage.videos.length > 0) {
              dispatch(deleteVideo(currentPage.videos[0]))
            }
            apiAddVideo(data, config).then((res) => {
              const newVideo = {
                pageId: currentPageId,
                userId: user.id,
                filename: res.data.filename,
                width: res.data.width,
                height: res.data.height,
                type: 1,
              }
              dispatch(addVideo(newVideo))
              if (
                res &&
                pageTypeIsNotFreeOrGrid(currentPage.type) &&
                currentPage.images.length > 0
              ) {
                dispatch(deleteImage(currentPage.images[0]))
              }
              const updatedBook = {
                ...book,
                updated: Date.now(),
              }
              dispatch(updateBook(updatedBook))
              closeModal()
            })
          } catch (error) {
            setShowWarning(true)
          }
        }
      } else {
        if (selectedBlockId !== undefined) {
          apiAddBlockImage(data).then((res) => {
            setChosenImage(res.data)
            setOpenCardModal(true)
          })
        } else {
          apiAddImage(data).then((res) => {
            setChosenImage(res.data)
            setOpenCardModal(true)
          })
        }
      }
    }
  }

  const getAcceptedFileTypes = () => {
    if (type === 'video') {
      return 'video/*'
    } else if (type === 'audio') {
      return 'audio/*, audio/mp3, audio/wav'
    }
    return 'image/*'
  }

  const getHeaderText = () => {
    if (type === 'audio') {
      return 'Lisää ääni'
    } else if (type === 'video') {
      return 'Lataa video'
    }
    return ''
  }

  const addRecordedAudio = (file) => {
    setShowWarning(false)
    const data = new FormData()
    data.append('file', file)
    let source = axios.CancelToken.source()
    const config = {
      cancelToken: source.token,
      onUploadProgress: (progressEvent) =>
        setPercentageDownloaded(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        ),
    }
    if (setSource) setSource(source)
    if (file.size > 50000000) {
      setShowWarning(true)
    } else {
      try {
        setIsUploading(true)
        apiAddSound(data, config).then((res) => {
          const newSound = {
            id: res.data.id,
            pageId: currentPageId,
            userId: user.id,
            draggable: cell ? 0 : 1,
            filename: res.data.filename,
            x: 0,
            y: 0,
          }
          if (cell) {
            dispatch(addSoundToCell(newSound, cell))
          } else {
            dispatch(addSound(newSound))
          }

          const updatedBook = {
            ...book,
            updated: Date.now(),
          }
          dispatch(updateBook(updatedBook))
          closeModal()
        })
      } catch (error) {
        setShowWarning(true)
        setWarningText('Tiedostoa ei voitu ladata.')
      }
    }
  }

  return (
    <div
      className={
        type !== 'image'
          ? 'file-upload-container'
          : 'file-upload-container margin-top-container'
      }
    >
      {isUploading ? (
        <div className="spinner-content">
          <FontAwesomeIcon icon="spinner" spin className="spinner-icon" />
          <p style={{ textAlign: 'center' }}>
            {' '}
            {percentageDownloaded === 100
              ? 'Muunnetaan tiedosto web-muotoon, odota...'
              : 'Ladataan...'}
          </p>
          <div className="progressbar">
            <span
              className="progressbar-block"
              style={{
                height: '24px',
                width: percentageDownloaded + '%',
              }}
            />
          </div>
          <p style={{ textAlign: 'center' }}>{percentageDownloaded}%</p>
          <Button
            className="dialogue-button gray-button single-button"
            onClick={closeModal}
            type="button"
            style="margin: 0"
          >
            Peruuta
          </Button>
        </div>
      ) : (
        <>
          {(type === 'audio' || type === 'video') &&
            !pageTypeIsGrid(currentPage.type) && (
              <ModalHeader headerText={getHeaderText()} onClick={closeModal} />
            )}
          {type === 'audio' && <p>Äänitä tai lataa äänitiedosto omalta koneelta.</p>}
          {!showRecorder && type === 'audio' && (
            <Button
              className="record-audio-button"
              onClick={() => setShowRecorder(true)}
              role="link"
            >
              <FontAwesomeIcon aria-hidden icon="microphone" size="2x" />
              <p className="sr-only">Äänitä</p>
            </Button>
          )}
          {showRecorder && (
            <AudioInput
              handleSubmit={(file) => addRecordedAudio(file)}
              setShowRecorder={setShowRecorder}
              showAudioModal={showRecorder}
            />
          )}
          {!showRecorder &&
            text.map((content, index) => <p key={index}>{content}</p>)}
          {!showRecorder && (
            <div className="centered-button-container">
              <Button
                className="dialogue-button select-button single-button"
                onClick={handleClick}
                type="button"
              >
                Valitse tiedosto
              </Button>
              {/*(type === 'audio' || type === 'video') && (
                <Button
                  className="dialogue-button gray-button"
                  onClick={closeModal}
                  type="button"
                >
                  Sulje
                </Button>
              )*/}
            </div>
          )}
          <form onSubmit={uploadFile} encType="multipart/form-data">
            <input
              type="file"
              name="file"
              ref={inputRef}
              accept={getAcceptedFileTypes()}
              hidden
              onChange={(e) => setFile(e.target.files[0])}
            />
            <input type="submit" value="Upload Now" hidden ref={uploadNowRef} />
          </form>
          {showWarning ? <p className="red-text">{warningText}</p> : <></>}
        </>
      )}
      {openCardModal && chosenImage && (
        <AddImagePreview
          openCardModal={openCardModal}
          setOpenCardModal={setOpenCardModal}
          closeModal={closeModal}
          result={chosenImage}
          chooseImage={chooseImage}
        />
      )}
    </div>
  )
}

UploadFile.propTypes = {
  closeModal: propTypes.func.isRequired,
  text: propTypes.instanceOf(Array).isRequired,
  type: propTypes.string,
  chooseImage: propTypes.func,
  setSource: propTypes.func,
  cell: propTypes.instanceOf(Object),
  selectedBlockId: propTypes.number,
}

UploadFile.defaultProps = {
  type: 'image',
  chooseImage: null,
  setSource: null,
  cell: null,
}

export default UploadFile
