import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import propTypes from 'prop-types'

const LoadingPage = ({ message }) => {
  return (
    <>
      <div
        className="spinner-content"
        style={{
          backgroundColor: 'white',
          width: '100vw',
          height: '100vh',
          minHeight: '100%',
          margin: 0,
          padding: '15%',
          position: 'absolute',
          top: '30',
          left: 0,
        }}
      >
        <FontAwesomeIcon icon="spinner" spin className="spinner-icon" size="2x" />
        <p>{message}...</p>
      </div>
    </>
  )
}

LoadingPage.propTypes = {
  message: propTypes.string.isRequired,
}

export default LoadingPage
