import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'
import LoadingPage from '../LoadingPage'
import PublicBooksList from './PublicBooksList'

const PublicBooks = ({ books }) => {
  const loading = useSelector((state) => state.root.loading)
  return (
    <div className="two-part-page-container">
      <div className="two-part-page-content">
        <div className="instructions">
          <h1 className="page-header">Muokattavat kirjat</h1>
          <p>Täältä löydät ammattilaisten luomia valmiita kirjoja.</p>
          <p>Voit aloittaa oman kirjan käyttämällä esimerkkejä pohjana.</p>
          <p>Voit muokata kirjaa haluamaksesi.</p>
          <p>
            Muokkaaminen aloitetaan valitsemalla toiminto <i>Käytä pohjana.</i>
          </p>
        </div>
        <div className="books-content">
          {loading ? (
            <LoadingPage message="Ladataan kirjoja" />
          ) : (
            <PublicBooksList publicBooks={books} />
          )}
        </div>
      </div>
    </div>
  )
}

PublicBooks.propTypes = {
  books: propTypes.instanceOf(Object).isRequired,
}

export default memo(PublicBooks)
