import { convertToObject } from '../../utils'
const initialState = {}

export const cellReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_CELLS':
      if (!action.data) {
        return initialState
      } else {
        return {
          ...state,
          cells: action.data,
        }
      }
    case 'ADD_CELL': {
      const id = action.data.id
      return {
        ...state,
        [id]: action.data,
      }
    }
    case 'UPDATE_CELL': {
      const key = action.data.id
      return {
        ...state,
        [key]: action.data,
      }
    }
    case 'UPDATE_CELLS': {
      if (!action.data) {
        return state
      } else {
        const cells = { ...state }
        const copyWithAddedCells = Object.values(cells).concat(
          Object.values(action.data)
        )
        return convertToObject(copyWithAddedCells)
      }
    }
    case 'DELETE_PAGE': {
      const cells = Object.values(state).filter(
        (sound) => sound.pageId !== action.data
      )
      return convertToObject(cells)
    }
    case 'PURGE':
      return initialState
    default:
      return state
  }
}
