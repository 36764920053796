import React, { memo } from 'react'
import propTypes from 'prop-types'
import Button from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ThumbnailImage = ({ book, viewStory }) => {
  return (
    <>
      {book && (
        <div className="thumbnail" width="170px">
          <Button
            onClick={() => viewStory(book.id)}
            className="thumbnail-button"
            label={`Esikatsele ${book.name}`}
            role="link"
          >
            <div className="view-book-icon-container" aria-hidden>
              <FontAwesomeIcon
                icon={['far', 'eye']}
                className="view-book-icon"
                size="1x"
              />
            </div>
            <img
              className="thumbnail-image"
              src={`/api/books/${book.id}/thumb`}
              width="100%"
              height="auto"
              alt=""
            />
          </Button>
        </div>
      )}
    </>
  )
}

ThumbnailImage.propTypes = {
  book: propTypes.instanceOf(Object).isRequired,
  viewStory: propTypes.func.isRequired,
}

export default memo(ThumbnailImage)
