import { convertToObject } from '../../utils'

const initialState = {
  pages: {},
  currentPage: null,
}

export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_PAGES':
      if (action.data.pages === undefined) {
        return { pages: {}, currentPage: null }
      } else if (state.pages && state.pages.pages && state.pages.pages.length > 0) {
        return {
          ...state,
          pages: {
            ...state.pages,
            [action.data.id]: action.data,
          },
        }
      } else {
        return action.data
      }
    case 'ADD_MATERIAL':
      return {
        ...state,
        currentPage: null,
      }
    case 'ADD_PAGE': {
      const newPage = {
        ...action.data,
        blocks: action.data.blocks || [],
        blockImages: action.data.blockImages || [],
        questions: action.data.questions || [],
        answers: action.data.answers || [],
        texts: action.data.texts || [],
        images: action.data.images || [],
        sounds: action.data.sounds || [],
        cells: action.data.cells || [],
        video: action.data.video || null,
      }
      const pagesWithAddedPage = Object.values(state.pages).concat(newPage) || [
        newPage,
      ]
      return {
        ...state,
        pages: convertToObject(pagesWithAddedPage),
        currentPage: newPage.id,
      }
    }
    case 'DELETE_PAGE': {
      const filtered = Object.values(state.pages).filter((p) => p.id !== action.data)
      return {
        ...state,
        pages: convertToObject(filtered),
      }
    }
    case 'ADD_TEXT': {
      const copyOfCurrentTexts = state.pages[state.currentPage].texts ?? {}
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            texts: copyOfCurrentTexts.concat(action.data.id),
          },
        },
      }
    }
    case 'DELETE_TEXT': {
      const filteredTexts = state.pages[state.currentPage].texts.filter(
        (t) => t !== action.data
      )
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            texts: filteredTexts.length > 0 ? filteredTexts : [],
          },
        },
      }
    }
    case 'ADD_BLOCK': {
      const copyOfCurrentBlocks = state.pages[state.currentPage].blocks ?? {}
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            blocks: copyOfCurrentBlocks.concat(action.data.id),
          },
        },
      }
    }
    case 'DELETE_BLOCK': {
      const filteredBlocks = state.pages[state.currentPage].blocks.filter(
        (t) => t !== action.data
      )
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            blocks: filteredBlocks.length > 0 ? filteredBlocks : [],
          },
        },
      }
    }
    case 'ADD_BLOCK_IMAGE': {
      const copyOfCurrentBlockImages =
        state.pages[state.currentPage].blockImages ?? {}
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            blockImages: copyOfCurrentBlockImages.concat(action.data.id),
          },
        },
      }
    }
    case 'DELETE_BLOCK_IMAGE': {
      const filteredImages = state.pages[state.currentPage].blockImages.filter(
        (t) => t !== action.data
      )
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            blockImages: filteredImages.length > 0 ? filteredImages : [],
          },
        },
      }
    }
    case 'ADD_QUESTION': {
      const copyOfCurrentQuestions = state.pages[state.currentPage].questions ?? {}
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            questions: copyOfCurrentQuestions.concat(action.data.id),
          },
        },
      }
    }
    case 'DELETE_QUESTION': {
      const filteredQuestions = state.pages[state.currentPage].questions.filter(
        (t) => t !== action.data
      )
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            questions: filteredQuestions.length > 0 ? filteredQuestions : [],
          },
        },
      }
    }
    case 'ADD_ANSWER': {
      const copyOfCurrentAnswers = state.pages[state.currentPage].answers ?? {}
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            answers: copyOfCurrentAnswers.concat(action.data.id),
          },
        },
      }
    }
    case 'DELETE_ANSWER': {
      const filteredAnswers = state.pages[state.currentPage].answers.filter(
        (t) => t !== action.data
      )
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            answers: filteredAnswers.length > 0 ? filteredAnswers : [],
          },
        },
      }
    }
    case 'ADD_IMAGE': {
      const copyOfCurrentImages = state.pages[state.currentPage].images ?? {}
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            images: copyOfCurrentImages.concat(action.data.id),
          },
        },
      }
    }
    case 'DELETE_IMAGE': {
      const filteredImages = state.pages[state.currentPage].images.filter(
        (t) => t !== action.data
      )
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            images: filteredImages.length > 0 ? filteredImages : [],
          },
        },
      }
    }
    case 'ADD_SOUND': {
      const copyOfCurrentSounds = state.pages[state.currentPage].sounds ?? {}
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            sounds: copyOfCurrentSounds.concat(action.data.id),
          },
        },
      }
    }

    case 'DELETE_SOUND': {
      const filteredSounds = state.pages[state.currentPage].sounds.filter(
        (t) => t !== action.data
      )
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            sounds: filteredSounds.length > 0 ? filteredSounds : [],
          },
        },
      }
    }

    case 'ADD_CELL': {
      const copyOfCurrentCells = state.pages[state.currentPage].cells ?? {}
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            cells: copyOfCurrentCells.concat(action.data.id),
          },
        },
      }
    }

    case 'DELETE_CELL': {
      const filteredCells = state.pages[state.currentPage].cells.filter(
        (c) => c !== action.data
      )
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            sounds: filteredCells.length > 0 ? filteredCells : [],
          },
        },
      }
    }

    case 'ADD_VIDEO': {
      const copyOfCurrent = state.pages[state.currentPage].videos ?? {}
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            videos: copyOfCurrent.concat(action.data.id),
          },
        },
      }
    }

    case 'DELETE_VIDEO': {
      const filteredVideos = state.pages[state.currentPage].videos.filter(
        (t) => t !== action.data
      )
      return {
        ...state,
        pages: {
          ...state.pages,
          [state.currentPage]: {
            ...state.pages[state.currentPage],
            videos: filteredVideos.length > 0 ? filteredVideos : [],
          },
        },
      }
    }

    case 'UPDATE_PAGE': {
      const id = action.data.id
      const copy = Object.values(state.pages).map((p) =>
        p.id !== id ? p : action.data
      )
      return { ...state, pages: convertToObject(copy) }
    }

    case 'UPDATE_PAGES': {
      const copyWithAddedPages = Object.values(state.pages).concat(
        Object.values(action.data.pages)
      )
      return { ...state, pages: convertToObject(copyWithAddedPages) }
    }

    case 'GET_NEW_CURRENT_PAGE':
      return { ...state, currentPage: action.data }
    case 'CHOOSE_MATERIAL':
      return { ...state, currentPage: null }
    case 'REMOVE_USER_FROM_STATE': {
      return { pages: {}, currentPage: null }
    }
    case 'PURGE':
      return initialState
    default:
      return state
  }
}
