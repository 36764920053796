import React, { memo, useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Pagination from 'react-bootstrap/Pagination'
import propTypes from 'prop-types'
import Button from '../../Button'
import ErrorPage from '../../ErrorPage'
import LoadingPage from '../../LoadingPage'
import PreviewPage from '../PreviewPage'
import { getBookById, getBookByIdAndUrl } from '../../../api/books/books'
import { getPageById } from '../../../api/books/pages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSwipeable } from 'react-swipeable'
import './styles.scss'

const PreviewBook = ({ error, setError, bookId, bookUrl, currentPage }) => {
  const [currentPreviewPage, setCurrentPreviewPage] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const allPages = useSelector((state) => state.pages.pages)
  const user = useSelector((state) => state.user)
  const [pages, setPages] = useState(null)
  const [hideCorrectAnswers, setHideCorrectAnswers] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  let prevPage = location.state?.prevPage

  const findCurrentPreviewPage = (id) => {
    if (book && user && book.authorId === user.id) {
      getPageById(id).then((res) => setCurrentPreviewPage(res))
    } else {
      const currentPage = pages.find((p) => p.id === id)
      setCurrentPreviewPage(currentPage)
    }
  }

  useEffect(() => {
    const getPages = () => {
      if (bookId && bookUrl) {
        try {
          // for shared books accessed with link
          getBookByIdAndUrl(bookId, bookUrl).then((res) => {
            if (res.url === bookUrl && res.pages && res.pages.length > 0) {
              const sortedPages = res.pages.sort((p1, p2) => p1.order - p2.order)
              setPages(sortedPages)
            } else if (setError && res.url !== bookUrl) {
              setError('Tarkasta osoite')
            } else if (res.status === 403 || res.status === 404) {
              setError(res.message)
              setPages(null)
            } else {
              setError('Kirjaa ei löydy tai siinä ei ole sivuja')
            }
          })
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      } else if (book && book.pages) {
        const pageList = book.pages.map((id) => allPages[id])
        setPages(pageList.sort((p1, p2) => p1.order - p2.order))
      } else if (currentBookId) {
        // for public books or if pages not fetched yet
        try {
          getBookById(currentBookId).then((res) => {
            if (res.status === 403 || res.status === 404) {
              setError(res.message)
              setPages(null)
            } else if (res.data && res.data.pages) {
              const sortedPages = res.data.pages.sort(
                (p1, p2) => p1.order - p2.order
              )
              setPages(sortedPages)
            }
          })
        } catch (e) {
          throw new Error(e)
        }
      }
    }
    if (!pages) {
      getPages()
    }
  }, [book, allPages, currentBookId])

  useEffect(() => {
    if (pages && currentPage) {
      pages.find((p, index) => {
        if (p.order === currentPage.order) {
          setCurrentIndex(index)
          findCurrentPreviewPage(p.id)
        }
      })
    } else if (pages) {
      findCurrentPreviewPage(pages[0].id)
    }
  }, [pages, currentPage])

  const setCurrentIndexAndPage = (index, page) => {
    setHideCorrectAnswers(true)
    findCurrentPreviewPage(page.id)
    setCurrentIndex(index)
  }

  // Swipeable
  const config = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: true, // call e.preventDefault
    trackTouch: true, // track touch input
    trackMouse: false, // track mouse input
    rotationAngle: 0, // set a rotation angle
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex < pages.length - 1) {
        setCurrentIndexAndPage(currentIndex + 1, pages[currentIndex + 1])
      }
    },
    onSwipedRight: () => {
      if (currentIndex > 0) {
        setCurrentIndexAndPage(currentIndex - 1, pages[currentIndex - 1])
      }
    },
    ...config,
  })

  const handleKey = (e) => {
    const key = e.key || e.keyCode
    if (currentIndex >= 0 && pages && pages.length > 0) {
      switch (key) {
        case 'ArrowLeft':
          if (currentIndex > 0)
            setCurrentIndexAndPage(currentIndex - 1, pages[currentIndex - 1])
          break
        case 'ArrowRight':
          if (currentIndex < pages.length - 1)
            setCurrentIndexAndPage(currentIndex + 1, pages[currentIndex + 1])
          break
        case 'Escape':
          closePreview()
          break
        case 37:
          if (currentIndex > 0)
            setCurrentIndexAndPage(currentIndex - 1, pages[currentIndex - 1])
          break
        case 39:
          if (currentIndex < pages.length - 1)
            setCurrentIndexAndPage(currentIndex + 1, pages[currentIndex + 1])
          break
        case 27:
          closePreview()
          break
        default:
          break
      }
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  }, [pages, currentIndex, handleKey])

  const previewRef = React.useRef()

  useEffect(() => {
    previewRef && previewRef.current && previewRef.current.focus()
  }, [previewRef])

  let nextPage

  const closePreview = () => {
    if (bookId && prevPage !== 'opike') {
      window.close()
    } else {
      navigate(-1)
    }
  }

  return (
    <div className="preview">
      {error && <ErrorPage error={error} setError={setError} />}
      {!error && !currentPreviewPage && <LoadingPage message="Ladataan kirjaa" />}
      {!error && pages && pages.length > 0 && currentPreviewPage && (
        <div className="preview-page-container" ref={previewRef} tabIndex={0}>
          <div className="preview-page">
            <Button
              className="close-preview-button"
              onClick={closePreview}
              label="Sulje esikatselu."
            >
              <FontAwesomeIcon
                icon={['far', 'times-circle']}
                className="template-icon"
                size="3x"
              />
            </Button>
            {currentPreviewPage && (
              <div {...handlers}>
                {!user && (
                  <a
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      fontSize: '1em',
                    }}
                    className="papunet-logo"
                    href="https://papunet.net/"
                  >
                    Papunet
                  </a>
                )}
                <PreviewPage
                  page={currentPreviewPage}
                  hideCorrectAnswers={hideCorrectAnswers}
                  setHideCorrectAnswers={setHideCorrectAnswers}
                />
              </div>
            )}
            <div className="pagination-buttons-container">
              <Pagination size={!isMobileOnly ? 'lg' : 'sm'}>
                <Pagination.Prev
                  className="pagination-btn arrow-btn"
                  role="button"
                  onClick={
                    currentIndex > 0
                      ? () =>
                          setCurrentIndexAndPage(
                            currentIndex - 1,
                            pages[currentIndex - 1]
                          )
                      : () => {}
                  }
                  onKeyDown={handleKey}
                  aria-label="Edellinen sivu."
                >
                  <FontAwesomeIcon icon="arrow-left" className="pagination-icon" />
                </Pagination.Prev>

                {pages.map((page, index) => {
                  if (!nextPage) {
                    nextPage = index
                  }
                  if (
                    pages.length > 12 &&
                    (index === 1 || index === pages.length - 2)
                  ) {
                    if (
                      (currentIndex < 7 && index === pages.length - 2) ||
                      (currentIndex >= 5 && currentIndex <= pages.length - 6) ||
                      (currentIndex >= pages.length - 6 && index === 1)
                    ) {
                      nextPage = index + 1
                      return (
                        <Pagination.Ellipsis
                          key={page.id}
                          className="pagination-btn pagination-ellipsis"
                        />
                      )
                    }
                  } else if (
                    (pages.length > 12 &&
                      index >= 0 &&
                      index > 7 &&
                      index < pages.length - 2 &&
                      currentIndex < 6) ||
                    (index > 1 &&
                      index < pages.length - 1 &&
                      (index < currentIndex - 3 || index > currentIndex + 3) &&
                      currentIndex >= 6 &&
                      currentIndex <= pages.length - 8)
                  ) {
                    nextPage + 1
                    return null
                  } else if (
                    pages.length > 12 &&
                    index > 0 &&
                    index < pages.length - 8 &&
                    currentIndex > pages.length - 8
                  ) {
                    nextPage + 1
                    return null
                  }
                  return (
                    <Pagination.Item
                      role="button"
                      tabIndex={0}
                      key={index}
                      active={pages[index].id === currentPreviewPage.id}
                      activeLabel={`valittu sivu ${index + 1}`}
                      onClick={() => setCurrentIndexAndPage(index, pages[index])}
                      className="pagination-btn"
                      aria-hidden
                    >
                      {index + 1}
                    </Pagination.Item>
                  )
                })}
                <Pagination.Next
                  role="button"
                  className="pagination-btn"
                  onClick={
                    currentIndex < pages.length - 1
                      ? () =>
                          setCurrentIndexAndPage(
                            currentIndex + 1,
                            pages[currentIndex + 1]
                          )
                      : () => {}
                  }
                  onKeyDown={handleKey}
                  aria-label="Seuraava sivu."
                  tabIndex={0}
                >
                  <FontAwesomeIcon icon="arrow-right" className="pagination-icon" />
                </Pagination.Next>
                {currentPreviewPage?.answers?.length ? (
                  <Button
                    className="show-correct-answers-button"
                    onClick={() => setHideCorrectAnswers(!hideCorrectAnswers)}
                    label={
                      hideCorrectAnswers
                        ? 'Näytä oikeat vastaukset'
                        : 'Piilota oikeat vastaukset'
                    }
                  >
                    {hideCorrectAnswers
                      ? 'Näytä oikeat vastaukset'
                      : 'Piilota oikeat vastaukset'}
                    {hideCorrectAnswers ? (
                      <FontAwesomeIcon
                        icon={['far', 'eye']}
                        className="template-icon"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={['far', 'eye-slash']}
                        className="template-icon"
                      />
                    )}
                  </Button>
                ) : null}
              </Pagination>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

PreviewBook.propTypes = {
  error: propTypes.string,
  setError: propTypes.func,
  bookId: propTypes.string,
  bookUrl: propTypes.string,
  currentPage: propTypes.instanceOf(Object),
}

PreviewBook.defaultProps = {
  error: null,
  setError: null,
  bookId: null,
  bookUrl: null,
  currentPage: null,
}

export default memo(PreviewBook)
