import axios from 'axios'
const baseUrl = '/api/answers'

const getAnswerById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`)
  return response.data
}

const updateAnswer = async (updatedAnswer) => {
  const response = await axios.put(`${baseUrl}/${updatedAnswer.id}`, updatedAnswer)
  return response.data
}

const deleteAnswer = (answerId) => {
  return axios.delete(`${baseUrl}/${answerId}`)
}

const addAnswer = (answerObject) => {
  return axios.post(baseUrl, answerObject)
}

export { getAnswerById, addAnswer, updateAnswer, deleteAnswer }
