import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import UploadIcons from '../UploadIcons'
import DraggableAudioButton from '../../DraggableAudioButton'
import Images from '../../Images'
import TextEditorContainer from '../../Texts/TextEditorContainer'
import BlockContainer from '../../Blocks'
import QuestionTool from '../../QuestionTool'
import Video from '../../Video'
import { useSelector } from 'react-redux'
import { useViewport } from '../../../hooks/useViewport'
import { getParent, pageLayoutIsHorizontal } from '../../../utils'
import './styles.scss'

const Page = ({ showGrid, removeVideo, setOpenModal }) => {
  const [parent, setParent] = useState(null)
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])

  const parentRef = React.useRef()
  const { width, height } = useViewport()

  useEffect(() => {
    if (parentRef) {
      setParent(getParent(parentRef))
    }
  }, [parentRef, width, height, currentPage])

  const getGridIfSet = () => {
    if (currentPage && currentPage.type === '6') {
      return 'grid-layout-page-content-2x2'
    } else if (currentPage && currentPage.type === '7') {
      return 'grid-layout-page-content-3x2'
    } else if (currentPage && currentPage.type === '8') {
      return 'grid-layout-page-content-4x3'
    }
    return 'no-grid-layout'
  }

  const getSounds = () => {
    return (
      <>
        {currentPage.sounds.map((soundId) => (
          <DraggableAudioButton key={soundId} soundId={soundId} parent={parent} />
        ))}
      </>
    )
  }

  return (
    <div
      className={
        pageLayoutIsHorizontal(currentPage?.type)
          ? 'create-page-content'
          : 'create-page-content create-page-content-vertical-layout'
      }
      ref={parentRef}
    >
      {parent && currentPage && (
        <div className={showGrid ? 'grid-container' : 'hide-grid'}>
          <div className={getGridIfSet()}>
            {currentPage.images.length < 1 &&
              (!currentPage.videos || currentPage.videos.length < 1) &&
              currentPage.type < 5 && <UploadIcons currentPage={currentPage} />}
            {currentPage.images && currentPage.images.length > 0 && (
              <Images parent={parent} />
            )}
            {currentPage.videos && (
              <Video parent={parent} removeVideo={removeVideo} />
            )}
            {currentPage &&
              currentPage.texts &&
              currentPage.texts.map((boxId) => (
                <TextEditorContainer
                  key={boxId}
                  boxId={boxId}
                  parent={parent}
                  currentPage={currentPage}
                />
              ))}
            {currentPage?.blocks?.map((boxId) => (
              <BlockContainer
                key={boxId}
                boxId={boxId}
                parent={parent}
                currentPage={currentPage}
                setOpenModal={setOpenModal}
              />
            ))}
            {currentPage?.questions?.map((questionId) => (
              <QuestionTool
                key={questionId}
                questionId={questionId}
                parent={parent}
                currentPage={currentPage}
              />
            ))}
            {currentPage.sounds && currentPage.sounds.length > 0 && getSounds()}
          </div>
        </div>
      )}
      {!parent ||
        (!currentPage && <div className="empty-create-page-content"></div>)}
    </div>
  )
}

Page.propTypes = {
  showGrid: propTypes.bool.isRequired,
  removeVideo: propTypes.func,
  setOpenModal: propTypes.func,
}

Page.defaultProps = {
  removeVideo: null,
}

export default memo(Page)
