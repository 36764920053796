import React, { memo, useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { startRootLoading, rootLoadingReady } from '../store/root/rootAction'
import { getPublicEditableBooks } from '../api/books/books'
import PublicBooks from '../components/PublicBooks'
import MainNavigationContainer from './MainNavigationContainer'

const PublicBooksContainer = () => {
  const [sharedMaterials, setSharedMaterials] = useState([])
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    let source = axios.CancelToken.source()
    const config = {
      cancelToken: source.token,
    }
    dispatch(startRootLoading())
    getPublicEditableBooks(config).then((res) => {
      setSharedMaterials(res.data)
      dispatch(rootLoadingReady())
    })
    return () => source.cancel()
  }, [location])

  return (
    <div>
      <MainNavigationContainer />
      <PublicBooks books={sharedMaterials} />
    </div>
  )
}

export default memo(PublicBooksContainer)
