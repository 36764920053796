import React, { memo } from 'react'
import kyselyGuideIcon from '../../QuestionTool/images/kysely-guide-icon.png'
import './styles.scss'

const QuestionGuideIcons = () => {
  return (
    <div className="question-guide-icons">
      <img src={kyselyGuideIcon} alt="" />
    </div>
  )
}

export default memo(QuestionGuideIcons)
