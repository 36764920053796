import React, { memo } from 'react'
import propTypes from 'prop-types'
import Modal from 'react-modal'

const ModalContainer = ({
  children,
  modalIsOpen,
  closeModal,
  info,
  label,
  styleName,
  className,
}) => {
  const customStyles = {
    content: {
      top: styleName === 'choose-image' ? '45%' : '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-20%',
      transform: 'translate(-50%, -50%)',
      position: 'fixed',
      width: styleName === 'choose-image' ? '700px' : 'auto',
      maxWidth: '95%',
      minWidth: '290px',
      height: styleName === 'choose-image' ? '95%' : 'auto',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  }
  const customInfoStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      position: 'fixed',
      height: 'fit-content',
      maxWidth: '100%',
      width: '370px',
      minWidth: '200px',
      maxHeight: '100vh',
      textAlign: 'left',
    },
  }

  // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root')

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={info ? customInfoStyles : customStyles}
      contentLabel={label}
      closeTimeoutMS={300}
      className={className}
    >
      {children}
    </Modal>
  )
}

ModalContainer.propTypes = {
  children: propTypes.oneOfType([
    propTypes.string,
    propTypes.instanceOf(Array),
    propTypes.node,
  ]).isRequired,
  modalIsOpen: propTypes.bool.isRequired,
  closeModal: propTypes.func.isRequired,
  info: propTypes.bool,
  label: propTypes.string.isRequired,
  styleName: propTypes.string,
  className: propTypes.string,
}

ModalContainer.defaultProps = {
  info: false,
  styleName: null,
  className: '',
}

export default memo(ModalContainer)
