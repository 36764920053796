import axios from 'axios'
const baseUrl = '/api/cells'

const addCell = (cell) => {
  return axios.post(baseUrl, cell)
}

const updateCell = async (updatedCell) => {
  const response = await axios.put(`${baseUrl}/${updatedCell.id}`, updatedCell)
  return response.data
}

export { addCell, updateCell }
