import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAllUserBooks } from '../../../api/books/books'
import { deleteUserMaterialsFromKuvatyokalu } from '../../../api/users/users'
import { deleteUserAccount } from '../../../store/user/userAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingPage from '../../LoadingPage'
import ModalContainer from '../../ModalContainer'
import Button from '../../Button'
import '../EditAccount/styles.scss'
import { rootLoadingReady, startRootLoading } from '../../../store/root/rootAction'

const DeleteAccount = () => {
  const [openModal, setOpenModal] = useState(false)
  const [deleteUser, setDeleteUser] = useState(false)
  const user = useSelector((state) => state.user)
  const books = useSelector((state) => (state.books ? state.books.books : {}))
  const loading = useSelector((state) => state.root.loading)
  const dispatch = useDispatch()

  const deleteAccount = () => {
    dispatch(startRootLoading())
    setDeleteUser(true)
    Promise.all(deleteAllUserBooks(Object.values(books)))
      .then(async () => {
        await deleteUserMaterialsFromKuvatyokalu(user.id)
      })
      .then(() => {
        dispatch(deleteUserAccount(user.id))
      })
      .then(() => {
        dispatch(rootLoadingReady)
      })
    setOpenModal(false)
  }

  return (
    <>
      {loading && deleteUser ? (
        <LoadingPage message="Poistetaan käyttäjää" />
      ) : (
        <div className="edit-account-container edit-email-container">
          <h3>Käyttäjätilin poistaminen</h3>
          <p className="red-text">
            Huom! Papurin käyttäjätili on yhteinen Papunetin Kuvatyökalun kanssa.
            Käyttäjätilin poistaminen poistaa kaikki Papurissa ja Kuvatyökalussa
            tekemäsi materiaalit. Poisto koskee myös mahdollisesti aiemmin jakamiasi
            materiaaleja.
          </p>
          <Button
            className="dialogue-button danger-button edit-account-button"
            onClick={() => setOpenModal(true)}
          >
            <div className="edit-account-button-content">
              <FontAwesomeIcon
                icon={['far', 'trash-alt']}
                className="save-edited-icon"
              />
              Poista käyttäjätili
            </div>
          </Button>
          <ModalContainer
            modalIsOpen={openModal}
            closeModal={() => setOpenModal(false)}
            info
            label="Oman tilin poistaminen"
          >
            <div className="info-modal-content">
              <h2>Tilin poistaminen</h2>
              <p className="modal-text">
                Haluatko varmasti poistaa käyttäjätilisi ja kaikki siihen liittyvät
                materiaalit? Tätä toimintoa ei voi perua.
              </p>
              <p className="modal-text">
                Tilin poistamisen jälkeen sinut kirjataan automaattisesti ulos
                palvelusta.
              </p>
              <div className="modal-button-container">
                <Button
                  className="dialogue-button danger-button"
                  onClick={() => deleteAccount()}
                >
                  Kyllä, poista tili.
                </Button>
                <Button
                  className="dialogue-button gray-button"
                  onClick={() => setOpenModal(false)}
                >
                  Peruuta
                </Button>
              </div>
            </div>
          </ModalContainer>
        </div>
      )}
    </>
  )
}

export default memo(DeleteAccount)
