import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import propTypes from 'prop-types'
import Button from '../Button'
import './styles.scss'

const ErrorPage = ({ error, setError }) => {
  const navigate = useNavigate()
  const goBackAndClearError = () => {
    if (setError) {
      setError(null)
    }
    navigate(-1)
  }
  return (
    <div
      className="error-message-content"
      style={{
        backgroundColor: 'white',
        width: '100vw',
        height: '100vh',
        minHeight: '100%',
        margin: 0,
        padding: '10%',
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <h1>Jotain meni pieleen.</h1>
      <p>{error}.</p>
      <Button
        className="btn select-button margin-top-button"
        onClick={goBackAndClearError}
        role="link"
      >
        Takaisin
      </Button>
    </div>
  )
}

ErrorPage.propTypes = {
  error: propTypes.string.isRequired,
  setError: propTypes.func,
}

ErrorPage.defaultProps = {
  setError: null,
}

export default memo(ErrorPage)
