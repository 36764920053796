import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deleteSound, updateSound } from '../../store/sounds/soundAction'
import { updateBook } from '../../store/books/bookAction'
import Draggable from 'react-draggable'
import Button from '../Button'
import DeleteItemDialogue from '../DeleteItemDialogue'
import './styles.scss'

const DraggableAudioButton = ({ soundId, parent }) => {
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  const [currentlyPlayingSound, setCurrentlyPlayingSound] = useState(false)
  const [position, setPosition] = useState(null)
  const nodeRef = React.useRef()
  const audioRef = React.useRef()
  const dispatch = useDispatch()
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const sound = useSelector((state) => state.sounds[soundId])

  const handleStop = (e, pos) => {
    updatePosition(pos.x, pos.y)
  }

  const updatePosition = (x, y) => {
    const relativePositionX = x / parent.width
    const relativePositionY = y / parent.height
    const updated = {
      ...sound,
      x: relativePositionX,
      y: relativePositionY,
    }
    dispatch(updateSound(updated))
    const updatedMaterial = {
      ...book,
      updated: Date.now(),
    }
    dispatch(updateBook(updatedMaterial))
  }

  const getPosition = () => {
    setPosition({ x: sound.x * parent.width, y: sound.y * parent.height })
  }

  const onControlledDrag = (e, pos) => {
    setPosition(pos)
  }

  useEffect(() => {
    if (sound) {
      getPosition()
    }
  }, [sound, parent])

  const togglePlaySound = () => {
    if (!currentlyPlayingSound) {
      audioRef.current.play()
      setCurrentlyPlayingSound(true)
    } else {
      audioRef.current.pause()
      setCurrentlyPlayingSound(false)
    }
  }

  const removeSound = (soundId) => {
    dispatch(deleteSound(soundId))
  }

  return (
    <>
      {sound && position && (
        <Draggable
          handle=".handle"
          position={position}
          onDrag={onControlledDrag}
          onStop={handleStop}
          grid={[1, 1]}
          scale={1}
          bounds=".create-page-content"
          nodeRef={nodeRef}
          cancel=".audio-button"
        >
          <div
            className="audio-container audio-player-button-container"
            ref={nodeRef}
          >
            <div className="audio-buttons-container">
              <div className="move-audio-button" label="Siirrä ääni">
                <FontAwesomeIcon icon="arrows-alt" className="handle move-icon" />
              </div>
              <Button
                className="delete-audio-button"
                onClick={() => setOpenDeleteContentModal(true)}
                role="link"
                label="Poista ääni"
              >
                <FontAwesomeIcon icon="times" className="cancel-icon" />
              </Button>
            </div>

            <Button
              className="audio-button"
              onClick={() => togglePlaySound()}
              label="Soita ääni."
            >
              {currentlyPlayingSound ? (
                <FontAwesomeIcon
                  icon={['far', 'pause-circle']}
                  className="audio-icon"
                />
              ) : (
                <FontAwesomeIcon icon="volume-up" className="audio-icon" />
              )}
              <audio
                ref={audioRef}
                src={sound.filename}
                onEnded={() => setCurrentlyPlayingSound(false)}
              />
            </Button>
          </div>
        </Draggable>
      )}
      {openDeleteContentModal && sound && (
        <DeleteItemDialogue
          openDeleteContentModal={openDeleteContentModal}
          setOpenDeleteContentModal={setOpenDeleteContentModal}
          deleteItem={removeSound}
          itemId={sound.id}
          textContent={['Haluatko varmasti poistaa äänen?', 'Kyllä, poista']}
        />
      )}
    </>
  )
}

DraggableAudioButton.propTypes = {
  soundId: propTypes.number.isRequired,
  parent: propTypes.instanceOf(Object).isRequired,
}

export default memo(DraggableAudioButton)
