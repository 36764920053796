import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Draggable from 'react-draggable'
import { Resizable } from 're-resizable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const DraggableResizable = ({
  dragHandle,
  lockAspectRatio,
  children,
  item,
  parent,
  handleResize,
  updateItem,
}) => {
  const nodeRef = React.useRef(null)
  const dispatch = useDispatch()
  const [position, setPosition] = useState({
    x: item.x * parent.width,
    y: item.y * parent.height,
  })
  const [size, setSize] = useState(null)
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const images = useSelector((state) => state.images.images)

  const getSize = () => {
    setSize({
      width: item.w * parent.width,
      height: item.w === 0.3 && item.h === 0.3 ? 'auto' : item.h * parent.height,
    })
  }

  const handleStop = (e, pos) => {
    updatePosition(pos.x, pos.y)
  }
  const updatePosition = (x, y) => {
    const relativePositionX = x / parent.width
    const relativePositionY = y / parent.height
    const updated = {
      ...item,
      x: relativePositionX,
      y: relativePositionY,
    }
    dispatch(updateItem(updated))
  }

  const getPosition = () => {
    setPosition({ x: item.x * parent.width, y: item.y * parent.height })
  }

  useEffect(() => {
    getSize()
    getPosition()
  }, [item, parent])

  const updateZIndex = () => {
    if (item.z && item.z < currentPage.images.length) {
      const updatedItem = { ...item, z: currentPage.images.length }
      dispatch(updateItem(updatedItem))
      Object.values(images)
        .filter((img) => currentPage.images.includes(img.id))
        .filter((img) => img.id !== item.id)
        .forEach((element) => {
          const updatedElement = {
            ...element,
            z: element.z === 1 ? 1 : element.z - 1,
          }
          dispatch(updateItem(updatedElement))
        })
      nodeRef.current.focus()
    }
  }

  const onControlledDrag = (e, pos) => {
    setPosition(pos)
  }

  return (
    <Draggable
      handle={dragHandle}
      position={position}
      onStart={updateZIndex}
      onDrag={onControlledDrag}
      onStop={handleStop}
      scale={1}
      grid={[0.1, 0.1]}
      bounds=".create-page-content"
      nodeRef={nodeRef}
      cancel=".delete-item-button, .resizable-handle, .play-icon-button"
    >
      <div
        className="draggable-container"
        ref={nodeRef}
        style={{
          position: 'absolute',
        }}
        tabIndex={0}
      >
        <Resizable
          minWidth={20}
          maxHeight={parent.height}
          maxWidth={parent.width}
          size={size}
          lockAspectRatio={lockAspectRatio}
          className="resizable-container"
          onResizeStop={handleResize}
          grid={[0.1, 0.1]}
          handleComponent={{
            bottomRight: (
              <FontAwesomeIcon
                icon="expand-alt"
                className="resizable-handle"
                size={
                  item.w * parent.width < 150 && item.h * parent.height < 150
                    ? '1x'
                    : '2x'
                }
              />
            ),
          }}
          enable={{
            top: false,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: true,
            bottomLeft: false,
            topLeft: false,
          }}
        >
          {children}
        </Resizable>
      </div>
    </Draggable>
  )
}

DraggableResizable.propTypes = {
  dragHandle: propTypes.string.isRequired,
  lockAspectRatio: propTypes.bool,
  children: propTypes.oneOfType([
    propTypes.string,
    propTypes.instanceOf(Array),
    propTypes.node,
  ]).isRequired,
  item: propTypes.instanceOf(Object).isRequired,
  parent: propTypes.instanceOf(Object).isRequired,
  handleResize: propTypes.func,
  updateItem: propTypes.func,
}

DraggableResizable.defaultProps = {
  lockAspectRatio: false,
  handleResize: null,
  updateItem: null,
}

export default memo(DraggableResizable)
