import React, { memo } from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const NavigationLink = ({ eventKey, icon, text, linkTo, activeLink, onClick }) => {
  const getIcon = () => {
    switch (icon) {
      case 'images':
        return (
          <FontAwesomeIcon
            icon={['far', 'images']}
            className="nav-link-icon"
            size="2x"
          />
        )
      case 'copy':
        return (
          <FontAwesomeIcon
            icon={['far', 'copy']}
            className="nav-link-icon"
            size="2x"
          />
        )
      case 'life-ring':
        return (
          <FontAwesomeIcon
            icon={['far', 'life-ring']}
            className="nav-link-icon"
            size="2x"
          />
        )
      case 'user':
        return (
          <FontAwesomeIcon
            icon={['far', 'user-circle']}
            className="nav-link-icon"
            size="2x"
          />
        )
      case 'sign-out':
        return (
          <button className="nav-button" onClick={() => onClick(true)}>
            <FontAwesomeIcon
              icon="sign-out-alt"
              className="nav-link-icon"
              size="2x"
            />
            Kirjaudu ulos
          </button>
        )
      default:
        return <></>
    }
  }

  return (
    <Nav.Item>
      <Nav.Link
        eventKey={eventKey}
        as={Link}
        className={activeLink === eventKey ? 'active-nav-link' : 'nav-link'}
        exact={linkTo === '/' ? 'true' : 'false'}
        to={linkTo}
      >
        {getIcon()}
        <span>{text}</span>
      </Nav.Link>
    </Nav.Item>
  )
}

NavigationLink.propTypes = {
  icon: propTypes.string.isRequired,
  text: propTypes.string,
  linkTo: propTypes.string,
  eventKey: propTypes.string.isRequired,
  activeLink: propTypes.string,
  onClick: propTypes.func,
}

NavigationLink.defaultProps = {
  linkTo: null,
  onClick: null,
  text: null,
  activeLink: null,
}

export default memo(NavigationLink)
