import React from 'react'
import propTypes from 'prop-types'
import Button from '../../Button'
import ModalContainer from '../../ModalContainer'
import ValidatedTextInput from '../../ValidatedTextInput'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { updateImage } from '../../../store/images/imageAction'
import { updateBlockImage } from '../../../store/blockImages/blockImageAction'

const EditImage = ({
  openEditImageModal,
  setOpenEditImageModal,
  image,
  editingBlockImage,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm()
  const dispatch = useDispatch()
  const closeWithoutSaving = () => {
    reset()
    setOpenEditImageModal(false)
  }

  const onSubmit = (data) => {
    setOpenEditImageModal(false)
    const updated = { ...image, alt: data.alt }
    if (!editingBlockImage) dispatch(updateImage(updated))
    else if (editingBlockImage) dispatch(updateBlockImage(updated))
  }
  return (
    <ModalContainer
      modalIsOpen={openEditImageModal}
      info
      closeModal={() => setOpenEditImageModal(false)}
      label="Esikatsele ja lisää tekstivastine"
    >
      <div className="image-text-input-container">
        <h3>Muokkaa kuvaa</h3>
        <div className="add-alt-preview-image-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ValidatedTextInput
              onChange={() => {
                clearErrors()
              }}
              label="Muokkaa tekstivastinetta"
              name="alt"
              labelClassName="input-label-class"
              inputClassName="text-input-class"
              value={image.alt}
              {...register('alt', { maxLength: 255 })}
            />
            <div className="validation-error-text">
              {errors.alt &&
                errors.alt.type === 'maxLength' &&
                'Tekstivastine voi olla korkeintaan 255 merkkiä pitkä.'}
            </div>
            <small>
              {' '}
              Tekstivastine näkyy vain ruudunlukijaohjelman käyttäjille.
            </small>
            <div className="modal-button-container">
              <Button type="submit" className="dialogue-button select-button">
                Tallenna
              </Button>
              <Button
                className="dialogue-button gray-button"
                onClick={closeWithoutSaving}
              >
                Peruuta
              </Button>
            </div>
          </form>
        </div>
      </div>
    </ModalContainer>
  )
}

EditImage.propTypes = {
  openEditImageModal: propTypes.bool.isRequired,
  setOpenEditImageModal: propTypes.func.isRequired,
  image: propTypes.instanceOf(Object).isRequired,
  editingBlockImage: propTypes.bool,
}

export default EditImage
