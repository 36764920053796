import { convertToObject } from '../../utils'
const initialState = {}
export const blockReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_BLOCKS':
      if (!action.data) {
        return {}
      } else {
        return action.data
      }
    case 'ADD_BLOCK': {
      const id = action.data.id
      return {
        ...state,
        [id]: action.data,
      }
    }
    case 'UPDATE_BLOCK':
    case 'BLOCK_SCALE_UP':
    case 'BLOCK_SCALE_DOWN': {
      const key = action.data.id
      return {
        ...state,
        [key]: action.data,
      }
    }
    case 'UPDATE_BLOCKS': {
      const blocks = { ...state }
      if (blocks && action.data) {
        const copyWithAddedTexts = Object.values(blocks).concat(
          Object.values(action.data)
        )
        return convertToObject(copyWithAddedTexts)
      }
      return state
    }
    case 'DELETE_BLOCK': {
      const blocks = { ...state }
      const filtered = Object.values(blocks).filter((p) => p.id !== action.data)
      return convertToObject(filtered)
    }
    case 'DELETE_PAGE': {
      const copy2 = Object.values(state).filter(
        (block) => block.pageId !== action.data
      )
      return convertToObject(copy2)
    }
    case 'PURGE':
      return initialState
    default:
      return state
  }
}
