import React, { memo } from 'react'
import FeedbackForm from './FeedbackForm'
import './styles.scss'

const Feedback = () => {
  return (
    <div className="two-part-page-container">
      <div className="two-part-page-content">
        <div className="instructions">
          <h1 className="page-header">Palaute</h1>
          <p>Tällä sivulla voit antaa palautetta Papurista.</p>
          <p>Jos haluat, että vastaamme viestiisi, anna sähköpostiosoitteesi.</p>
          <p>
            Jos mahdollista, mainitse palautteessasi millä laitteella käytit Papuria.
          </p>
        </div>
        <div className="feedback-content">
          <h2>Anna palautetta!</h2>
          <FeedbackForm />
        </div>
      </div>
    </div>
  )
}

export default memo(Feedback)
