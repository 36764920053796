import React from 'react'
import PageHeader from '../EditPage/EditPageHeader'
import { useViewport } from '../../hooks/useViewport'

const AddPageView = () => {
  const { width } = useViewport()
  return (
    <div className="new-page-container">
      <PageHeader />
      <div className="create-page-content">
        <div className="empty-create-page-content">
          <p
            style={{
              textAlign: 'center',
              fontSize: width < 576 ? '2em' : '4em',
              marginTop: 0,
              color: 'gray',
            }}
          >
            Ei sivuja
          </p>
        </div>
      </div>
    </div>
  )
}

export default AddPageView
