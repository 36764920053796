import React from 'react'
import Button from '../../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import propTypes from 'prop-types'
import '../styles.scss'
import { useDispatch } from 'react-redux'
import {
  blockImageMoveLeft,
  blockImageMoveRight,
} from '../../../store/blockImages/blockImageAction'
import {
  relativeBlockImageHeight,
  relativeMarginsForBlockImage,
} from '../../../utils'

const BlockImages = ({
  images,
  setOpenDeleteSingleImageModal,
  setImageBlockIdToDelete,
  setOpenEditBlockImageModal,
  // setImageBlockIdToEdit,
  scale,
  pageHeight,
  pageWidth,
  pageType,
}) => {
  const dispatch = useDispatch()

  const openDeleteModalWithCorrectId = (id) => {
    setOpenDeleteSingleImageModal(true)
    setImageBlockIdToDelete(id)
  }

  // const openEditModalWithCorrectId = (id) => {
  //   setOpenEditBlockImageModal(true)
  //   setImageBlockIdToEdit(id)
  // }

  const changeImageOrder = (id, direction) => {
    if (direction === 'left') {
      dispatch(blockImageMoveLeft(id))
    } else if (direction === 'right') {
      dispatch(blockImageMoveRight(id))
    }
  }

  const isFirstImage = (id) => {
    const idOfFirstImage = images[0].id
    return idOfFirstImage === id ? true : false
  }

  const isLastImage = (id) => {
    const idOfLastImage = images[images.length - 1].id
    return idOfLastImage === id ? true : false
  }

  return images?.length
    ? images.map((image) => (
        <div key={image.id} className="block-image-wrapper">
          <div className="block-image-tools">
            {/* {setOpenEditBlockImageModal !== undefined ? (
            <Button
              className="edit-block-image-button"
              onClick={() => openEditModalWithCorrectId(image.id)}
              role="link"
              label="Muokkaa kuvan alt-tekstiä"
            >
              <FontAwesomeIcon icon={['far', 'edit']} className="cancel-icon" />
            </Button>
          ) : null} */}
            {setOpenDeleteSingleImageModal !== undefined ? (
              <Button
                className="delete-block-image-button"
                onClick={() => openDeleteModalWithCorrectId(image.id)}
                role="link"
                label="Poista kuva"
              >
                <FontAwesomeIcon icon="times" className="cancel-icon" />
              </Button>
            ) : null}
            {setOpenEditBlockImageModal !== undefined ? (
              <Button
                className={
                  isFirstImage(image.id)
                    ? 'left-block-image-button disabled'
                    : 'left-block-image-button'
                }
                onClick={() => changeImageOrder(image.id, 'left')}
                role="link"
                label="Siirrä kuva vasemmalle"
                disabled={isFirstImage(image.id)}
              >
                <FontAwesomeIcon icon="arrow-left" className="cancel-icon" />
              </Button>
            ) : null}
            {setOpenEditBlockImageModal !== undefined ? (
              <Button
                className={
                  isLastImage(image.id)
                    ? 'right-block-image-button disabled'
                    : 'right-block-image-button'
                }
                onClick={() => changeImageOrder(image.id, 'right')}
                role="link"
                label="Siirrä kuva oikealle"
                disabled={isLastImage(image.id)}
              >
                <FontAwesomeIcon icon="arrow-right" className="cancel-icon" />
              </Button>
            ) : null}
          </div>
          <img
            src={image.filename}
            alt={image.alt}
            className="block-image"
            style={{
              height: relativeBlockImageHeight(
                scale,
                pageWidth,
                pageHeight,
                pageType
              ),
              margin: relativeMarginsForBlockImage(pageWidth),
            }}
          />
        </div>
      ))
    : null
}

BlockImages.propTypes = {
  images: propTypes.array,
  setOpenDeleteSingleImageModal: propTypes.func,
  setImageBlockIdToDelete: propTypes.func,
  setOpenEditBlockImageModal: propTypes.func,
  setImageBlockIdToEdit: propTypes.func,
  scale: propTypes.number,
  height: propTypes.string,
  pageHeight: propTypes.number,
  pageWidth: propTypes.number,
  pageType: propTypes.number,
}

export default BlockImages
