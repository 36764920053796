import React, { memo } from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Image from './Image'
import { pageTypeIsFree, pageTypeIsQuestion } from '../../utils'

const Images = ({ parent }) => {
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])

  return (
    <>
      {pageTypeIsFree(currentPage.type) || pageTypeIsQuestion(currentPage.type) ? (
        <>
          {currentPage &&
            currentPage.images &&
            currentPage.images.map((imgId) => (
              <Image key={imgId} imageId={imgId} parent={parent} />
            ))}
        </>
      ) : (
        <>
          {currentPage.images.length > 0 && (
            <Image imageId={currentPage.images[0]} parent={parent} />
          )}
        </>
      )}
    </>
  )
}

Images.propTypes = {
  parent: propTypes.instanceOf(Object).isRequired,
}

export default memo(Images)
