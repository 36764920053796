import React, { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import DeleteItemDialogue from '../components/DeleteItemDialogue'
import OwnBooks from '../components/OwnBooks'
import MainNavigationContainer from './MainNavigationContainer'
import { deleteMaterial } from '../store/books/bookAction'

const OwnMaterialsContainer = () => {
  const { trackEvent } = useMatomo()
  const [openModal, setOpenModal] = useState(false)
  const [id, setId] = useState(null)

  const dispatch = useDispatch()

  const deleteBook = async (id) => {
    dispatch(deleteMaterial(id))
    setId(null)
    setOpenModal(false)
    trackEvent({ category: 'Kirja', action: 'Kirja poistettu' })
  }
  const deleteChosen = async (id) => {
    setOpenModal(true)
    setId(id)
  }

  return (
    <div>
      <MainNavigationContainer />
      <OwnBooks deleteBook={deleteChosen} />
      <DeleteItemDialogue
        openDeleteContentModal={openModal}
        setOpenDeleteContentModal={setOpenModal}
        deleteItem={deleteBook}
        itemId={id}
        textContent={['Haluatko varmasti poistaa kirjan?', 'Kyllä, poista']}
      />
    </div>
  )
}

export default memo(OwnMaterialsContainer)
