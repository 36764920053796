import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import ModalContainer from '../../ModalContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Button'
import ValidatedTextInput from '../../ValidatedTextInput'
import { useForm } from 'react-hook-form'
import { loginUser } from '../../../store/user/userAction'
import './styles.scss'
import ForgottenPassword from '../ForgottenPassword'

const LoginForm = () => {
  const { trackEvent } = useMatomo()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm()
  const dispatch = useDispatch()
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const [message, setMessage] = useState('')
  const user = useSelector((state) => state.user)

  useEffect(() => {
    if (user && user.error) {
      setMessage(user.error)
    } else if (!user) {
      setMessage('')
    }
  }, [user])

  const onSubmit = (data) => {
    const fd = new FormData()
    fd.append('username', data.email.trim().toLowerCase())
    fd.append('password', data.password)
    fd.append('yt0', 'Kirjaudu')
    fd.append('ajax', 'login-form')
    dispatch(loginUser(fd))
    trackEvent({ category: 'Käyttäjä', action: 'Kirjautui sisään' })
  }

  return (
    <div className="login-form-container">
      <div className="login-form-content">
        <h1 className="login-page-header" aria-describedby="papuri-introduction">
          Kirjaudu Papuriin
        </h1>
        <p>
          Työkaluun kirjaudutaan Papunet-tunnuksilla. Jos sinulla on tunnukset,
          kirjaudu tästä.
        </p>
        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          <ValidatedTextInput
            label="Sähköpostiosoite"
            name="email"
            placeHolder="Kirjoita sähköpostiosoite..."
            onChange={() => clearErrors()}
            labelClassName="hidden-label"
            inputClassName="text-input-field"
            {...register('email', { required: true })}
          />
          <div className="validation-error-text">
            {errors.email &&
              errors.email.type === 'required' &&
              'Kirjoita sähköpostiosoite.'}
          </div>
          <ValidatedTextInput
            label="Salasana"
            name="password"
            placeHolder="Kirjoita salasana..."
            onChange={() => clearErrors()}
            labelClassName="hidden-label"
            inputClassName="text-input-field"
            {...register('password', { required: true })}
          />
          <div className="validation-error-text">
            {errors.password &&
              errors.password.type === 'required' &&
              'Kirjoita salasana.'}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Button className="login-page-button login-button" type="submit">
              <div className="icon-button-content">
                <FontAwesomeIcon icon="sign-out-alt" className="info-icon" />
                Kirjaudu
              </div>
            </Button>
            <Button
              className="link-button"
              onClick={() => setOpenPasswordModal(true)}
            >
              Unohditko salasanasi?
            </Button>
          </div>

          <p className="backend-response-text red-text">{message}</p>
        </form>
      </div>
      {openPasswordModal && (
        <ModalContainer
          modalIsOpen={openPasswordModal}
          closeModal={() => setOpenPasswordModal(false)}
          label="Unohtuneen salasanan vaihto"
        >
          <ForgottenPassword setOpenPasswordModal={setOpenPasswordModal} />
        </ModalContainer>
      )}
    </div>
  )
}

export default memo(LoginForm)
