import { convertToObject } from '../../utils'
const initialState = {
  books: {},
  currentBook: null,
  sortAlphabetically: false,
  sortAscending: true,
}

export const bookReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_MATERIAL': {
      if (action.data.books === undefined) {
        return {
          books: {},
          currentBook: null,
          sortAlphabetically: false,
          sortAscending: true,
        }
      } else {
        return action.data
      }
    }
    case 'ADD_MATERIAL':
    case 'CHOOSE_OPIKE_MATERIAL': {
      const bookId = action.data.id
      const newBook = { ...action.data, pages: [] }
      return {
        ...state,
        books: {
          ...state.books,
          [bookId]: newBook,
        },
        currentBook: newBook.id,
      }
    }
    case 'COPY_BOOK': {
      const book = { ...action.data, created: Date.now(), updated: Date.now() }
      return {
        ...state,
        books: {
          ...state.books,
          [book.id]: book,
        },
      }
    }
    case 'ADD_PAGE': {
      const copyOfCurrentPages = state.books[state.currentBook].pages ?? []
      return {
        ...state,
        books: {
          ...state.books,
          [state.currentBook]: {
            ...state.books[state.currentBook],
            pages: copyOfCurrentPages.concat(action.data.id),
          },
        },
      }
    }
    case 'CHOOSE_MATERIAL':
      return { ...state, currentBook: action.data }
    case 'SORT_BOOKS':
      return { ...state, sortAlphabetically: action.data }
    case 'SORT_ASCENDING': {
      return { ...state, sortAscending: action.data }
    }
    case 'UPDATE_BOOK':
      return {
        ...state,
        books: {
          ...state.books,
          [action.data.id]: action.data,
        },
      }
    case 'DELETE_MATERIAL': {
      const filtered = Object.values(state.books).filter((p) => p.id !== action.data)
      return { ...state, books: convertToObject(filtered) }
    }
    case 'REMOVE_USER_FROM_STATE': {
      return { books: {}, currentBook: action.data }
    }
    case 'PURGE':
      return initialState
    default:
      return state
  }
}
