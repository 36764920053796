import React, { memo } from 'react'
import Feedback from '../components/Feedback'
import MainNavigationContainer from './MainNavigationContainer'

const FeedbackContainer = () => {
  return (
    <div>
      <MainNavigationContainer />
      <Feedback />
    </div>
  )
}

export default memo(FeedbackContainer)
