import axios from 'axios'
const baseUrl = '/api/sounds'

const addSound = (sound, config) => {
  try {
    return axios.post(baseUrl, sound, config)
  } catch (e) {
    throw new Error(e)
  }
}

const deleteSound = (soundId) => {
  return axios.delete(`${baseUrl}/${soundId}`)
}

const getSoundById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`)
  return response.data
}

const updateSound = async (updatedSound) => {
  const response = await axios.put(`${baseUrl}/${updatedSound.id}`, updatedSound)
  return response.data
}

export { deleteSound, getSoundById, addSound, updateSound }
