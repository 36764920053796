const initialState = {
  loading: true,
}

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_ROOT_LOADING':
      return { ...state, loading: true }
    case 'ROOT_LOADING_READY':
      return { ...state, loading: false }
    case 'REHYDRATE':
      return state
    case 'PURGE':
      return state
    default:
      return state
  }
}
