import {
  addImage as apiAddImage,
  deleteImage as apiDeleteImage,
  getImages,
  updateImage as apiUpdateImage,
} from '../../api/books/images'
import { updateCell } from '../cells/cellAction'
import {
  getSizeAndPositionForImageOrVideo,
  anonymousGetId,
  getGridImageSizeAndPosition,
  pageTypeIsGrid,
  pageTypeIsFree,
  pageTypeIsQuestion,
} from '../../utils'

export const addImage = (url, width, height, alt, text, currentCellId) => {
  return async (dispatch, getState) => {
    const state = getState()
    const currentPageId = state.pages.currentPage
    const currentPage = state.pages.pages[currentPageId]
    const cellToUpdate = state.cells[currentCellId]
    const coordinates = pageTypeIsGrid(currentPage.type)
      ? getGridImageSizeAndPosition(currentPage.type, cellToUpdate.position)
      : getSizeAndPositionForImageOrVideo(currentPage)
    const user = state.user
    const image = {
      pageId: currentPageId,
      userId: user.id,
      size: 0,
      filename: url,
      type: 0,
      x: coordinates && coordinates.x,
      y: coordinates && coordinates.y,
      z: currentPage.images.length > 0 ? currentPage.images.length + 1 : 1,
      width: width,
      height: height,
      w: coordinates && coordinates.w,
      h: coordinates && coordinates.h,
      resizable:
        pageTypeIsFree(currentPage.type) || pageTypeIsQuestion(currentPage.type)
          ? 1
          : 0,
      draggable:
        pageTypeIsFree(currentPage.type) || pageTypeIsQuestion(currentPage.type)
          ? 1
          : 0,
      alt: alt,
      position: null,
      caption: text,
    }
    if (!user.anonymous) {
      apiAddImage(image).then((res) => {
        if (pageTypeIsGrid(currentPage.type)) {
          dispatch(updateCell({ ...cellToUpdate, imageId: res.data.id }))
        }
        dispatch({
          type: 'ADD_IMAGE',
          data: res.data,
        })
      })
    } else {
      const imageWithId = { ...image, id: anonymousGetId(state.images.images) }
      if (pageTypeIsGrid(currentPage.type)) {
        dispatch(updateCell({ ...cellToUpdate, imageId: imageWithId.id }))
      }
      dispatch({
        type: 'ADD_IMAGE',
        data: imageWithId,
      })
    }
  }
}

export const deleteImage = (id) => {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      if (state.user && !state.user.anonymous) {
        await apiDeleteImage(id)
      }
      dispatch({
        type: 'DELETE_IMAGE',
        data: id,
      })
    } catch {
      dispatch({
        type: 'REMOVE_USER_FROM_STATE',
        data: null,
      })
    }
  }
}

export const initializeImages = () => {
  return async (dispatch) => {
    const res = await getImages() //
    const imageList = Object.values(res.data).reduce(
      (list, a) => ({ ...list, [a.id]: a }),
      {}
    )
    dispatch({
      type: 'INIT_IMAGES',
      data: imageList,
    })
  }
}

export const toggleShowImages = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'TOGGLE_SHOW_IMAGES',
      data,
    })
  }
}

export const updateImage = (updated) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiUpdateImage(updated)
      dispatch({
        type: 'UPDATE_IMAGE',
        data: res,
      })
    } else {
      dispatch({
        type: 'UPDATE_IMAGE',
        data: updated,
      })
    }
  }
}

export const updateImageStore = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_IMAGES',
      data,
    })
  }
}
