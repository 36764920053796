import React, { memo } from 'react'
import propTypes from 'prop-types'
import './styles.scss'

const ValidatedTextInput = React.forwardRef((props, ref) => {
  const {
    label,
    name,
    value,
    placeHolder,
    onChange,
    labelClassName,
    inputClassName,
  } = props

  return (
    <div className="text-input-container">
      <label className={labelClassName} htmlFor={name}>
        {label}
      </label>
      <input
        type={name.includes('password') ? 'password' : 'text'}
        name={name}
        defaultValue={value}
        id={name}
        placeholder={placeHolder}
        ref={ref}
        className={inputClassName}
        onChange={onChange}
        aria-label={label}
        autoComplete="on"
      />
    </div>
  )
})

ValidatedTextInput.displayName = 'ValidatedTextInput'

ValidatedTextInput.propTypes = {
  label: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  value: propTypes.string,
  placeHolder: propTypes.string,
  onChange: propTypes.func.isRequired,
  labelClassName: propTypes.string.isRequired,
  inputClassName: propTypes.string.isRequired,
}

ValidatedTextInput.defaultProps = {
  value: null,
  placeHolder: null,
}

export default memo(ValidatedTextInput)
