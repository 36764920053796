import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import Cell from './Cell'
import PreviewCell from './PreviewCell'
import { deleteImage } from '../../../store/images/imageAction'
import { updateBook } from '../../../store/books/bookAction'
import { updateCell } from '../../../store/cells/cellAction'
import './styles.scss'
import { useViewport } from '../../../hooks/useViewport'
import { getGridImageSizeAndPosition, getParent } from '../../../utils'
import TextEditorContainer from '../../Texts/TextEditorContainer'

const GridPage = ({ currentPreviewPage }) => {
  const bookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[bookId])
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const cellsFromState = useSelector((state) => state.cells)
  const user = useSelector((state) => state.user)
  const [cells, setCells] = useState(null)
  const [parent, setParent] = useState()
  const [page, setPage] = useState(null)
  const { width, height } = useViewport()
  const dispatch = useDispatch()

  const parentRef = React.useRef()

  useEffect(() => {
    if (currentPreviewPage) {
      setPage(currentPreviewPage)
    } else {
      setPage(currentPage)
    }
  }, [currentPreviewPage, currentPage])

  useEffect(() => {
    if (parentRef) {
      setParent(getParent(parentRef))
    }
  }, [parentRef, width, height, page])

  useEffect(() => {
    if (currentPreviewPage) {
      setCells(
        currentPreviewPage.cells.sort(
          (cell1, cell2) => cell1.position - cell2.position
        )
      )
    } else if (currentPage && currentPage.cells && cellsFromState) {
      const list = currentPage.cells
        .map((cellId) => cellsFromState[cellId])
        .sort((cell1, cell2) => cell1.position - cell2.position)
      setCells(list)
    }
  }, [currentPage, cellsFromState, currentPreviewPage])

  const moveItem = (cell, direction) => {
    if (direction === 'left') {
      const previousCell = cells.find((c) => c.position === cell.position - 1)
      if (previousCell) {
        dispatch(
          updateCell({
            ...previousCell,
            position: cell.position,
            x: cell.x,
            y: cell.y,
          })
        )
      }
      const { x, y } = getGridImageSizeAndPosition(
        currentPage.type,
        cell.position - 1
      )
      dispatch(updateCell({ ...cell, position: cell.position - 1, x, y }))
    } else if (direction === 'right') {
      const nextCell = cells.find((c) => c.position === cell.position + 1)
      if (nextCell) {
        dispatch(
          updateCell({ ...nextCell, position: cell.position, x: cell.x, y: cell.y })
        )
      }
      const { x, y } = getGridImageSizeAndPosition(
        currentPage.type,
        cell.position + 1
      )
      dispatch(updateCell({ ...cell, position: cell.position + 1, x, y }))
    }
  }

  const removeImage = (imageId) => {
    dispatch(deleteImage(imageId))
    if (user && !user.anonymous) {
      const updatedBook = {
        ...book,
        updated: Date.now(),
      }
      dispatch(updateBook(updatedBook))
    }
  }

  const getClasses = () => {
    if (currentPreviewPage) {
      return 'grid-layout'
    } else {
      return 'create-page-content grid-layout'
    }
  }

  const getBorderClasses = (position) => {
    if (page && page.type === 6) {
      if (position === 0) {
        return 'cell'
      } else if (position === 1) {
        return 'cell cell-no-right-border'
      } else if (position === 2) {
        return 'cell cell-no-bottom-border'
      }
      return 'cell cell-no-right-border cell-no-bottom-border'
    } else if (page && page.type === 7) {
      if (position === 2) {
        return 'cell cell-no-right-border'
      } else if (position > 3 && position < 5) {
        return 'cell cell-no-bottom-border'
      } else if (position === 5) {
        return 'cell cell-no-bottom-border cell-no-right-border'
      }
      return 'cell'
    } else if (page && page.type === 8) {
      if (position === 3 || position === 7) {
        return 'cell cell-no-right-border'
      } else if (position > 7 && position < 11) {
        return 'cell cell-no-bottom-border'
      } else if (position === 11) {
        return 'cell cell-no-bottom-border cell-no-right-border'
      }
      return 'cell'
    }
  }

  return (
    <div ref={parentRef} className={getClasses()}>
      {cells &&
        parent &&
        cells.length > 0 &&
        cells.map((cell) => (
          <div
            style={{
              position: 'absolute',
              top: cell.y * parent.height,
              left: cell.x * parent.width,
            }}
            className={getBorderClasses(cell.position)}
            key={cell.id}
          >
            {!currentPreviewPage ? (
              <Cell
                parent={parent}
                cell={cell}
                removeImage={removeImage}
                moveItem={moveItem}
                maxIndex={cells.length - 1}
                pageType={currentPage.type}
              />
            ) : (
              <PreviewCell
                parent={parent}
                cell={cell}
                pageFromApi={currentPreviewPage}
              />
            )}
          </div>
        ))}
      {currentPage &&
        !currentPreviewPage &&
        parent &&
        currentPage.texts &&
        currentPage.texts.map((boxId) => (
            <TextEditorContainer
              key={boxId}
              boxId={boxId}
              parent={parent}
              currentPage={currentPage}
          />
        ))}
    </div>
  )
}

GridPage.propTypes = {
  currentPreviewPage: propTypes.instanceOf(Object),
}

GridPage.defaultProps = {
  currentPreviewPage: null,
}

export default memo(GridPage)
