import { convertToObject } from '../../utils'
export const soundReducer = (state = {}, action) => {
  switch (action.type) {
    case 'INIT_SOUNDS':
      if (!action.data) {
        return {}
      } else {
        return action.data
      }
    case 'ADD_SOUND': {
      const id = action.data.id
      return {
        ...state,
        [id]: action.data,
      }
    }
    case 'UPDATE_SOUND': {
      const key = action.data.id
      return {
        ...state,
        [key]: action.data,
      }
    }
    case 'UPDATE_SOUNDS': {
      if (!action.data) {
        return state
      } else {
        const sounds = { ...state }
        const copyWithAddedSounds = Object.values(sounds).concat(
          Object.values(action.data)
        )
        return convertToObject(copyWithAddedSounds)
      }
    }
    case 'DELETE_SOUND': {
      const sounds = { ...state }
      const filtered = Object.values(sounds).filter((p) => p.id !== action.data)
      return convertToObject(filtered)
    }
    case 'DELETE_PAGE': {
      const copy2 = Object.values(state).filter(
        (sound) => sound.pageId !== action.data
      )
      return convertToObject(copy2)
    }
    case 'PURGE':
      return {}
    default:
      return state
  }
}
