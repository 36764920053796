import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import propTypes from 'prop-types'
import Button from '../Button'
import ModalContainer from '../ModalContainer'
import ModalHeader from '../ModalHeader'
import ValidatedTextInput from '../ValidatedTextInput'
import { useForm } from 'react-hook-form'
import { copyAndAddBookToState } from '../../store/books/bookAction'
import { startRootLoading } from '../../store/root/rootAction'
import { useDispatch, useSelector } from 'react-redux'

const CopyMaterialForm = ({ openCopyModal, closeCopyModal, id, oldName }) => {
  const { trackEvent } = useMatomo()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const books = useSelector((state) => state.books.books)

  const onSubmit = (data) => {
    try {
      dispatch(startRootLoading())
      dispatch(copyAndAddBookToState(id, data))
      closeCopyModal()
      navigate('/omat-kirjat')
      if (books[id].status === 98) {
        trackEvent({ category: 'Kirja', action: `Käytä pohjana (${id})` })
      } else {
        trackEvent({ category: 'Kirja', action: 'Kirja kopioitu' })
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  return (
    <ModalContainer
      modalIsOpen={openCopyModal}
      closeModal={closeCopyModal}
      label="Kopioi kirja"
      info
    >
      <form id="copy-form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader headerText="Kopioi kirja" onClick={closeCopyModal} />
        <ValidatedTextInput
          label="Uusi nimi"
          name="name"
          placeHolder="Kirjoita kirjalle uusi nimi"
          value={`${oldName}-(kopio)`}
          onChange={() => clearErrors()}
          labelClassName="input-label-class"
          inputClassName="text-input-field"
          {...register('name', {
            required: true,
            pattern: /^[0-9 A-Za-zäöüßÄÖÜ'!."/?=#&%() -]+$/,
          })}
        />
        <div className="validation-error-text">
          {errors.name && errors.name.type === 'required' && 'Kirjoita nimi.'}
          {errors.name &&
            errors.name.type === 'maxLength' &&
            'Nimi saa olla enintään 50 merkkiä pitkä.'}
          {errors.name && errors.name.type === 'pattern' && 'Nimi ei kelpaa.'}
        </div>
        <ValidatedTextInput
          label="Uusi kuvaus"
          name="description"
          placeHolder="Kirjoita kirjalle uusi kuvaus"
          onChange={() => clearErrors()}
          labelClassName="input-label-class"
          inputClassName="text-input-field"
          {...register('description', {
            required: false,
            pattern: /^[0-9 A-Za-z0-9äöüßÄÖÜ'!."/?=#&%() -]+$/,
          })}
        />
        <div className="validation-error-text">
          {errors.description &&
            errors.description.type === 'pattern' &&
            'Kuvaus ei kelpaa.'}
        </div>
        <div className="modal-button-container">
          <Button
            type="submit"
            className="dialogue-button select-button single-button"
          >
            Luo kopio
          </Button>
        </div>
      </form>
    </ModalContainer>
  )
}

CopyMaterialForm.propTypes = {
  oldName: propTypes.string.isRequired,
  id: propTypes.number,
  openCopyModal: propTypes.bool.isRequired,
  closeCopyModal: propTypes.func.isRequired,
}

CopyMaterialForm.defaultProps = {
  id: null,
}

export default CopyMaterialForm
