import React, { memo } from 'react'
import propTypes from 'prop-types'
import { DropdownButton, Form } from 'react-bootstrap'
import './styles.scss'

const SelectImageType = ({ imageTypes, setImageTypes }) => {
  const toggleItemsInImageTypeList = (target) => {
    let list
    if (target && target.checked) {
      list = imageTypes.concat(target.value)
    } else {
      list = imageTypes.filter((type) => type !== target.value)
    }
    setImageTypes(list)
  }

  return (
    <>
      {imageTypes && (
        <DropdownButton
          id="dropdown-item-button"
          title="Kuvatyyppi"
          role="menuitemcheckbox"
        >
          <Form tabIndex={0}>
            <Form.Control
              as="fieldset"
              multiple
              onChange={(e) => toggleItemsInImageTypeList(e.target)}
            >
              <Form.Label as="arasaac-checkbox">
                <Form.Check
                  defaultChecked={imageTypes.indexOf('arasaac') > -1}
                  type="checkbox"
                  value="arasaac"
                  label="ARASAAC-piirroskuvat"
                  id="arasaac-checkbox"
                  tabIndex={0}
                />
              </Form.Label>
              <Form.Label as="kuvako-checkbox">
                <Form.Check
                  defaultChecked={imageTypes.indexOf('kuvako') > -1}
                  type="checkbox"
                  value="kuvako"
                  label="KUVAKO-kuvat"
                  id="kuvako-checkbox"
                />
              </Form.Label>
              <Form.Label as="mulberry-checkbox">
                <Form.Check
                  defaultChecked={imageTypes.indexOf('mulberry') > -1}
                  type="checkbox"
                  value="mulberry"
                  label="Mulberry-kuvat"
                  id="mulberry-checkbox"
                />
              </Form.Label>
              <Form.Label as="drawing-checkbox">
                <Form.Check
                  defaultChecked={imageTypes.indexOf('drawing') > -1}
                  type="checkbox"
                  value="drawing"
                  label="Piirroskuvat"
                  id="drawing-checkbox"
                />
              </Form.Label>
              <Form.Label as="sclera-checkbox">
                <Form.Check
                  defaultChecked={imageTypes.indexOf('sclera') > -1}
                  type="checkbox"
                  value="sclera"
                  label="Sclera-piktogrammit"
                  id="sclera-checkbox"
                />
              </Form.Label>
              <Form.Label as="toisto-checkbox">
                <Form.Check
                  defaultChecked={imageTypes.indexOf('toisto') > -1}
                  type="checkbox"
                  value="toisto"
                  label="Toisto-kuvat"
                  id="toisto-checkbox"
                />
              </Form.Label>
              <Form.Label as="photo-checkbox">
                <Form.Check
                  defaultChecked={imageTypes.indexOf('photo') > -1}
                  type="checkbox"
                  value="photo"
                  label="Valokuvat"
                  id="photo-checkbox"
                />
              </Form.Label>

              <Form.Label as="sign-checkbox">
                <Form.Check
                  defaultChecked={imageTypes.indexOf('sign') > -1}
                  type="checkbox"
                  value="sign"
                  label="Viittomakuvat"
                  id="sign-checkbox"
                />
              </Form.Label>
            </Form.Control>
          </Form>
        </DropdownButton>
      )}
    </>
  )
}

SelectImageType.propTypes = {
  imageTypes: propTypes.instanceOf(Array).isRequired,
  setImageTypes: propTypes.func.isRequired,
}

export default memo(SelectImageType)
