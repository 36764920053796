import {
  addVideo as apiAddVideo,
  deleteVideo as apiDeleteVideo,
  updateVideo as apiUpdateVideo,
} from '../../api/books/videos'
import { getSizeAndPositionForImageOrVideo, pageTypeIsFree } from '../../utils'

export const addVideo = (video, config) => {
  return async (dispatch, getState) => {
    const state = getState()
    const currentPageId = state.pages.currentPage
    const currentPage = state.pages.pages[currentPageId]
    const coordinates = getSizeAndPositionForImageOrVideo(currentPage)
    const videoWithCoordinates = {
      ...video,
      x: coordinates && coordinates.x,
      y: coordinates && coordinates.y,
      w: coordinates && coordinates.w,
      h: coordinates && coordinates.h,
      width: video.width ? video.width : 300,
      height: video.height ? video.height : 188,
      resizable: pageTypeIsFree(currentPage.type) ? 1 : 0,
      draggable: pageTypeIsFree(currentPage.type) ? 1 : 0,
    }
    const res = await apiAddVideo(videoWithCoordinates, config)
    dispatch({
      type: 'ADD_VIDEO',
      data: res.data,
    })
  }
}

export const addVideoToState = (video) => {
  return async (dispatch) => {
    dispatch({
      type: 'ADD_VIDEO',
      data: video,
    })
  }
}

export const deleteVideo = (id) => {
  return async (dispatch) => {
    await apiDeleteVideo(id)
    dispatch({
      type: 'DELETE_VIDEO',
      data: id,
    })
  }
}

export const initializeVideos = (videos) => {
  return async (dispatch) => {
    dispatch({
      type: 'INIT_VIDEOS',
      data: videos,
    })
  }
}

export const updateVideo = (updated) => {
  return async (dispatch) => {
    const res = await apiUpdateVideo(updated)
    dispatch({
      type: 'UPDATE_VIDEO',
      data: res,
    })
  }
}

export const updateVideoStore = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_VIDEOS',
      data,
    })
  }
}
