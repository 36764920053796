import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Button from '../../Button'
import QRCode from 'qrcode.react'
import { isOpikeUser } from '../../../utils'
import '../styles.scss'

const ShareButtons = ({ book, cancelSharing }) => {
  const { trackEvent } = useMatomo()
  const user = useSelector((state) => state.user)
  const [linkClicked, setLinkClicked] = useState(false)
  const [opikeIdClicked, setOpikeIdClicked] = useState(false)
  const [downloadQrCode, setDownloadQrCode] = useState(false)
  const downloadRef = React.useRef()

  const copyLinkToClipboard = () => {
    setLinkClicked(true)
    setOpikeIdClicked(false)
    setDownloadQrCode(false)
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_FRONTEND_URL}/#/lue/${book.id}/${book.url}`
    )
  }

  const copyOpikeIdToClipboard = () => {
    setOpikeIdClicked(true)
    setLinkClicked(false)
    setDownloadQrCode(false)
    navigator.clipboard.writeText(book.url)
  }

  const downloadQrCodeAndShowNotification = () => {
    setDownloadQrCode(true)
    setLinkClicked(false)
    setOpikeIdClicked(false)
    const currentRef = downloadRef.current
    const canvas = document.querySelector('.HpQrcode > canvas')
    currentRef.href = canvas.toDataURL()
    currentRef.download = `${book.name}.png`
    currentRef.click()
    trackEvent({ category: 'Kirja', action: 'QR-koodi tallennettu' })
  }

  const getClasses = () => {
    if (linkClicked) {
      return 'share-link visited-link'
    }
    return 'share-link'
  }

  return (
    <>
      {downloadRef && (
        <div className="share-link-container">
          <h2>Linkki</h2>
          <a
            className={getClasses()}
            href={`${process.env.REACT_APP_FRONTEND_URL}/#/lue/${book.id}/${book.url}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {`${process.env.REACT_APP_FRONTEND_URL}/#/lue/${book.id}/${book.url}`}
          </a>
          <div className="share-settings-container">
            <div className="qrcode-container">
              <h3>
                <span className="uppercase-text">qr</span>-koodi
              </h3>
              <div className="HpQrcode">
                <QRCode
                  value={`${process.env.REACT_APP_FRONTEND_URL}/#/lue/${book.id}/${book.url}`}
                  size={100}
                />
              </div>
            </div>
            <div className="share-buttons-container">
              {isOpikeUser(user?.id) ? (
                <>
                  <Button
                    onClick={copyOpikeIdToClipboard}
                    className="dialogue-button select-button opike-copy-button"
                  >
                    Kopioi jakotunniste (Opike)
                  </Button>
                  <div style={{ height: '1.5em' }}>
                    {opikeIdClicked && (
                      <p style={{ margin: 0 }} className="success-text">
                        Jakotunniste kopioitu leikepöydälle.
                      </p>
                    )}
                  </div>
                </>
              ) : null}
              <Button
                onClick={copyLinkToClipboard}
                className="dialogue-button select-button"
              >
                Kopioi linkki
              </Button>
              <div style={{ height: '1.5em' }}>
                {linkClicked && (
                  <p style={{ margin: 0 }} className="success-text">
                    Linkki kopioitu leikepöydälle.
                  </p>
                )}
              </div>
              <Button
                onClick={downloadQrCodeAndShowNotification}
                className="dialogue-button select-button"
              >
                Tallenna QR-koodi
              </Button>
              <a ref={downloadRef} style={{ display: 'none', ariaHidden: true }}>
                Tallenna QR-koodi
              </a>
              <div style={{ height: '1em' }}>
                {downloadQrCode && (
                  <p style={{ margin: 0 }} className="success-text">
                    QR-koodi ladattu.
                  </p>
                )}
              </div>
            </div>
          </div>
          {book.status < 98 && (
            <div className="float-right-button">
              <Button
                onClick={() => cancelSharing(book)}
                className="dialogue-button gray-button single-button"
              >
                Peruuta jakaminen
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

ShareButtons.propTypes = {
  book: propTypes.instanceOf(Object).isRequired,
  cancelSharing: propTypes.func.isRequired,
}

export default memo(ShareButtons)
