import { initializeImages } from '../images/imageAction'
import { fetchBooksAndInitStore, initMaterials } from '../books/bookAction'
import { initializePages } from '../pages/pageAction'
import { initializeBlocks } from '../blocks/blockAction'
import { initializeBlockImages } from '../blockImages/blockImageAction'
import { initializeQuestions } from '../questions/questionAction'
import { initializeAnswers } from '../answers/answerAction'
import { initializeTexts } from '../texts/textAction'
import { initializeSounds } from '../sounds/soundAction'
import { initializeVideos } from '../videos/videoAction'
import { initializeCells } from '../cells/cellAction'

export const startRootLoading = () => {
  return async (dispatch) => {
    dispatch({
      type: 'START_ROOT_LOADING',
      data: true,
    })
  }
}

export const rootLoadingReady = () => {
  return async (dispatch) => {
    dispatch({
      type: 'ROOT_LOADING_READY',
      data: false,
    })
  }
}

export const initStore = (data) => {
  return async (dispatch, getState) => {
    const state = getState()
    const currentBook = state.books.currentBook
    const sortAlphabetically = state.books.sortAlphabetically
    const sortAscending = state.books.sortAscending
    const currentPage = state.pages.currentPage
    await dispatch(
      initMaterials({
        books: data.entities.books,
        currentBook,
        sortAlphabetically,
        sortAscending,
      })
    )
    await dispatch(initializePages({ pages: data.entities.pages, currentPage }))
    await dispatch(initializeBlocks(data.entities.blocks))
    await dispatch(initializeBlockImages(data.entities.blockImages))
    await dispatch(initializeQuestions(data.entities.questions))
    await dispatch(initializeAnswers(data.entities.answers))
    await dispatch(initializeTexts(data.entities.texts))
    await dispatch(initializeCells(data.entities.cells))
    await dispatch(initializeImages(data.entities.images))
    await dispatch(initializeSounds(data.entities.sounds))
    await dispatch(initializeVideos(data.entities.videos))
  }
}

export const resetStore = () => {
  return async (dispatch) => {
    dispatch({
      type: 'PURGE',
      data: undefined,
    })
  }
}

export const rootStartUp = () => {
  return async (dispatch) => {
    await dispatch(startRootLoading())
    // await dispatch(initializeUser())
    await dispatch(fetchBooksAndInitStore())
    await dispatch(rootLoadingReady())
  }
}
