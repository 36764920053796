import React from 'react'
import TopNavigation from '../components/Navigation/TopNavigation'
import MainNavigation from '../components/Navigation/MainNavigation'

const MainNavigationContainer = () => {
  return (
    <div>
      <TopNavigation />
      <MainNavigation />
    </div>
  )
}

export default MainNavigationContainer
