import {
  deletePage as apiDeletePage,
  updatePage as updatePageAtApi,
} from '../../api/books/pages'

export const addPage = (page) => {
  return async (dispatch) => {
    dispatch({
      type: 'ADD_PAGE',
      data: page,
    })
  }
}

export const deletePage = (id) => {
  return async (dispatch, getState) => {
    const state = getState()
    try {
      if (state.user && !state.user.anonymous) {
        await apiDeletePage(id)
      }
      dispatch({
        type: 'DELETE_PAGE',
        data: id,
      })
    } catch {
      dispatch({
        type: 'REMOVE_USER_FROM_STATE',
        data: null,
      })
    }
  }
}

export const getNewCurrentPage = (id) => {
  return {
    type: 'GET_NEW_CURRENT_PAGE',
    data: id,
  }
}

export const initializePages = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'INIT_PAGES',
      data,
    })
  }
}

export const updatePage = (updated) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) await updatePageAtApi(updated)
    dispatch({
      type: 'UPDATE_PAGE',
      data: updated,
    })
  }
}

export const updatePages = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_PAGES',
      data,
    })
  }
}
