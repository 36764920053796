import axios from 'axios'
const baseUrl = '/api/texts'

const getTextById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`)
  return response.data
}

const updateText = async (updatedText) => {
  const response = await axios.put(`${baseUrl}/${updatedText.id}`, updatedText)
  return response.data
}

const deleteText = (textId) => {
  return axios.delete(`${baseUrl}/${textId}`)
}

const createText = (textObject) => {
  return axios.post(baseUrl, textObject)
}

export { getTextById, createText, updateText, deleteText }
