import React, { memo, useState } from 'react'
import Button from '../../Button'
import { Form } from 'react-bootstrap'
import { sendFeedback } from '../../../api/users/users'
import { useForm } from 'react-hook-form'

const FeedbackForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm()
  const [showMessage, setShowMessage] = useState(false)

  const onSubmit = (data) => {
    const sender = data.email.trim()
    const message = data.message.trim()
    if (sender.length > 0 || message.length > 0) {
      const dataToSend = {
        sender,
        message,
      }
      sendFeedback(dataToSend)
      setShowMessage(true)
      reset()
    }
  }
  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="feedbackForm.ControlInput1">
        <Form.Label className="input-label-class">Sähköpostiosoitteesi</Form.Label>
        <Form.Control
          type="email"
          placeholder="Sähköpostiosoite"
          name="email"
          onChange={() => clearErrors()}
          {...register('email', {
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            maxLength: 254,
          })}
          disabled={showMessage}
        />
      </Form.Group>
      <div className="validation-error-text">
        {errors.email &&
          errors.email.type === 'pattern' &&
          'Sähköpostiosoite ei kelpaa.'}
        {errors.email &&
          errors.email.type === 'maxLength' &&
          'Sähköposti on liian pitkä.'}
      </div>
      <Form.Group controlId="feedbackForm.ControlTextarea1">
        <Form.Label className="input-label-class">
          Palaute <span style={{ color: 'red' }}>*</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="message"
          {...register('message', { required: true, maxLength: 10000 })}
          disabled={showMessage}
        />
      </Form.Group>
      <div className="validation-error-text">
        {errors.message &&
          errors.message.type === 'required' &&
          'Palautekenttä ei voi olla tyhjä.'}
        {errors.message &&
          errors.message.type === 'maxLength' &&
          'Viestin maksimipituus on 10 000 merkkiä.'}
        {showMessage && (
          <p style={{ margin: 0, fontSize: '1em' }} className="success-text">
            Kiitos palautteesta!
          </p>
        )}
      </div>
      {!showMessage ? (
        <Button
          className="dialogue-button select-button margin-top-button"
          type="submit"
        >
          Lähetä
        </Button>
      ) : (
        <button
          type="button"
          onClick={() => {}}
          className="dialogue-button gray-button margin-top-button inactive-button"
        >
          Lähetä
        </button>
      )}
      <p>
        <span style={{ color: 'red' }}>*</span> Pakollinen kenttä.
      </p>
    </Form>
  )
}

export default memo(FeedbackForm)
