import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from '../../../hooks/useDebounce'
import DraggableTextEditor from '../DraggableTextEditor'
import TextEditor from '../TextEditor'
import { updateBook } from '../../../store/books/bookAction'
import { updateText } from '../../../store/texts/textAction'
import '../styles.scss'
import { removeEmptyHtmlTags } from '../../../utils'

const TextEditorContainer = ({ boxId, parent }) => {
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const textbox = useSelector((state) => state.texts[boxId])
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [editorContent, setEditorContent] = useState(null)
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (textbox && textbox.html && !editorContent) {
      setEditorContent(removeEmptyHtmlTags(textbox.html))
    }
  }, [textbox])

  const saveText = (html) => {
    if (textbox) {
      const updated = {
        ...textbox,
        html,
      }
      if (textbox.html !== updated.html) {
        dispatch(updateText(updated))
        if (user && !user.anonymous) {
          const updatedBook = {
            ...book,
            updated: Date.now(),
          }
          dispatch(updateBook(updatedBook))
        }
      }
    }
    setEditorContent(html)
  }

  const debouncedEditorState = useDebounce(editorContent, 1000)

  const debouncedSaveText = async (html) => {
    saveText(html)
  }

  useEffect(() => {
    if (editorContent && dirty) {
      debouncedSaveText(debouncedEditorState)
    }
  }, [debouncedEditorState])

  const getWidth = () => {
    if (parent && textbox) {
      return textbox.w * parent.width
    }
  }

  const getHeight = () => {
    if (parent && textbox) {
      return textbox.h * parent.height
    }
  }

  const getFontSize = () => {
    if (parent) {
      return `${(parent.width / 1280) * 20 * 1.2}px`
    }
  }

  return (
    <>
      {textbox ? (
        textbox.draggable === 1 ? (
          <DraggableTextEditor
            boxId={textbox.id}
            parent={parent}
            saveText={saveText}
            draggable={textbox.draggable}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            setDirty={setDirty}
            getFontSize={getFontSize}
          />
        ) : (
          <div
            style={{
              width: getWidth(),
              height: getHeight(),
              position: 'absolute',
              top: parent ? textbox.y * parent.height : 0,
              left: parent ? textbox.x * parent.width : 0,
            }}
          >
            <div className="textbox-wrapper">
              {' '}
              <TextEditor
                textbox={textbox}
                parent={parent}
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                setDirty={setDirty}
                saveText={saveText}
                getFontSize={getFontSize}
                width={textbox.w * parent.width}
              />
            </div>
          </div>
        )
      ) : (
        <span>Ladataan...</span>
      )}
    </>
  )
}

TextEditorContainer.propTypes = {
  boxId: propTypes.number.isRequired,
  parent: propTypes.instanceOf(Object),
}

TextEditorContainer.defaultProps = {
  parent: null,
}

export default memo(TextEditorContainer)
