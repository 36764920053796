import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import Draggable from 'react-draggable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteItemDialogue from '../../DeleteItemDialogue'
import Button from '../../Button'
import TextEditor from '../TextEditor'
import BlockImages from '../BlockImages'
import EditImage from '../../Images/EditImage'
import {
  blockScaleDown,
  blockScaleUp,
  deleteBlock,
  updateBlock,
} from '../../../store/blocks/blockAction'
import '../styles.scss'
import { relativeFontSize, relativePaddingsForBlockRow } from '../../../utils'

const DraggableBlock = ({
  boxId,
  parent,
  editorContent,
  setEditorContent,
  setDirty,
  saveBlock,
  setOpenModal,
  pageType,
}) => {
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  const [openDeleteSingleImageModal, setOpenDeleteSingleImageModal] = useState(false)
  const [openEditBlockImageModal, setOpenEditBlockImageModal] = useState(false)

  const [imageBlockIdToDelete, setImageBlockIdToDelete] = useState(undefined)
  const [imageBlockIdToEdit, setImageBlockIdToEdit] = useState(undefined)

  const [position, setPosition] = useState(null)
  const [dragging, setDragging] = useState(false)
  const [width, setWidth] = useState(null)
  const textAreaRef = React.useRef()

  const dispatch = useDispatch()
  const blockbox = useSelector((state) => state.blocks[boxId])
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const currentPageBlockImageIds = currentPage.blockImages
  const blockImageData = useSelector((state) => state.blockImages?.images)
  const [currentBlockImages, setCurrentBlockImages] = useState(undefined)

  const getPosition = () => {
    setPosition({
      x: blockbox.x * parent.width,
      y: blockbox.y * parent.height,
    })
  }

  useEffect(() => {
    if (blockbox && parent) {
      getPosition()
    }
  }, [blockbox.x, blockbox.y, parent])

  useEffect(() => {
    if (!width || width !== blockbox.w * parent.width) {
      setWidth(blockbox.w * parent.width)
    }
  }, [blockbox.w, parent.width])

  useEffect(() => {
    const currentPageBlockImages = currentPageBlockImageIds.map(
      (id) => blockImageData[id]
    )
    setCurrentBlockImages(
      currentPageBlockImages
        .filter((bi) => bi?.blockId === boxId)
        .sort((a, b) => a.order - b.order)
    )
  }, [currentPageBlockImageIds, blockImageData])

  const nodeRef = React.useRef(null)

  const handleStop = (e, pos) => {
    updatePosition(pos.x, pos.y)
    setDragging(false)
  }
  const updatePosition = (x, y) => {
    const relativePositionX = x / parent.width
    const relativePositionY = y / parent.height
    const updated = {
      ...blockbox,
      bgColor: blockbox.bgColor,
      x: relativePositionX,
      y: relativePositionY,
    }
    dispatch(updateBlock(updated))
  }

  const deleteThisBlock = (id) => {
    dispatch(deleteBlock(id))
  }

  const onControlledDrag = (e, pos) => {
    setPosition(pos)
  }

  const scaleThisBlockUp = (id) => {
    dispatch(blockScaleUp(id))
  }

  const scaleThisBlockDown = (id) => {
    dispatch(blockScaleDown(id))
  }

  const scaleToPercent = (scale) => {
    return `${parseInt(scale * 100)}%`
  }

  return (
    <>
      {blockbox && parent && position && (
        <Draggable
          position={position}
          onDrag={onControlledDrag}
          onStart={() => setDragging(true)}
          onStop={handleStop}
          scale={1}
          grid={[0.1, 0.1]}
          handle=".block-image-row"
          cancel=""
          bounds=".create-page-content"
          nodeRef={nodeRef}
        >
          <div
            className="draggable-container block-draggable"
            ref={nodeRef}
            style={{
              position: 'absolute',
              zIndex: 100,
            }}
            tabIndex={0}
          >
            <div
              ref={textAreaRef}
              className="blockbox-wrapper"
              style={{
                backgroundColor: blockbox.bgColor,
              }}
            >
              <div
                className="block-image-row"
                style={{
                  fontSize: relativeFontSize(
                    blockbox.scale,
                    parent.width,
                    parent.height,
                    pageType
                  ),
                  padding: relativePaddingsForBlockRow('image'),
                }}
              >
                {currentBlockImages?.length ? (
                  <BlockImages
                    images={currentBlockImages}
                    setOpenDeleteSingleImageModal={setOpenDeleteSingleImageModal}
                    setImageBlockIdToDelete={setImageBlockIdToDelete}
                    setOpenEditBlockImageModal={setOpenEditBlockImageModal}
                    setImageBlockIdToEdit={setImageBlockIdToEdit}
                    scale={blockbox.scale}
                    pageType={pageType}
                    pageHeight={parent.height}
                    pageWidth={parent.width}
                  />
                ) : null}
                <Button
                  className={
                    currentBlockImages?.length
                      ? 'block-add-image-button move-to-right'
                      : 'block-add-image-button'
                  }
                  onClick={() => setOpenModal(true, boxId)}
                >
                  <FontAwesomeIcon icon={['far', 'plus-square']} aria-hidden />
                  {currentBlockImages?.length ? (
                    ''
                  ) : (
                    <span style={{ marginLeft: '10px' }}>Lisää kuva</span>
                  )}
                </Button>
              </div>
              <TextEditor
                blockbox={blockbox}
                parent={parent}
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                setDirty={setDirty}
                saveBlock={saveBlock}
                dragging={dragging}
                width={width}
                pageType={pageType}
              />
              {/* <FontAwesomeIcon
                icon="arrows-alt"
                className="move-item-handle"
                size={
                  blockbox.w * parent.width < 150 && blockbox.h * parent.height < 150
                    ? '1x'
                    : '2x'
                }
              /> */}
              <div className="block-tools">
                <Button
                  onClick={() => scaleThisBlockUp(blockbox.id)}
                  label="Suurenna blokin kuvia."
                  className={blockbox.scale >= 2 ? 'disabled' : ''}
                  disabled={blockbox.scale >= 2 ? true : false}
                >
                  <FontAwesomeIcon icon="plus" className="cancel-icon" aria-hidden />
                </Button>
                <span className="scale-display">
                  {scaleToPercent(blockbox.scale)}
                </span>
                <Button
                  onClick={() => scaleThisBlockDown(blockbox.id)}
                  label="Pienennä blokin kuvia."
                  className={blockbox.scale <= 0.6 ? 'disabled' : ''}
                  disabled={blockbox.scale <= 0.6 ? true : false}
                >
                  <FontAwesomeIcon
                    icon="minus"
                    className="cancel-icon"
                    aria-hidden
                  />
                </Button>
                <Button
                  onClick={() => setOpenDeleteContentModal(true)}
                  label="Poista blokki."
                  className=""
                >
                  <FontAwesomeIcon
                    icon="times"
                    className="cancel-icon"
                    aria-hidden
                  />
                </Button>
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {openDeleteContentModal && blockbox && (
        <DeleteItemDialogue
          openDeleteContentModal={openDeleteContentModal}
          setOpenDeleteContentModal={setOpenDeleteContentModal}
          deleteItem={deleteThisBlock}
          itemId={blockbox.id}
          textContent={['Haluatko varmasti poistaa blokin?', 'Kyllä, poista']}
        />
      )}
      {openDeleteSingleImageModal && blockbox && (
        <DeleteItemDialogue
          openDeleteContentModal={openDeleteSingleImageModal}
          setOpenDeleteContentModal={setOpenDeleteSingleImageModal}
          itemId={imageBlockIdToDelete}
          deletingBlockImage={true}
          textContent={[
            'Haluatko varmasti poistaa kuvan blokista?',
            'Kyllä, poista',
          ]}
        />
      )}
      {openEditBlockImageModal && blockbox && (
        <EditImage
          openEditImageModal={openEditBlockImageModal}
          setOpenEditImageModal={setOpenEditBlockImageModal}
          image={blockImageData[imageBlockIdToEdit]}
          editingBlockImage={true}
        />
      )}
    </>
  )
}

DraggableBlock.propTypes = {
  boxId: propTypes.number.isRequired,
  parent: propTypes.instanceOf(Object).isRequired,
  editorContent: propTypes.string,
  setEditorContent: propTypes.func.isRequired,
  setDirty: propTypes.func.isRequired,
  saveBlock: propTypes.func.isRequired,
  setOpenModal: propTypes.func,
  pageType: propTypes.number,
}

DraggableBlock.defaultProps = {
  editorContent: null,
}

export default memo(DraggableBlock)
