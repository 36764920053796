import React, { memo, useState, useEffect } from 'react'
import propTypes from 'prop-types'
import axios from 'axios'
import Button from '../../Button'
import SearchResults from '../SearchResults'
import SelectImageType from '../SelectImageType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const SearchByCategory = ({
  chooseImage,
  closeModal,
  imageTypes,
  setImageTypes,
}) => {
  const [searchString, setSearchString] = useState('')
  const [categories, setCategories] = useState([])
  const [results, setResults] = useState([])

  const [path, setPath] = useState('')
  const [loading, setLoading] = useState(false)
  const [initialLoadDone, setInitialLoadDone] = useState(false)
  const apiUrl = process.env.REACT_APP_KUVAPANKKI_API_URL

  const getImageTypes = () => {
    if (imageTypes.length < 8) {
      const typeString = imageTypes.join('-')
      return typeString
    }
    return 'all'
  }

  useEffect(() => {
    const getRootCategories = async () => {
      const res = await axios.get(`${apiUrl}/browse/class?jsonp&lang=FI`)
      const data = res.data
      setCategories(data.children)
    }
    const searchDatabase = async () => {
      try {
        const typesToSearch = getImageTypes()
        const res = await axios.get(
          `${apiUrl}/browse/class/${searchString}?type=${typesToSearch}&jsonp`
        )
        const data = res.data
        setPath(data.path)
        if (data.children.length && !data.images.length) {
          setCategories(data.children)
        } else {
          setResults(data)
          setCategories([])
        }
        if (res) setLoading(false)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('error ', e)
      }
    }

    if (loading || !initialLoadDone) {
      if (searchString === '') {
        getRootCategories()
        setInitialLoadDone(true)
      } else {
        searchDatabase()
      }
    }
  }, [searchString, loading])

  const searchCategory = (cat) => {
    setLoading(true)
    setSearchString(cat)
  }

  const resetCategories = () => {
    setPath('')
    setResults([])
    setSearchString('')
    setInitialLoadDone(false)
  }

  return (
    <div className="search-container">
      <div className="search-buttons-container">
        {path !== '' ? (
          <Button className="return-to-search-button" onClick={resetCategories}>
            <div className="icon-button-content">
              <FontAwesomeIcon
                icon="arrow-left"
                className="icon"
                style={{ marginRight: '5px' }}
              />
              Alkuun
            </div>
          </Button>
        ) : (
          <></>
        )}
        <SelectImageType imageTypes={imageTypes} setImageTypes={setImageTypes} />
        <Button
          onClick={() => searchCategory(searchString)}
          className="search-button"
          disabled={
            (imageTypes && imageTypes.length === 0) || categories?.length > 0
          }
        >
          Hae
        </Button>
      </div>
      {path && (
        <div>
          <h3 className="search-results-header">{path}</h3>
        </div>
      )}
      {loading ? (
        <div className="spinner-content">
          <FontAwesomeIcon icon="spinner" spin className="spinner-icon" size="2x" />
          <p>Ladataan...</p>
        </div>
      ) : (
        <ul className="categories-list">
          {categories?.length && !results?.images?.length
            ? categories.map((cat) => {
                return (
                  <li key={cat}>
                    <Button
                      onClick={() => searchCategory(cat)}
                      className="category-list-button"
                      label={`Etsi kategoriasta ${cat}.`}
                    >
                      {cat}
                    </Button>
                  </li>
                )
              })
            : null}
        </ul>
      )}
      {results && results.images && (
        <SearchResults
          results={results}
          chooseImage={chooseImage}
          closeModal={closeModal}
        />
      )}
    </div>
  )
}

SearchByCategory.propTypes = {
  chooseImage: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired,
  imageTypes: propTypes.instanceOf(Object).isRequired,
  setImageTypes: propTypes.func.isRequired,
}

export default memo(SearchByCategory)
