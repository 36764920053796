import React, { memo, useState } from 'react'
import PreviewBook from '../components/Preview/PreviewBook'

const PreviewContainer = () => {
  const [error, setError] = useState(null)

  return <PreviewBook error={error} setError={setError} />
}

export default memo(PreviewContainer)
