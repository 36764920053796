import React, { memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import propTypes from 'prop-types'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Button from '../../Button'
import CopyMaterialForm from '../../CopyMaterialForm'
import LoadingPage from '../../LoadingPage'
import ModalContainer from '../../ModalContainer'
import ShareModal from '../../ShareBook'
import ThumbnailImage from '../../ThumbnailImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'
import {
  chooseMaterial,
  fetchBookContentsAndAddToStore,
} from '../../../store/books/bookAction'
import { useSelector, useDispatch } from 'react-redux'

const OwnBooksList = ({ books, deleteBook, sortAlphabetically, sortAscending }) => {
  const { trackEvent } = useMatomo()
  const [openShareModal, setOpenShareModal] = useState(false)
  const [openCopyModal, setOpenCopyModal] = useState(false)
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [id, setId] = useState(null)
  const [oldName, setOldName] = useState(null)
  const loading = useSelector((state) => state.root.loading)
  const bookstore = useSelector((state) => state.books.books)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const copyBook = (id, oldName) => {
    setId(id)
    setOldName(oldName)
    setOpenCopyModal(true)
  }

  const closeCopyModal = () => {
    setOpenCopyModal(false)
    setOldName(null)
  }

  const getDate = (timestamp) => {
    const dateObject = dayjs(timestamp)
    const date = dateObject.format('DD.MM.YYYY')
    const time = dateObject.format('HH.mm')
    return `${date} klo ${time}`
  }
  const editBook = async (bookId, bookStatus) => {
    if (bookStatus === 98) {
      setOpenInfoModal(true)
    } else {
      dispatch(chooseMaterial(bookId))
      if (bookstore && bookstore[bookId] && bookstore[bookId].pages) {
        navigate(`/muokkaa`)
      } else {
        dispatch(fetchBookContentsAndAddToStore(bookId))
        navigate(`/muokkaa`)
      }
    }
  }
  const sortBooks = (a, b) => {
    if (
      sortAlphabetically &&
      typeof Object.values(books) === 'object' &&
      Object.values(books).length > 1
    ) {
      if (sortAscending) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        if (b.name.toLowerCase() > a.name.toLowerCase()) {
          return -1
        }
        return 0
      } else if (!sortAscending) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1
        }
        if (b.name.toLowerCase() < a.name.toLowerCase()) {
          return -1
        }
        return 0
      }
    } else {
      if (sortAscending) {
        const timeInMillisecondsA = dayjs(a.updated).valueOf()
        const timeInMillisecondsB = dayjs(b.updated).valueOf()
        return timeInMillisecondsB - timeInMillisecondsA
      } else if (!sortAscending) {
        const timeInMillisecondsA = dayjs(a.updated).valueOf()
        const timeInMillisecondsB = dayjs(b.updated).valueOf()
        return timeInMillisecondsA - timeInMillisecondsB
      }
    }
  }

  const shareBook = (bookId) => {
    dispatch(chooseMaterial(bookId))
    setOpenShareModal(true)
  }

  const viewBook = (bookId) => {
    dispatch(chooseMaterial(bookId))
    navigate('/esikatsele')
    trackEvent({ category: 'Kirja', action: `Oma kirja avattu (${bookId})` })
  }

  return (
    <>
      {!loading && books && Object.values(books).length < 1 && (
        <div className="empty-book-list">
          <p>Ei kirjoja.</p>
          <p>Voit luoda kirjan valitsemalla &ldquo;Luo uusi kirja&rdquo;.</p>
        </div>
      )}
      {loading && <LoadingPage message="Ladataan" />}
      {books && Object.values(books).length > 0 && !loading && (
        <ul className="book-list">
          {Object.values(books)
            .sort((a, b) => sortBooks(a, b))
            .map((book) => {
              return (
                <li className="book-item" key={book.id}>
                  <div className="book-container">
                    <div className="book-info-buttons-container">
                      <div className="book-info">
                        <span className="sr-only">Nimi: </span>
                        <h2 className="book-title">{book.name}</h2>
                        <p className="book-description">
                          <span className="sr-only">Kuvaus:</span>
                          {book.description}
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '0.8em' }}>
                          Muokattu {getDate(book.updated)}
                        </p>
                        {book.status > 0 && (
                          <Button
                            className="link-button"
                            onClick={() => shareBook(book.id)}
                            label={`Jakoasetukset ${book.name}`}
                            role="link"
                          >
                            {book.status === 1 && <p>Linkki jaettu</p>}
                            {book.status === 98 && (
                              <p>Jaettu muokattavissa kirjoissa</p>
                            )}
                          </Button>
                        )}
                      </div>
                      <ThumbnailImage book={book} viewStory={viewBook} />
                    </div>
                    <div className="books-button-container">
                      <Button
                        onClick={() => editBook(book.id, book.status)}
                        className="book-icon-button"
                        role="link"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'edit']}
                          className="edit-icon"
                        />
                        Muokkaa
                      </Button>
                      <Button
                        onClick={() => copyBook(book.id, book.name)}
                        className="book-icon-button"
                        role="link"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'copy']}
                          className="edit-icon"
                        />
                        Kopioi
                      </Button>
                      <Button
                        onClick={() => {
                          shareBook(book.id)
                        }}
                        className="book-icon-button"
                        role="link"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'share-square']}
                          className="edit-icon"
                        />
                        Jaa
                      </Button>
                      <Button
                        onClick={
                          book.status > 1
                            ? () => setOpenInfoModal(true)
                            : () => deleteBook(book.id)
                        }
                        className="book-icon-button"
                        role="link"
                      >
                        <FontAwesomeIcon
                          icon={['far', 'trash-alt']}
                          className="edit-icon"
                        />
                        Poista
                      </Button>
                    </div>
                  </div>
                </li>
              )
            })}
        </ul>
      )}
      {oldName && (
        <CopyMaterialForm
          openCopyModal={openCopyModal}
          closeCopyModal={closeCopyModal}
          id={id}
          oldName={oldName}
        />
      )}
      {openInfoModal && (
        <ModalContainer
          modalIsOpen={openInfoModal}
          closeModal={() => setOpenInfoModal(false)}
          info
          label="Muokattavissa jaetun kirjan muokkaus"
        >
          <div>
            <h2>Tämä kirja on jaettu muokattavissa kirjoissa.</h2>
            <p>Jos haluat muokata tai poistaa kirjan, peruuta ensin jako.</p>
            <Button
              onClick={() => setOpenInfoModal(false)}
              className="dialogue-button gray-button"
            >
              Sulje
            </Button>
          </div>
        </ModalContainer>
      )}
      {openShareModal && (
        <ShareModal
          modalIsOpen={openShareModal}
          closeModal={() => setOpenShareModal(false)}
        />
      )}
    </>
  )
}

OwnBooksList.propTypes = {
  books: propTypes.instanceOf(Object).isRequired,
  deleteBook: propTypes.func.isRequired,
  sortAlphabetically: propTypes.bool,
  sortAscending: propTypes.bool,
}

OwnBooksList.defaultProps = {
  sortAlphabetically: false,
  sortAscending: true,
}

export default memo(OwnBooksList)
