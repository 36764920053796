import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import pohja1b from '../img/pohja1b.png'
import pohja2b from '../img/pohja2b.png'
import pohja3b from '../img/pohja3b.png'
import pohja4b from '../img/pohja4b.png'
import pohja5b from '../img/pohja5b.png'
import apuruudukko from '../img/ruudukkopohja.png'
import kyselypohja from '../img/kyselypohja.png'
import pystypohja from '../img/pystypohja.png'
import Button from '../../../Button'
import './styles.scss'

const Template = ({
  chosenTemplate,
  setChosenTemplate,
  templateId,
  title,
  text,
  submitChoice,
}) => {
  const [showGridOptions, setShowGridOptions] = useState(false)
  const [choicesMade, setChoicesMade] = useState(false)

  const chooseTemplate = (e) => {
    e.target.focus()
    if (templateId === '6') {
      setShowGridOptions(true)
      setChosenTemplate(templateId)
    } else {
      setChosenTemplate(templateId)
      setChoicesMade(true)
    }
  }

  const chooseGridTemplate = (template) => {
    setChosenTemplate(template)
    setChoicesMade(true)
    setShowGridOptions(false)
  }

  const getIcon = () => {
    switch (templateId) {
      case '0':
        return pohja2b
      case '1':
        return pohja3b
      case '2':
        return pohja1b
      case '4':
        return pohja4b
      case '5':
        return pohja5b
      case '6':
        return apuruudukko
      case '9':
        return kyselypohja
      case '15':
        return pystypohja
      default:
        return null
    }
  }

  useEffect(() => {
    const submit = () => {
      if (chosenTemplate) {
        submitChoice()
      }
    }
    if (choicesMade) submit()
  }, [choicesMade])

  return (
    <>
      <div className="template-container">
        <Button
          className={
            chosenTemplate === templateId
              ? 'template-button active-template-button'
              : 'template-button'
          }
          onClick={(e) => chooseTemplate(e)}
          onKeyDown={(e) => chooseTemplate(e)}
          label={text}
        >
          <div className="template-image-container">
            <img src={getIcon()} alt="" />
          </div>
          <div className="template-text-content">
            <p>
              <strong>{title}</strong>
            </p>
            <p>{text}</p>
          </div>
        </Button>
      </div>
      {showGridOptions ? (
        <div style={{ marginLeft: '6px' }}>
          <p>Valitse ruudukon koko.</p>
          <div className="grid-buttons-container">
            <Button
              className="choose-grid-button"
              onClick={() => chooseGridTemplate('6')}
            >
              2x2
            </Button>
            <Button
              className="choose-grid-button"
              onClick={() => chooseGridTemplate('7')}
            >
              3x2
            </Button>
            <Button
              className="choose-grid-button"
              onClick={() => chooseGridTemplate('8')}
            >
              4x3
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

Template.propTypes = {
  chosenTemplate: propTypes.string.isRequired,
  setChosenTemplate: propTypes.func.isRequired,
  templateId: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  submitChoice: propTypes.func.isRequired,
}

export default memo(Template)
