import React, { memo } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../../Button'
import ValidatedTextInput from '../../../ValidatedTextInput'
import { useForm } from 'react-hook-form'

const EditPassword = ({ activate, onSubmit, response }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    watch,
  } = useForm()

  const submitForm = (data) => {
    onSubmit(data)
    reset()
  }

  return (
    <div className="edit-account-container edit-password-container">
      <h3>Vaihda salasana</h3>
      <form className="flex-form" onSubmit={handleSubmit(submitForm)}>
        <ValidatedTextInput
          label="Uusi salasana"
          name="password"
          placeHolder="Kirjoita salasana..."
          onChange={() => clearErrors()}
          labelClassName="input-label-class"
          inputClassName="text-input-field"
          {...register('password', { required: true, minLength: 8, maxLength: 128 })}
        />
        <div className="validation-error-text">
          {errors.password &&
            errors.password.type === 'required' &&
            'Kirjoita salasana.'}
          {errors.password &&
            errors.password.type === 'minLength' &&
            'Salasanan on oltava vähintään 8 merkkiä pitkä.'}
          {errors.password &&
            errors.password.type === 'maxLength' &&
            'Salasana on liian pitkä.'}
        </div>
        <div className="input-and-save-container">
          <ValidatedTextInput
            label="Anna salasana uudelleen"
            name="passwordConfirm"
            placeHolder="Kirjoita salasana..."
            onChange={() => clearErrors()}
            labelClassName="input-label-class"
            inputClassName="text-input-field"
            {...register('passwordConfirm', {
              required: true,
              validate: (value) => {
                return value === watch('password')
              },
            })}
          />
          <div className="validation-error-text">
            {errors.passwordConfirm &&
              errors.passwordConfirm.type === 'required' &&
              'Kirjoita salasana uudelleen.'}
            {errors.passwordConfirm &&
              errors.passwordConfirm.type === 'validate' &&
              'Salasanat eivät ole samat.'}
          </div>
          <Button
            className="dialogue-button select-button edit-account-button"
            type="submit"
          >
            <div className="edit-account-button-content">
              <FontAwesomeIcon icon={['far', 'save']} className="save-edited-icon" />
              {activate ? 'Aktivoi' : 'Tallenna'}
            </div>
          </Button>
          <p
            className={
              response && response.error
                ? 'backend-response-text red-text'
                : 'backend-response-text success-text'
            }
          >
            {response && response.message}
          </p>
        </div>
      </form>
    </div>
  )
}

EditPassword.propTypes = {
  activate: propTypes.bool,
  onSubmit: propTypes.func.isRequired,
  response: propTypes.instanceOf(Object),
}

EditPassword.defaultProps = {
  activate: false,
  response: null,
}

export default memo(EditPassword)
