import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import axios from 'axios'
import TextInput from '../../TextInput'
import Button from '../../Button'
import SearchResults from '../SearchResults'
import SelectImageType from '../SelectImageType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const SearchImageBank = ({
  setOpenCardModal,
  chooseImage,
  closeModal,
  imageTypes,
  setImageTypes,
}) => {
  const [searchString, setSearchString] = useState('')
  const [searchTitle, setSearchTitle] = useState('')
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const apiUrl = process.env.REACT_APP_KUVAPANKKI_API_URL

  // this is used to enable search by pressing enter
  const searchRef = React.useRef(searchString)

  const getImageTypes = () => {
    if (imageTypes.length < 8) {
      const typeString = imageTypes.join('-')
      return typeString
    }
    return 'all'
  }

  const setSearchTerm = (e) => {
    searchRef.current = e.target.value.toLowerCase()
    setSearchString(e.target.value.toLowerCase())
  }

  const searchDatabase = async (searchTerm) => {
    setMessage(null)
    if (searchTerm.length < 2) {
      setMessage('Tarkenna hakua!')
    } else {
      setSearchTitle('')
      setLoading(true)
      const typesToSearch = getImageTypes()
      const res = await axios.get(`${apiUrl}/search/${typesToSearch}/${searchTerm}`)
      if (res) {
        const data = res.data
        setResults(data)
        setSearchTitle(searchTerm)
        if (data.images.length === 0) {
          setMessage('Ei hakutuloksia. Kokeile toisella hakusanalla!')
        }
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        searchDatabase(`${searchRef.current}`)
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [imageTypes])

  return (
    <div className="search-container">
      <div className="search-input-and-buttons-container">
        <TextInput
          placeHolder="Etsi kuvapankista..."
          onChange={(e) => setSearchTerm(e)}
          name="Etsi kuvapankista"
          label="Etsi kuvapankista."
          showLabel={false}
          labelClassName="text-label-class"
          inputClassName="text-input-class"
          role="search"
        />
        <div className="search-buttons-container">
          <SelectImageType imageTypes={imageTypes} setImageTypes={setImageTypes} />
          <Button
            onClick={() => searchDatabase(searchString)}
            className="search-button"
            disabled={imageTypes && imageTypes.length === 0}
          >
            Hae
          </Button>
        </div>
      </div>
      {searchTitle && results?.images && results.images.length > 0 && (
        <div>
          <h3 className="search-results-header">{searchTitle}</h3>
        </div>
      )}
      {loading ? (
        <div className="spinner-content">
          <FontAwesomeIcon icon="spinner" spin className="spinner-icon" />
          <p>Ladataan...</p>
        </div>
      ) : (
        <></>
      )}
      {results && results.images && results.images.length > 0 ? (
        <SearchResults
          results={results}
          chooseImage={chooseImage}
          setOpenCardModal={setOpenCardModal}
          closeModal={closeModal}
        />
      ) : (
        <p className="no-results-text">{message}</p>
      )}
    </div>
  )
}

SearchImageBank.propTypes = {
  chooseImage: propTypes.func.isRequired,
  setOpenCardModal: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired,
  imageTypes: propTypes.instanceOf(Object).isRequired,
  setImageTypes: propTypes.func.isRequired,
}

export default memo(SearchImageBank)
