import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import propTypes from 'prop-types'
import Template from './Template'
import Button from '../../Button'
import ModalContainer from '../../ModalContainer'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isOpikeUser } from '../../../utils'

const Templates = ({ handleSubmit, setOpenTemplateModal }) => {
  const user = useSelector((state) => state.user)
  const [chosenTemplate, setChosenTemplate] = useState('')
  const [openModal, setOpenModal] = useState(false)

  const submitChoice = () => {
    if (chosenTemplate === 6) {
      setOpenModal(true)
    } else {
      handleSubmit(chosenTemplate)
    }
  }

  return (
    <div className="choose-template-modal-container">
      <ModalContainer
        closeModal={() => setOpenModal(false)}
        modalIsOpen={openModal}
        info
        label="Ruudukko-info"
      >
        <div className="info-modal-content">
          <p className="modal-text">Valitse ruudukon koko!</p>
          <Button onClick={() => setOpenModal(false)} className="close-modal-button">
            Sulje
          </Button>
        </div>
      </ModalContainer>
      <div className="choose-template-content">
        <div className="row-space-between-container">
          <h1 className="page-header">Valitse sivupohja</h1>
          <Button
            className="close-templates-button"
            onClick={() => setOpenTemplateModal(false)}
            label="sulje"
          >
            <FontAwesomeIcon
              icon={['far', 'times-circle']}
              className="template-icon"
              size="2x"
            />
          </Button>
        </div>
        {isOpikeUser(user?.id) ? (
          <div className="row-space-between-container">
            <h2 className="page-sub-header">Asettelu: Vaaka</h2>
          </div>
        ) : null}
        <div className="template-columns">
          <div className="left-template-column">
            <Template
              chosenTemplate={chosenTemplate}
              setChosenTemplate={setChosenTemplate}
              templateId="0"
              title="Kuva vasemmalla"
              text="Pystykuva vasemmalla ja tekstipalsta oikealla puolella"
              submitChoice={submitChoice}
            />
            <Template
              chosenTemplate={chosenTemplate}
              setChosenTemplate={setChosenTemplate}
              templateId="1"
              title="Kuva oikealla"
              text="Pystykuva oikealla ja tekstipalsta vasemmalla puolella"
              submitChoice={submitChoice}
            />
            <Template
              chosenTemplate={chosenTemplate}
              setChosenTemplate={setChosenTemplate}
              templateId="2"
              title="Kuva ylhäällä"
              text="Kuva ylhäällä ja tekstipalsta alapuolella"
              submitChoice={submitChoice}
            />
            <Template
              chosenTemplate={chosenTemplate}
              setChosenTemplate={setChosenTemplate}
              templateId="9"
              title="Kysely"
              text="Kirjoita kysymys, sekä lisää oikeat ja väärät vastausvaihtoehdot. Voit lisätä sivulle myös kuvia, ääntä ym."
              submitChoice={submitChoice}
            />
          </div>
          <div className="right-template-column">
            <Template
              chosenTemplate={chosenTemplate}
              setChosenTemplate={setChosenTemplate}
              templateId="4"
              title="Taustakuva ja tekstilaatikot"
              text="Koko sivun kokoinen taustakuva, jonka päällä tekstilaatikko tai useampi"
              submitChoice={submitChoice}
            />
            <Template
              chosenTemplate={chosenTemplate}
              setChosenTemplate={setChosenTemplate}
              templateId="5"
              title="Vapaa pohja"
              text="Täysin vapaasti sijoiteltavat kuvat, tekstit, videot ja äänet"
              submitChoice={submitChoice}
            />
            <Template
              chosenTemplate={chosenTemplate}
              setChosenTemplate={setChosenTemplate}
              templateId="6"
              title="Ruudukko"
              text="Sijoita kuvat, tekstit ja äänet halutun kokoiseen ruudukkoon"
              submitChoice={submitChoice}
            />
          </div>
        </div>
        {isOpikeUser(user?.id) ? (
          <div className="row-space-between-container">
            <h2 className="page-sub-header">Asettelu: Pysty</h2>
          </div>
        ) : null}
        {isOpikeUser(user?.id) ? (
          <div className="template-columns">
            <div className="left-template-column">
              <Template
                chosenTemplate={chosenTemplate}
                setChosenTemplate={setChosenTemplate}
                templateId="15"
                title="Vapaa pohja"
                text="Täysin vapaasti sijoiteltavat kuvat, tekstit, videot ja äänet"
                submitChoice={submitChoice}
              />
            </div>
            <div className="right-template-column"></div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

Templates.propTypes = {
  handleSubmit: propTypes.func.isRequired,
  setOpenTemplateModal: propTypes.func.isRequired,
}

export default memo(Templates)
