import React, { memo } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const ToolButton = ({ onClick, icon, text, inactive, customIcon = false }) => {
  const getClasses = () => {
    if (!inactive) {
      return 'tool-button'
    }
    return 'tool-button inactive-button'
  }
  return (
    <button
      className={getClasses()}
      onClick={onClick}
      tabIndex={0}
      aria-label={text}
    >
      <div className="icon-button-content">
        {customIcon ? (
          <img src={icon} className="tool-button-icon-custom" />
        ) : (
          <FontAwesomeIcon icon={icon} className="tool-button-icon" />
        )}
        <span className="tool-button-text">{text}</span>
      </div>
    </button>
  )
}

ToolButton.propTypes = {
  onClick: propTypes.func,
  icon: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(Array)])
    .isRequired,
  text: propTypes.string.isRequired,
  inactive: propTypes.bool,
  customIcon: propTypes.bool,
}

ToolButton.defaultProps = {
  onClick: null,
  inactive: false,
}

export default memo(ToolButton)
