import {
  createText,
  deleteText as apiDeleteText,
  updateText as apiUpdateText,
} from '../../api/books/texts'

export const addText = (page) => {
  return async (dispatch) => {
    const res = await createText(page)
    dispatch({
      type: 'ADD_TEXT',
      data: res.data,
    })
  }
}

export const addTextToState = (text) => {
  return async (dispatch) => {
    dispatch({
      type: 'ADD_TEXT',
      data: text,
    })
  }
}

export const deleteText = (id) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      await apiDeleteText(id)
    }
    dispatch({
      type: 'DELETE_TEXT',
      data: id,
    })
  }
}

export const initializeTexts = (texts) => {
  return async (dispatch) => {
    dispatch({
      type: 'INIT_TEXTS',
      data: texts,
    })
  }
}

export const updateText = (updated) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiUpdateText(updated)
      dispatch({
        type: 'UPDATE_TEXT',
        data: res,
      })
    } else {
      dispatch({
        type: 'UPDATE_TEXT',
        data: updated,
      })
    }
  }
}

export const updateTextStore = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_TEXTS',
      data,
    })
  }
}
