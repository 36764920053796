import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isIE } from 'react-device-detect'
import RecordRTC, { StereoAudioRecorder } from 'recordrtc'
import Button from '../Button'
import './styles.scss'

const AudioInput = ({ handleSubmit, setShowRecorder, showAudioModal }) => {
  const [stream, setStream] = useState(null)
  const [recorder, setRecorder] = useState(null)
  const [permission, setPermission] = useState(true)
  const [recordingFinished, setRecordingFinished] = useState(false)
  const [recording, setRecording] = useState(false)
  const [timer, setTimer] = useState(0)

  // This useEffect handles opening and closing audio stream
  useEffect(() => {
    if (showAudioModal && !isIE) {
      const openStream = async () => {
        try {
          const openedStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
          })
          if (openedStream) {
            setPermission(true)
            setStream(openedStream)
          } else {
            setShowRecorder(false)
          }
        } catch (e) {
          setPermission(false)
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }
      if (!stream) {
        openStream()
      }
    }
    return () => {
      if (stream) {
        stream.stop()
      }
    }
  }, [showAudioModal, stream])

  // This useEffect handles creating and setting recorder after stream is set
  useEffect(() => {
    if (stream) {
      setRecorder(
        new RecordRTC(stream, {
          type: 'audio',
          disableLogs: true,
          mimeType: 'audio/wav',
          recorderType: StereoAudioRecorder,
        })
      )
    }
  }, [stream])

  useEffect(() => {
    if (recorder) {
      recorder.startRecording()
      setRecording(true)
    }
  }, [recorder])

  useEffect(() => {
    if (permission) {
      const handler = setTimeout(() => {
        if (timer < 181) {
          setTimer(timer + 1)
        } else {
          setRecordingFinished(true)
        }
      }, 1000)
      return () => {
        clearTimeout(handler)
      }
    }
  }, [permission, recording, timer])

  useEffect(() => {
    const stopRecording = () => {
      if (recorder) {
        setShowRecorder(false)
        recorder.stopRecording(() => {
          const blob = recorder.getBlob()
          const file = new File([blob], 'audio.wav')
          handleSubmit(file)
        })
      } else {
        setShowRecorder(false)
      }
    }
    if (recordingFinished) stopRecording()
  }, [recordingFinished])

  return (
    <main className="audio-recording-content">
      {isIE && (
        <div className="IE-info-container">
          <p className="IE-info-text">
            Interner Explorer -selaimella ei valitettavasti voi äänittää.
          </p>
          <Button
            onClick={() => setShowRecorder(false)}
            className="dialogue-button danger-button"
          >
            Sulje
          </Button>
        </div>
      )}
      {!recorder && !isIE && (
        <div className="audio-description-text">
          <p>Odottaa mikrofonia...</p>
        </div>
      )}
      {recorder && permission && (
        <div>
          <div className="audio-recording-info">
            <span className={!showAudioModal ? 'pulse-done' : 'pulse-recording'} />
          </div>
          <div className="audio-description-text">
            {!showAudioModal ? 'Äänitys valmis' : 'Äänitys käynnissä...'}
            <p>{timer} sekuntia</p>
            <p>Äänite voi olla enintään 180 sekuntia pitkä.</p>
          </div>
          <div className="audio-control-buttons">
            <Button
              className="dialogue-button select-button single-button"
              onClick={() => setRecordingFinished(true)}
            >
              Tallenna
            </Button>
          </div>
        </div>
      )}
      {!permission && (
        <div className="audio-message-error-container">
          <p>Jos haluat lisätä äänitteen, anna lupa mikrofonin käytölle.</p>
          <Button
            className="dialogue-button gray-button"
            onClick={() => setShowRecorder(false)}
          >
            Sulje
          </Button>
        </div>
      )}
    </main>
  )
}

AudioInput.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setShowRecorder: PropTypes.func.isRequired,
  showAudioModal: PropTypes.bool.isRequired,
}

export default memo(AudioInput)
