import React, { memo } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'
import { useViewport } from '../../../hooks/useViewport'

const UploadIcons = ({ currentPage }) => {
  const { width } = useViewport()

  const getSize = () => {
    if (width > 1024) {
      return '5x'
    } else if (width > 768) {
      return '4x'
    } else if (width > 576) {
      return '3x'
    }
    return '1x'
  }

  const getStyle = () => {
    switch (currentPage.type) {
      case 0:
        return 'image-left-image-input-container'
      case 1:
        return 'image-right-image-input-container'
      case 2:
        return 'image-top-image-input-container'
      case 4:
        return 'full-image-input-container'
      default:
        return null
    }
  }

  return (
    <div className={getStyle()}>
      <div className="upload-icons-container">
        <div className="upload-icons-content">
          <div className="upload-icons">
            <FontAwesomeIcon
              icon={['far', 'file-image']}
              className="upload-image-icon"
              size={getSize()}
            />
            <FontAwesomeIcon
              icon={['far', 'file-video']}
              className="upload-video-icon"
              size={getSize()}
            />
          </div>
          <p>Lisää kuva tai video työkalupalkista.</p>
        </div>
      </div>
    </div>
  )
}

UploadIcons.propTypes = {
  currentPage: propTypes.instanceOf(Object).isRequired,
}

export default memo(UploadIcons)
