import axios from 'axios'
const baseUrl = '/api/videos'

const addVideo = (video, config) => {
  try {
    return axios.post(baseUrl, video, config)
  } catch (e) {
    throw new Error(e)
  }
}

const deleteVideo = (videoId) => {
  return axios.delete(`${baseUrl}/${videoId}`)
}

const getVideoById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`)
  return response.data
}

const updateVideo = async (updatedVideo) => {
  const response = await axios.put(`${baseUrl}/${updatedVideo.id}`, updatedVideo)
  return response.data
}

export { deleteVideo, getVideoById, addVideo, updateVideo }
