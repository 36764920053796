import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Button from '../Button'
import MainNavigation from '../Navigation/MainNavigation'
import TopNavigation from '../Navigation/TopNavigation'

const InfoPage = () => {
  const user = useSelector((state) => state.user)
  const navigate = useNavigate()
  return (
    <div className="info-text-container">
      <TopNavigation />
      {user && !user.anonymous ? (
        <MainNavigation />
      ) : (
        <div style={{ width: '100%', height: '8px', background: 'gray' }} />
      )}
      <div className="info-text-content">
        <h1 className="h1-title">Tietoa Papurista</h1>
        <p>
          Papuri on työkalu, jolla voi tehdä verkkokirjoja. Kirjoissa voi käyttää
          tekstiä, kuvaa, videota ja äänitiedostoja.
        </p>
        <p>
          Papurilla voit luoda esimerkiksi omia tarinoita, selkokielisiä
          tietokirjoja, toimintaohjeita tai valokuva-albumeita. Mitä ikinä
          keksitkään.
        </p>
        <p>
          Voit valita sivun muodon neljästä eri vaihtoehdosta tai sijoitella
          elementit täysin vapaasti.{' '}
        </p>
        <p>
          Voit ladata kuvakirjaan kuvia, ääniä ja lyhyitä videoita omalta koneeltasi.
          Voit myös käyttää Papunetin kuvapankin yli 37 000 kuvaa.
        </p>
        <p>
          Voit jakaa verkossa luettavan kirjan esimerkiksi sähköpostilla,
          Facebookissa tai verkkosivuillasi. Voit myös tallentaa valmiin kirjan
          PDF-muodossa lukemista ja tulostamista varten.
        </p>
        <h2 className="h2-title">Tekijänoikeudet</h2>
        <p>
          Muistathan, että verkossa julkaistaviin kirjoihin pätevät samat
          tekijänoikeussäännöt kuin painettuihin tuotteisiin.
        </p>
        <p>
          Kun jaat kirjasi muille, varmista, että sinulla on tekijänoikeus tai
          käyttöoikeus kirjassa käytettyihin kuviin, teksteihin, videoihin ja ääniin.
        </p>
        <h2 className="h2-title">Jos käytät Papunetin kuvapankin kuvia</h2>
        <p>
          Papunetin kuvapankin kuvat on lisensoitu Creative Commons -lisenssillä{' '}
          <a
            className="info-link"
            href="http://creativecommons.org/licenses/by-nc-sa/3.0/deed.fi"
          >
            (Nimeä-Epäkaupallinen-JaaSamoin).
          </a>
        </p>
        <p>
          Tämä tarkoittaa sitä, että kuvien käyttö kaupallisiin tarkoituksiin, esim.
          myytävissä tuotteissa, on kielletty. Epäkaupallisissa tarkoituksissa kuvia
          saa käyttää ja jakaa muokkaamattomina, kunhan kuvien alkuperäinen lähde on
          mainittu.
        </p>
        <p>
          Kuvia saa myös muokata, esimerkiksi rajata, värittää tai yhdistää kaksi
          kuvaa. Tällöin on alkuperäisen tekijän ja lähteen lisäksi mainittava, että
          kuva on muokattu versio alkuperäisestä. Muokattujen kuvien käyttöön pätevät
          samat rajoitukset kuin alkuperäisiin.
        </p>
        <p>
          Alkuperäisenä lähteenä on mainittava Papunetin kuvapankki, www.papunet.net
          ja kuvan alkuperäinen tekijä.
        </p>
        <p>
          Kuvan alkuperäinen tekijä löytyy kuvatyökalussa kunkin kuvan lisätiedoista.
        </p>
        <p>Esimerkki alkuperäisen lähteen merkitsemisestä:</p>
        <p>Kuva: Papunetin kuvapankki, www.papunet.net, Päivi Honkonen</p>
        <br />
        <Button
          className="select-button dialogue-button info-page-button"
          onClick={() => navigate(-1)}
        >
          Takaisin
        </Button>
      </div>
    </div>
  )
}

export default InfoPage
