import axios from 'axios'
const baseUrl = '/api/questions'

const getQuestionById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`)
  return response.data
}

const updateQuestion = async (updatedQuestion) => {
  const response = await axios.put(
    `${baseUrl}/${updatedQuestion.id}`,
    updatedQuestion
  )
  return response.data
}

const deleteQuestion = (questionId) => {
  return axios.delete(`${baseUrl}/${questionId}`)
}

const createQuestion = (questionObject) => {
  return axios.post(baseUrl, questionObject)
}

const questionScaleUp = async (questionId) => {
  const response = await axios.put(`${baseUrl}/${questionId}/scale-up`)
  return response.data
}

const questionScaleDown = async (questionId) => {
  const response = await axios.put(`${baseUrl}/${questionId}/scale-down`)
  return response.data
}

export {
  getQuestionById,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  questionScaleUp,
  questionScaleDown,
}
