import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Button from '../Button'
import { getNewCurrentPage, updatePage } from '../../store/pages/pageAction'
import { updateBook } from '../../store/books/bookAction'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import './styles.scss'
import PageThumbnail from './PageThumbnail'
import { pageLayoutIsHorizontal } from '../../utils'

const PagesSider = ({
  setOpenTemplateModal,
  openDeletePageModal,
  setOpenDeletePageModal,
  setDeletedPageId,
}) => {
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const allPages = useSelector((state) => state.pages.pages)
  const [pages, setPages] = useState(null)
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (book && book.pages && allPages) {
      const pageList = book.pages
        .map((id) => allPages[id])
        .filter((p) => p !== undefined)
        .sort((p1, p2) => p1.order - p2.order)
      setPages(pageList)
    }
  }, [book, allPages])

  useEffect(() => {
    let currentPageIndex
    if (pages) {
      const sortedBookPages = Object.values(pages)
        .filter((p) => book.pages.includes(p.id))
        .sort((p1, p2) => p1.order - p2.order)
      sortedBookPages.find((p, index) => {
        if (currentPage.id === p.id) {
          currentPageIndex = index
        }
      })
    }
    if (
      currentPage &&
      currentPage.id &&
      pages &&
      currentPageIndex === pages.length - 1
    ) {
      const currentElement = document.getElementById(`page-${currentPageId}`)
      if (currentElement && !isMobile) {
        currentElement.scrollIntoView(false, {
          behavior: 'smooth',
        })
      }
    }
  }, [currentPage, pages])

  const showPage = (pageId) => {
    dispatch(getNewCurrentPage(pageId))
    return
  }

  useEffect(() => {
    if (!openDeletePageModal) setDeletedPageId(null)
  })

  const chooseForDeletion = (page) => {
    setOpenDeletePageModal(true)
    setDeletedPageId(page.id)
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    updatePageOrder(result.source.index, result.destination.index)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const updatePageOrder = (startIndex, endIndex) => {
    const reorderedList = reorder(pages, startIndex, endIndex)
    reorderedList.forEach((page, index) => {
      const updated = {
        ...page,
        order: index,
      }
      dispatch(updatePage(updated))
    })
    if (user && !user.anonymous) {
      const updatedBook = {
        ...book,
        updated: Date.now(),
      }
      dispatch(updateBook(updatedBook))
    }
  }
  return (
    <div className="pages-sider-container sider-container">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="page-list-id" direction="vertical">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="page-list"
            >
              {!pages && (
                <FontAwesomeIcon icon="spinner" spin className="spinner-icon" />
              )}
              {pages &&
                pages.map((page, index) => {
                  return (
                    <Draggable
                      key={page.id}
                      draggableId={page && page.id.toString()}
                      index={index}
                      style={{ width: '100%', height: '100%' }}
                      disableInteractiveElementBlocking
                    >
                      {(provided) => (
                        <div
                          className={
                            currentPage.id === page.id
                              ? pageLayoutIsHorizontal(page.type)
                                ? 'page-list-item active-page-list-item'
                                : 'page-list-item page-list-item-vertical-layout active-page-list-item'
                              : pageLayoutIsHorizontal(page.type)
                              ? 'page-list-item'
                              : 'page-list-item page-list-item-vertical-layout'
                          }
                          id={`page-${page.id}`}
                          tabIndex={0}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Button
                            className="delete-page-button"
                            onClick={() => chooseForDeletion(page)}
                            label={`Poista sivu ${index + 1}`}
                            role="link"
                          >
                            <FontAwesomeIcon
                              icon={['far', 'times-circle']}
                              className="delete-page-icon"
                            />
                          </Button>
                          <PageThumbnail
                            page={page}
                            key={page.id}
                            showPage={showPage}
                            index={index}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="add-page-icon-container">
        <Button
          className="add-page-button"
          onClick={() => setOpenTemplateModal(true)}
          label="lisää uusi sivu"
          role="link"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={['far', 'plus-square']}
              className="add-page-icon"
            />
            <span style={{ fontSize: '0.8em' }}>Lisää sivu</span>
          </div>
        </Button>
      </div>
    </div>
  )
}

PagesSider.propTypes = {
  setOpenTemplateModal: propTypes.func.isRequired,
  openDeletePageModal: propTypes.bool.isRequired,
  setOpenDeletePageModal: propTypes.func.isRequired,
  setDeletedPageId: propTypes.func.isRequired,
}

export default memo(PagesSider)
