import React, { memo } from 'react'
import propTypes from 'prop-types'
import './styles.scss'

const Button = ({
  id,
  className,
  children,
  type,
  onClick,
  onMouseDown,
  label,
  role,
  ariaDescribedby,
  style,
  disabled,
}) => {
  const handleKeyPress = (event) => {
    event.preventDefault()
    if (event.key === 'Enter') {
      onClick()
    }
  }
  return (
    <button
      id={id}
      className={className}
      type={type}
      tabIndex={0}
      onClick={onClick}
      onKeyPress={(e) => handleKeyPress(e)}
      onMouseDown={onMouseDown}
      aria-label={label}
      role={role}
      aria-describedby={ariaDescribedby}
      style={{ style }}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  id: propTypes.string,
  className: propTypes.string.isRequired,
  children: propTypes.oneOfType([
    propTypes.string,
    propTypes.instanceOf(Array),
    propTypes.node,
  ]).isRequired,
  type: propTypes.string,
  onClick: propTypes.func,
  onMouseDown: propTypes.func,
  label: propTypes.string,
  role: propTypes.string,
  ariaDescribedby: propTypes.string,
  style: propTypes.string,
  disabled: propTypes.bool,
}

Button.defaultProps = {
  id: null,
  type: 'button',
  onClick: null,
  onMouseDown: null,
  label: null,
  role: null,
  ariaDescribedby: null,
  style: null,
}

export default memo(Button)
