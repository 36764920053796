import React, { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { chooseMaterial } from '../store/books/bookAction'
import PreviewBook from '../components/Preview/PreviewBook'

const ReadStoryContainer = () => {
  const { bookId } = useParams()
  const { bookUrl } = useParams()
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    if (bookId && bookUrl) {
      dispatch(chooseMaterial(bookId))
    }
  }, [bookId])

  return (
    <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
      <PreviewBook
        error={error}
        setError={setError}
        bookId={bookId}
        bookUrl={bookUrl}
      />
    </div>
  )
}

export default memo(ReadStoryContainer)
