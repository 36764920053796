import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import Button from '../../Button'
import DraggableResizable from '../../DraggableResizable'
import DeleteItemDialogue from '../../DeleteItemDialogue'
import EditImage from '../EditImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deleteImage, updateImage } from '../../../store/images/imageAction'
import { updateBook } from '../../../store/books/bookAction'
import './styles.scss'

const Image = ({ imageId, parent }) => {
  const imageRef = React.useRef()
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  const [openEditImageModal, setOpenEditImageModal] = useState(false)

  const dispatch = useDispatch()
  const bookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[bookId])
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const images = useSelector((state) => state.images.images)
  const image = useSelector((state) => state.images.images[imageId])
  const user = useSelector((state) => state.user)

  const removeImage = () => {
    if (image.z < currentPage.images.length) {
      Object.values(images)
        .filter((img) => currentPage.images.includes(img.id))
        .filter((i) => i.id !== image.id && i.z < image.z)
        .forEach((element) => {
          const updatedElement = {
            ...element,
            z: element.z === 1 ? 1 : element.z - 1,
          }
          dispatch(updateImage(updatedElement))
        })
    }
    dispatch(deleteImage(image.id))
    if (user && !user.anonymous) {
      const updatedBook = {
        ...book,
        updated: Date.now(),
      }
      dispatch(updateBook(updatedBook))
    }
  }
  const handleResize = () => {
    const rect = imageRef.current.getBoundingClientRect()
    const updated = {
      ...image,
      w: rect.width / parent.width,
      h: rect.height / parent.height,
    }
    dispatch(updateImage(updated))
  }
  const getWidth = () => {
    return image.w * parent.width
  }
  const getHeight = () => {
    if (image.w === 0.3 && image.h === 0.3) return 'auto'
    return image.h * parent.height
  }
  return (
    <>
      {image && image.draggable ? (
        <div
          className="draggable-image-content"
          tabIndex={0}
          style={{ zIndex: image.z }}
        >
          <DraggableResizable
            item={image}
            parent={parent}
            handleResize={handleResize}
            dragHandle=".image-container, .move-item-handle"
            updateItem={updateImage}
            lockAspectRatio
          >
            <div className="image-container" ref={imageRef}>
              <img
                src={image.filename}
                alt={image.alt}
                width={image.w ? '100%' : image.width}
                height={image.h ? '100%' : image.height}
                draggable="false"
              />
            </div>
            <Button
              className="edit-image-button"
              onClick={() => setOpenEditImageModal(true)}
              role="link"
              label="muokkaa kuvan tekstivastinetta"
            >
              <FontAwesomeIcon
                icon={['far', 'edit']}
                size={
                  image.w * parent.width < 150 && image.h * parent.height < 150
                    ? 'xs'
                    : '1x'
                }
              />
            </Button>
            <Button
              className="delete-item-button"
              onClick={() => setOpenDeleteContentModal(true)}
              role="link"
              label="poista kuva"
            >
              <FontAwesomeIcon icon="times" className="cancel-icon" />
            </Button>
            <FontAwesomeIcon
              icon="arrows-alt"
              className="handle move-item-handle"
              size={
                image.w * parent.width < 150 && image.h * parent.height < 150
                  ? '1x'
                  : '2x'
              }
            />
          </DraggableResizable>
        </div>
      ) : (
        <>
          {image && parent && (
            <div
              className="uploaded-image-container"
              style={{
                width: getWidth(),
                height: getHeight(),
                position: 'absolute',
                top: image.y * parent.height,
                left: image.x * parent.width,
              }}
            >
              <Button
                className="edit-image-button"
                onClick={() => setOpenEditImageModal(true)}
                role="link"
                label="muokkaa kuvan tekstivastinetta"
              >
                <FontAwesomeIcon icon={['far', 'edit']} className="cancel-icon" />
              </Button>
              <Button
                className="remove-image-button"
                onClick={() => setOpenDeleteContentModal(true)}
                role="link"
              >
                Tyhjennä
              </Button>
              <img
                src={image.filename}
                alt={image.alt ? image.alt : ''}
                height="100%"
                width="100%"
                className="uploaded-image-content"
                draggable="false"
              />
            </div>
          )}
        </>
      )}
      {openDeleteContentModal && (
        <DeleteItemDialogue
          openDeleteContentModal={openDeleteContentModal}
          setOpenDeleteContentModal={setOpenDeleteContentModal}
          deleteItem={removeImage}
          textContent={['Haluatko varmasti poistaa kuvan?', 'Kyllä, poista']}
        />
      )}
      {openEditImageModal && (
        <EditImage
          openEditImageModal={openEditImageModal}
          setOpenEditImageModal={setOpenEditImageModal}
          image={image}
        />
      )}
    </>
  )
}

Image.propTypes = {
  imageId: propTypes.number,
  parent: propTypes.instanceOf(Object),
}

Image.defaultProps = {
  imgeId: 0,
  parent: null,
}

export default memo(Image)
