import {
  addSound as apiAddSound,
  deleteSound as apiDeleteSound,
  updateSound as apiUpdateSound,
} from '../../api/books/sounds'
import { updateCell } from '../cells/cellAction'

export const addSound = (sound) => {
  return async (dispatch) => {
    const res = await apiAddSound(sound)
    dispatch({
      type: 'ADD_SOUND',
      data: res.data,
    })
  }
}

export const addSoundToCell = (sound, cell) => {
  return async (dispatch) => {
    return apiAddSound(sound).then((res) => {
      dispatch(updateCell({ ...cell, soundId: res.data.id }))
      dispatch({
        type: 'ADD_SOUND',
        data: res.data,
      })
    })
  }
}

export const updateSoundStore = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_SOUNDS',
      data,
    })
  }
}

export const deleteSound = (id) => {
  return async (dispatch) => {
    await apiDeleteSound(id)
    dispatch({
      type: 'DELETE_SOUND',
      data: id,
    })
  }
}

export const initializeSounds = (sounds) => {
  return async (dispatch) => {
    dispatch({
      type: 'INIT_SOUNDS',
      data: sounds,
    })
  }
}

export const updateSound = (updated) => {
  return async (dispatch) => {
    const res = await apiUpdateSound(updated)
    dispatch({
      type: 'UPDATE_SOUND',
      data: res,
    })
  }
}
