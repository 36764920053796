import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../Button'
import DeleteItemDialogue from '../DeleteItemDialogue'
import DraggableResizable from '../DraggableResizable'
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateVideo } from '../../store/videos/videoAction'
import { getMP4, getWebM, getVideoThumbnail } from '../../utils'
import './styles.scss'

const Video = ({ removeVideo, parent }) => {
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  const [playVideo, setPlayVideo] = useState(false)
  const dispatch = useDispatch()
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const video = useSelector((state) => state.videos[currentPage.videos[0]])
  const videoRef = React.useRef()

  useEffect(() => {
    setPlayVideo(false)
  }, [currentPageId])

  const handleResize = () => {
    const rect = videoRef.current.getBoundingClientRect()
    const updated = {
      ...video,
      w: rect.width / parent.width,
      h: rect.height / parent.height,
    }
    dispatch(updateVideo(updated))
  }

  return (
    <>
      {video && video.draggable ? (
        <DraggableResizable
          item={video}
          parent={parent}
          handleResize={handleResize}
          dragHandle=".video-container"
          updateItem={updateVideo}
          lockAspectRatio
        >
          <div className="video-container" ref={videoRef}>
            {!playVideo ? (
              <div className="video-thumbnail-container">
                <Button
                  className="play-icon-button"
                  onClick={() => setPlayVideo(true)}
                >
                  <FontAwesomeIcon
                    icon="play-circle"
                    className="play-button"
                    aria-hidden
                    size={
                      video.w * parent.width < 150 && video.h * parent.height < 150
                        ? '1x'
                        : '2x'
                    }
                  />
                  <span className="sr-only">Toista video</span>
                </Button>
                <Button
                  className="delete-item-button"
                  onClick={() => setOpenDeleteContentModal(true)}
                  label="Poista video."
                >
                  <FontAwesomeIcon
                    aria-hidden
                    icon="times"
                    className="cancel-icon"
                  />
                </Button>
                <FontAwesomeIcon
                  icon="arrows-alt"
                  className="handle move-item-handle"
                  size={
                    video.w * parent.width < 150 && video.h * parent.height < 150
                      ? '1x'
                      : '2x'
                  }
                />
                <img
                  src={getVideoThumbnail(video)}
                  alt=""
                  width="100%"
                  height="100%"
                />
              </div>
            ) : (
              <ReactPlayer
                url={[getWebM(video.filename), getMP4(video.filename)]}
                width={video.w ? '100%' : video.width}
                height={video.h ? '100%' : video.height}
                className="react-player"
                playing
                controls
                onEnded={() => setPlayVideo(false)}
              />
            )}
          </div>
        </DraggableResizable>
      ) : (
        <>
          {video && parent && (
            <div
              style={{
                width: video.w * parent.width,
                height: video.h * parent.height,
                position: 'absolute',
                top: video.y * parent.height,
                left: video.x * parent.width,
              }}
              className="video-container"
            >
              {!playVideo ? (
                <>
                  <Button
                    className="play-icon-button"
                    onClick={() => setPlayVideo(true)}
                  >
                    <FontAwesomeIcon
                      icon="play-circle"
                      className="play-button"
                      size="3x"
                      aria-hidden
                    />
                    <span className="sr-only">Toista video</span>
                  </Button>
                  <img
                    src={getVideoThumbnail(video)}
                    alt=""
                    width={video.width >= video.height ? '100%' : 'auto'}
                    height="100%"
                    draggable="false"
                    className={
                      !video.draggable ? 'thumbnail-video-content' : undefined
                    }
                  />
                  <Button
                    className="remove-image-button"
                    onClick={() => setOpenDeleteContentModal(true)}
                    role="link"
                  >
                    Tyhjennä
                  </Button>
                </>
              ) : (
                <ReactPlayer
                  url={[getWebM(video.filename), getMP4(video.filename)]}
                  controls
                  width={video.w ? '100%' : video.width}
                  height={video.h ? '100%' : video.height}
                  className="react-player"
                  playing
                  onEnded={() => setPlayVideo(false)}
                />
              )}
            </div>
          )}
        </>
      )}
      {openDeleteContentModal && video && (
        <DeleteItemDialogue
          openDeleteContentModal={openDeleteContentModal}
          setOpenDeleteContentModal={setOpenDeleteContentModal}
          deleteItem={removeVideo}
          itemId={video.id}
          textContent={['Haluatko varmasti poistaa videon?', 'Kyllä, poista']}
        />
      )}
    </>
  )
}

Video.propTypes = {
  removeVideo: propTypes.func.isRequired,
  parent: propTypes.instanceOf(Object),
}

Video.defaultProps = {
  parent: null,
}

export default memo(Video)
