import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'localforage'
import { blockReducer } from './blocks/blockReducer'
import { blockImageReducer } from './blockImages/blockImageReducer'
import { bookReducer } from './books/bookReducer'
import { cellReducer } from './cells/cellReducer'
import { pageReducer } from './pages/pageReducer'
import { questionReducer } from './questions/questionReducer'
import { answerReducer } from './answers/answerReducer'
import { rootReducer } from './root/rootReducer'
import { textReducer } from './texts/textReducer'
import { userReducer } from './user/userReducer'
import { imageReducer } from './images/imageReducer'
import { soundReducer } from './sounds/soundReducer'
import { videoReducer } from './videos/videoReducer'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const initialState = {}

const appReducer = combineReducers({
  pages: pageReducer,
  cells: cellReducer,
  user: userReducer,
  books: bookReducer,
  blocks: blockReducer,
  blockImages: blockImageReducer,
  questions: questionReducer,
  answers: answerReducer,
  texts: textReducer,
  root: rootReducer,
  images: imageReducer,
  sounds: soundReducer,
  videos: videoReducer,
})

export const store = configureStore({
  reducer: persistReducer(persistConfig, appReducer),
  initialState,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
})

export const persistor = persistStore(store)
