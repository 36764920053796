import React, { memo } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import Button from '../../../Button'
import ValidatedTextInput from '../../../ValidatedTextInput'
import { updateBook } from '../../../../store/books/bookAction'
import ModalHeader from '../../../ModalHeader'

const EditBookDetails = ({ setModalIsOpen }) => {
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm()

  const onSubmit = async (data) => {
    try {
      let name = data && data.name.trim()
      let description = data && data.description.trim()
      if (name.length < 1) {
        name = book.name
      }
      const updated = { ...book, name, description }
      dispatch(updateBook(updated))
      setModalIsOpen(false)
    } catch (e) {
      throw new Error(e)
    }
  }
  return (
    <div className="book-details-content">
      <ModalHeader
        headerText="Muokkaa kirjan tietoja"
        onClick={() => setModalIsOpen(false)}
      />
      <form id="edit-book-details-form" onSubmit={handleSubmit(onSubmit)}>
        <ValidatedTextInput
          label="Muokkaa kirjan nimeä"
          name="name"
          value={book && book.name}
          onChange={() => clearErrors()}
          labelClassName="input-label-class"
          inputClassName="edit-book-input"
          {...register('name', {
            required: true,
            maxLength: 255,
          })}
        />
        <div className="validation-error-text">
          {errors.name && errors.name.type === 'required' && 'Kirjoita nimi.'}
          {errors.name &&
            errors.name.type === 'maxLength' &&
            'Nimi saa olla enintään 255 merkkiä pitkä.'}
        </div>
        <ValidatedTextInput
          label="Muokkaa kirjan kuvausta"
          name="description"
          value={book && book.description}
          onChange={() => clearErrors()}
          labelClassName="input-label-class"
          inputClassName="edit-book-input"
          {...register('description', {
            required: false,
            maxLength: 255,
          })}
        />
        <div className="validation-error-text">
          {errors.description &&
            errors.description.type === 'maxLength' &&
            'Kuvaus saa olla enintään 255 merkkiä pitkä.'}
        </div>
        <div className="audio-control-buttons">
          <Button className="dialogue-button select-button" type="submit">
            Tallenna
          </Button>
        </div>
      </form>
    </div>
  )
}

EditBookDetails.propTypes = {
  setModalIsOpen: propTypes.func.isRequired,
}

export default memo(EditBookDetails)
