import React, { memo } from 'react'
import propTypes from 'prop-types'
import './styles.scss'

const TextInput = (props) => {
  const {
    label,
    showLabel,
    name,
    placeHolder,
    onChange,
    labelClassName,
    inputClassName,
  } = props

  return (
    <div className="text-input-container">
      {showLabel && (
        <label className={labelClassName} htmlFor={name}>
          {label}
        </label>
      )}
      <input
        type="text"
        name={name}
        id={name}
        placeholder={placeHolder}
        className={inputClassName}
        onChange={onChange}
        aria-label={label}
      />
    </div>
  )
}

TextInput.propTypes = {
  label: propTypes.string.isRequired,
  showLabel: propTypes.bool,
  name: propTypes.string.isRequired,
  placeHolder: propTypes.string,
  onChange: propTypes.func.isRequired,
  labelClassName: propTypes.string,
  inputClassName: propTypes.string,
}

TextInput.defaultProps = {
  placeHolder: null,
  showLabel: true,
  labelClassName: undefined,
  inputClassName: undefined,
}

export default memo(TextInput)
