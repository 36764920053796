import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import AddImagePreview from '../AddImagePreview'
import Button from '../../Button'
import PaginationContainer from '../../Pagination'
import { Container, Row, Col } from 'react-bootstrap'
import './styles.scss'

const SearchResults = ({ results, closeModal, chooseImage }) => {
  const [openCardModal, setOpenCardModal] = useState(false)
  const [chosenImage, setChosenImage] = useState(null)
  const [currentPaginationPage, setCurrentPaginationPage] = useState(1)
  const [paginationPages, setPaginationPages] = useState([])
  const [resultsToShow, setResultsToShow] = useState(null)

  useEffect(() => {
    if (results && results.images && results.images.length > 0) {
      const length = Math.ceil(results.images.length / 40)
      let pages = [1]
      for (let i = 2; i <= length; i++) {
        pages.push(i)
      }
      setPaginationPages(pages)
      setCurrentPaginationPage(1)
      getResultsToShow()
    }
  }, [results])

  useEffect(() => {
    getResultsToShow()
  }, [currentPaginationPage])

  const getResultsToShow = () => {
    if (results.images.length <= 40) {
      setResultsToShow(results.images)
    } else if (results.images.length > 40) {
      const startIndex = currentPaginationPage * 40 - 40
      const endIndex = currentPaginationPage * 40
      setResultsToShow(results.images.slice(startIndex, endIndex))
    }
  }

  const getPagesToDisplay = () => {
    // shows all pages in pagination if there are less than 12, but if there are more,
    // it filters out the pages that should not be shown but ellipsis shown instead.
    if (paginationPages.length < 12) {
      return paginationPages
    } else if (currentPaginationPage < 8) {
      // add ellipsis to end
      return paginationPages.filter((p) => p < 11 || p > paginationPages.length - 2)
    } else if (currentPaginationPage > paginationPages.length - 6) {
      // add ellipsis to beginning
      return paginationPages.filter(
        (p) => p > paginationPages.length - 11 || p === 1
      )
    } else if (
      currentPaginationPage > 5 &&
      currentPaginationPage <= paginationPages.length - 6
    ) {
      // add ellipsis to both sides
      return paginationPages.filter(
        (p) =>
          p === 1 ||
          p > paginationPages.length - 2 ||
          (p > currentPaginationPage - 5 && p < currentPaginationPage + 5)
      )
    }
  }

  const previewImage = (result) => {
    setOpenCardModal(true)
    setChosenImage(result)
  }
  return (
    <>
      {results && results.images && (
        <Container>
          <PaginationContainer
            results={results.images}
            currentPaginationPage={currentPaginationPage}
            setCurrentPaginationPage={setCurrentPaginationPage}
            pagesToDisplay={getPagesToDisplay()}
            paginationPages={paginationPages}
          />
          <Row xs={2} md={4} lg={5}>
            {resultsToShow &&
              resultsToShow.map((result) => {
                return (
                  <Col
                    key={`${result.uid}-${result.type}`}
                    className="search-result-container"
                  >
                    <Button
                      className="choose-image-button"
                      label={`Valitse ${result.name}.`}
                      onClick={() => previewImage(result)}
                    >
                      <img src={result.thumb} alt="" width="auto" height="auto" />
                      <div className="search-result-text">{result.name}</div>
                    </Button>
                  </Col>
                )
              })}
          </Row>
          <PaginationContainer
            results={results.images}
            currentPaginationPage={currentPaginationPage}
            setCurrentPaginationPage={setCurrentPaginationPage}
            pagesToDisplay={getPagesToDisplay()}
            paginationPages={paginationPages}
          />
          {openCardModal && (
            <AddImagePreview
              openCardModal={openCardModal}
              setOpenCardModal={setOpenCardModal}
              closeModal={closeModal}
              result={chosenImage}
              chooseImage={chooseImage}
            />
          )}
        </Container>
      )}
    </>
  )
}

SearchResults.propTypes = {
  results: propTypes.instanceOf(Object).isRequired,
  chooseImage: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired,
}

export default memo(SearchResults)
