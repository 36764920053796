import {
  loginUser as apiLoginUser,
  deleteUser,
  getUser,
  logoutUser as apiLogoutUser,
} from '../../api/users/users'
import { resetStore, rootStartUp } from '../root/rootAction'

export const initializeAnonymousUser = () => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_USER_TO_STATE',
      data: {
        id: null,
        anonymous: true,
      },
    })
  }
}

export const loginUser = (formData) => {
  return (dispatch) => {
    apiLoginUser(formData).then((res) => {
      if (res && !res.error) {
        dispatch({
          type: 'ADD_USER_TO_STATE',
          data: {
            id: res.id,
            error: false,
            email: res.email,
          },
        })
        dispatch(rootStartUp())
      } else {
        dispatch({
          type: 'USER_LOGIN_FAILED',
          data: res.message,
        })
      }
    })
  }
}

export const logoutUser = () => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state && state.user && !state.user.anonymous) {
      await apiLogoutUser()
    }
    dispatch({
      type: 'REMOVE_USER_FROM_STATE',
      data: null,
    })
    dispatch(resetStore())
  }
}

export const deleteUserAccount = (id) => {
  return async (dispatch) => {
    await deleteUser(id)
    dispatch({
      type: 'REMOVE_USER_FROM_STATE',
      data: null,
    })
    dispatch(resetStore())
  }
}

export const initializeUser = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const userInState = state.user
    const books = state.books
    if (!userInState || (userInState && !books)) {
      try {
        const user = await getUser()
        if (user) {
          dispatch({
            type: 'GET_USER',
            data: user,
          })
        } else {
          dispatch({
            type: 'REMOVE_USER_FROM_STATE',
            data: null,
          })
        }
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}

export const updateUserInState = () => {
  return async (dispatch) => {
    try {
      const user = await getUser()
      dispatch({
        type: 'UPDATE_USER',
        data: {
          ...user,
        },
      })
    } catch (e) {
      await dispatch({
        type: 'REMOVE_USER_FROM_STATE',
        data: null,
      })
    }
  }
}
