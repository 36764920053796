import axios from 'axios'
const baseUrl = '/api/block-images'

const addBlockImage = (image) => {
  return axios.post(baseUrl, image)
}

const copyBlockImage = (imageId) => {
  return axios.post(`${baseUrl}/${imageId}/copy`)
}

const deleteBlockImage = (imageId) => {
  return axios.delete(`${baseUrl}/${imageId}`)
}

const getBlockImages = () => {
  return axios.get(baseUrl)
}

const updateBlockImage = async (updatedImage) => {
  const response = await axios.put(`${baseUrl}/${updatedImage.id}`, updatedImage)
  return response.data
}

const blockImageMoveLeft = async (imageId) => {
  const response = await axios.put(`${baseUrl}/${imageId}/left`)
  return response.data
}

const blockImageMoveRight = async (imageId) => {
  const response = await axios.put(`${baseUrl}/${imageId}/right`)
  return response.data
}

export {
  addBlockImage,
  copyBlockImage,
  deleteBlockImage,
  getBlockImages,
  updateBlockImage,
  blockImageMoveLeft,
  blockImageMoveRight,
}
