import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Button'
import ValidatedTextInput from '../../ValidatedTextInput'
import { useForm } from 'react-hook-form'
import { registerNewUser } from '../../../api/users/users'

const RegistrationModal = ({ setOpenRegistrationModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm()
  const navigate = useNavigate()
  const user = useSelector((state) => state.user)
  const [response, setResponse] = useState(null)

  useEffect(() => {
    if (user && !user.error) {
      navigate('/')
      setOpenRegistrationModal(false)
    } else if (user && user.error) {
      setOpenRegistrationModal(true)
    }
  }, [user])

  const onSubmit = async (data) => {
    setResponse(null)
    const email = data.email.trim().toLowerCase()
    const res = await registerNewUser(email)
    setResponse(res)
  }

  const closeModal = () => {
    setOpenRegistrationModal(false)
    setResponse(null)
  }

  return (
    <div className="registration-form-container">
      <div className="login-form-content">
        <h1 className="login-page-header">Tilaa käyttäjätunnus</h1>
        <p>
          Näillä tunnuksilla voit käyttää sekä Papuria, että{' '}
          <a href="https://kuvatyokalu.papunet.net">Papunetin kuvatyökalua.</a>
        </p>
        <p>
          Anna sähköpostiosoite johon haluat tunnukset. Tunnuksia voi olla vain yksi
          per sähköpostiosoite. Jos olet unohtanut aikaisemman salasanasi, voit uusia
          sen klikkaamalla linkkiä kirjautumislomakkeen vierestä.
        </p>
        <p>
          Jos et saa aktivointilinkkiä sähköpostiisi, tarkista roskapostikansiosi.
        </p>
        <form className="flex-form" onSubmit={handleSubmit(onSubmit)}>
          <ValidatedTextInput
            label="Sähköpostiosoite"
            name="email"
            placeHolder="Kirjoita sähköpostiosoite..."
            onChange={() => clearErrors()}
            labelClassName="hidden-label"
            inputClassName="text-input-field"
            {...register('email', { required: true })}
          />
          <div className="validation-error-text">
            {errors.email &&
              errors.email.type === 'required' &&
              'Kirjoita sähköpostiosoite.'}
          </div>
          <p
            className={
              response && response.error
                ? 'backend-response-text red-text'
                : 'backend-response-text success-text'
            }
          >
            {response && response.message}
          </p>
          <br />
          <div className="modal-button-container">
            <Button type="submit" className="dialogue-button select-button">
              <div className="icon-button-content">
                <FontAwesomeIcon icon="sign-out-alt" className="info-icon" />
                Tilaa
              </div>
            </Button>
            <Button className="dialogue-button gray-button" onClick={closeModal}>
              Sulje
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
RegistrationModal.propTypes = {
  setOpenRegistrationModal: propTypes.func.isRequired,
}

export default memo(RegistrationModal)
