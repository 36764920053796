import { getAllUserBooks } from '../../api/users/users'
import {
  copyBook,
  createBook,
  deleteBook,
  getBookById,
  getOpikeBookByUrl,
  updateBook as apiUpdateBook,
} from '../../api/books/books'
import { schema, normalize } from 'normalizr'
import { initStore, rootLoadingReady, startRootLoading } from '../root/rootAction'
import { updateImageStore } from '../images/imageAction'
import { updatePages } from '../pages/pageAction'
import { updateBlockStore } from '../blocks/blockAction'
import { updateBlockImageStore } from '../blockImages/blockImageAction'
import { updateQuestionStore } from '../questions/questionAction'
import { updateAnswerStore } from '../answers/answerAction'
import { updateTextStore } from '../texts/textAction'
import { updateSoundStore } from '../sounds/soundAction'
import { updateVideoStore } from '../videos/videoAction'
import { updateCellStore } from '../cells/cellAction'

export const addMaterial = (id, name, description) => {
  return (dispatch) => {
    createBook(id, name, description).then((res) => {
      if (res && res.status && res.status === 403) {
        dispatch({
          type: 'REMOVE_USER_FROM_STATE',
          data: null,
        })
      } else {
        dispatch({
          type: 'ADD_MATERIAL',
          data: res.data,
        })
      }
    })
  }
}

export const addAnonymousBook = (id) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_MATERIAL',
      data: { id },
    })
  }
}

export const chooseMaterial = (id) => {
  return {
    type: 'CHOOSE_MATERIAL',
    data: id,
  }
}

export const chooseOpikeMaterial = (key) => {
  return async (dispatch) => {
    getOpikeBookByUrl(key).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: 'CHOOSE_OPIKE_MATERIAL',
          data: res.data,
        })
      }
    })
  }
}

export const copyAndAddBookToState = (id, data) => {
  return async (dispatch) => {
    copyBook(id, data.name, data.description).then((res) => {
      if (res && res.status && res.status === 403) {
        dispatch({
          type: 'REMOVE_USER_FROM_STATE',
          data: null,
        })
      }
      dispatch({
        type: 'COPY_BOOK',
        data: res.data,
      })
      dispatch(rootLoadingReady())
    })
  }
}

export const deleteMaterial = (id) => {
  return async (dispatch) => {
    try {
      await deleteBook(id)
      dispatch({
        type: 'DELETE_MATERIAL',
        data: id,
      })
    } catch {
      dispatch({
        type: 'REMOVE_USER_FROM_STATE',
        data: null,
      })
    }
  }
}

export const fetchBookContentsAndAddToStore = (id) => {
  return (dispatch) => {
    try {
      dispatch(startRootLoading())
      getBookById(id).then((res) => {
        if (res) {
          if (res.status && res.status === 403) {
            dispatch({
              type: 'REMOVE_USER_FROM_STATE',
              data: null,
            })
          } else {
            const block = new schema.Entity('blocks')
            const blockImage = new schema.Entity('blockImages')
            const question = new schema.Entity('questions')
            const answer = new schema.Entity('answers')
            const text = new schema.Entity('texts')
            const image = new schema.Entity('images')
            const sound = new schema.Entity('sounds')
            const video = new schema.Entity('videos')
            const cell = new schema.Entity('cells')

            const page = new schema.Entity('pages', {
              blocks: [block],
              blockImages: [blockImage],
              questions: [question],
              answers: [answer],
              texts: [text],
              images: [image],
              sounds: [sound],
              videos: [video],
              cells: [cell],
            })

            const book = new schema.Entity('books', {
              pages: [page],
            })
            const normalizedData = normalize(res.data, book)
            dispatch(initBook(normalizedData))
          }
        }
      })
    } catch {
      dispatch({
        type: 'REMOVE_USER_FROM_STATE',
        data: null,
      })
    }
  }
}

export const fetchBooksAndInitStore = () => {
  return async (dispatch) => {
    try {
      const res = await getAllUserBooks()
      const block = new schema.Entity('blocks')
      const blockImage = new schema.Entity('blockImages')
      const question = new schema.Entity('questions')
      const answer = new schema.Entity('answers')
      const text = new schema.Entity('texts')
      const image = new schema.Entity('images')
      const sound = new schema.Entity('sounds')
      const video = new schema.Entity('videos')
      const cell = new schema.Entity('cells')

      const page = new schema.Entity('pages', {
        blocks: [block],
        blockImages: [blockImage],
        questions: [question],
        answers: [answer],
        texts: [text],
        images: [image],
        sounds: [sound],
        videos: [video],
        cells: [cell],
      })

      const book = new schema.Entity('books', {
        pages: [page],
      })
      const arrayOfBooks = new schema.Array(book)
      const normalizedData = normalize(res, arrayOfBooks)
      dispatch(initStore(normalizedData))
    } catch {
      dispatch({
        type: 'REMOVE_USER_FROM_STATE',
        data: null,
      })
    }
  }
}

export const initBook = (data) => {
  return (dispatch) => {
    dispatch(updateBookState(Object.values(data.entities.books)[0]))
    if (data.entities.pages && Object.values(data.entities.pages).length > 0) {
      const sortedPages = Object.values(data.entities.pages).sort(
        (p1, p2) => p1.order - p2.order
      )
      const currentPage = sortedPages[0]
      dispatch(
        updatePages({ pages: data.entities.pages, currentPage: currentPage.id })
      )
      dispatch(updateBlockStore(data.entities.blocks))
      dispatch(updateBlockImageStore(data.entities.blockImages))
      dispatch(updateQuestionStore(data.entities.questions))
      dispatch(updateAnswerStore(data.entities.answers))
      dispatch(updateTextStore(data.entities.texts))
      dispatch(updateImageStore(data.entities.images))
      dispatch(updateSoundStore(data.entities.sounds))
      dispatch(updateVideoStore(data.entities.videos))
      dispatch(updateCellStore(data.entities.cells))
    }
    dispatch(rootLoadingReady())
  }
}

export const initMaterials = (data) => {
  return async (dispatch) => {
    await dispatch({
      type: 'INIT_MATERIAL',
      data,
    })
  }
}

export const sortBooksAlphabetically = (data) => {
  return {
    type: 'SORT_BOOKS',
    data,
  }
}

export const sortBooksInAscendingOrder = (data) => {
  return {
    type: 'SORT_ASCENDING',
    data,
  }
}

export const updateBookState = (updated) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_BOOK',
      data: updated,
    })
  }
}

export const updateBook = (updated) => {
  return async (dispatch) => {
    try {
      await apiUpdateBook(updated)
      dispatch({
        type: 'UPDATE_BOOK',
        data: updated,
      })
    } catch {
      dispatch({
        type: 'REMOVE_USER_FROM_STATE',
        data: null,
      })
    }
  }
}
