import { convertToObject } from '../../utils'
export const videoReducer = (state = {}, action) => {
  switch (action.type) {
    case 'INIT_VIDEOS':
      if (!action.data) {
        return {}
      } else {
        return action.data
      }
    case 'ADD_VIDEO': {
      const id = action.data.id
      return {
        ...state,
        [id]: action.data,
      }
    }
    case 'UPDATE_VIDEO': {
      const key = action.data.id
      return {
        ...state,
        [key]: action.data,
      }
    }
    case 'UPDATE_VIDEOS': {
      if (!action.data) {
        return state
      } else {
        const videos = { ...state }
        const copyWithAddedVideos = Object.values(videos).concat(
          Object.values(action.data)
        )
        return convertToObject(copyWithAddedVideos)
      }
    }
    case 'DELETE_VIDEO': {
      const videos = { ...state }
      const filtered = Object.values(videos).filter((p) => p.id !== action.data)
      return convertToObject(filtered)
    }
    case 'DELETE_PAGE': {
      const copy2 = Object.values(state).filter(
        (image) => image.pageId !== action.data
      )
      return convertToObject(copy2)
    }
    case 'PURGE':
      return {}
    default:
      return state
  }
}
