import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  questionScaleDown,
  questionScaleUp,
  deleteQuestion,
  updateQuestion,
} from '../../../store/questions/questionAction'
import propTypes from 'prop-types'
import Draggable from 'react-draggable'
import TextEditor from '../TextEditor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteItemDialogue from '../../DeleteItemDialogue'
import Button from '../../Button'
import '../styles.scss'
import { addAnswer } from '../../../store/answers/answerAction'
import { pageTypeIsFree } from '../../../utils'
import { updateBook } from '../../../store/books/bookAction'
import AnswerTextEditor from '../AnswerTextEditor'

const DraggableQuestion = ({
  questionId,
  parent,
  editorContent,
  setEditorContent,
  setDirty,
  saveQuestion,
  pageType,
}) => {
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  // const [openDeleteSingleImageModal, setOpenDeleteSingleImageModal] = useState(false)
  // const [imageBlockIdToDelete, setImageBlockIdToDelete] = useState(undefined)

  const [position, setPosition] = useState(null)
  const [dragging, setDragging] = useState(false)
  const [width, setWidth] = useState(null)
  const textAreaRef = React.useRef()

  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const question = useSelector((state) => state.questions[questionId])
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])

  const currentPageAnswerIds = currentPage.answers
  const answersData = useSelector((state) => state.answers)
  const [currentAnswers, setCurrentAnswers] = useState(undefined)

  const getPosition = () => {
    setPosition({
      x: question.x * parent.width,
      y: question.y * parent.height,
    })
  }

  useEffect(() => {
    if (question && parent) {
      getPosition()
    }
  }, [question.x, question.y, parent])

  useEffect(() => {
    if (!width || width !== question.w * parent.width) {
      setWidth(question.w * parent.width)
    }
  }, [question.w, parent.width])

  useEffect(() => {
    const currentPageAnswers = currentPageAnswerIds.map((id) => answersData[id])
    setCurrentAnswers(
      currentPageAnswers
        .filter((el) => el?.questionId === questionId)
        .sort((a, b) => a.order - b.order)
    )
  }, [currentPageAnswerIds, answersData])

  const addNewAnswer = () => {
    const newAnswer = {
      questionId: questionId,
      pageId: currentPageId,
      userId: user.id,
    }
    dispatch(addAnswer(newAnswer))
    if (currentPage && pageTypeIsFree(currentPage.type)) {
      const updatedBook = {
        ...book,
        updated: Date.now(),
      }
      dispatch(updateBook(updatedBook))
    }
  }

  const nodeRef = React.useRef(null)

  const handleStop = (e, pos) => {
    updatePosition(pos.x, pos.y)
    setDragging(false)
  }

  const updatePosition = (x, y) => {
    const relativePositionX = x / parent.width
    const relativePositionY = y / parent.height
    const updated = {
      ...question,
      bgColor: question.bgColor,
      x: relativePositionX,
      y: relativePositionY,
    }
    dispatch(updateQuestion(updated))
  }

  const deleteThisQuestion = (id) => {
    dispatch(deleteQuestion(id))
  }

  const onControlledDrag = (e, pos) => {
    setPosition(pos)
  }

  const scaleThisQuestionUp = (id) => {
    dispatch(questionScaleUp(id))
  }

  const scaleThisQuestionDown = (id) => {
    dispatch(questionScaleDown(id))
  }

  const scaleToPercent = (scale) => {
    return `${parseInt(scale * 100)}%`
  }

  return (
    <>
      {question && parent && position && (
        <Draggable
          position={position}
          onDrag={onControlledDrag}
          onStart={() => setDragging(true)}
          onStop={handleStop}
          scale={1}
          grid={[0.1, 0.1]}
          handle=".move-item-handle"
          cancel=".tox .mce-content-body"
          bounds=".create-page-content"
          nodeRef={nodeRef}
        >
          <div
            className="draggable-container question-draggable"
            ref={nodeRef}
            style={{
              position: 'absolute',
              zIndex: 100,
            }}
            tabIndex={0}
          >
            <div
              ref={textAreaRef}
              className="question-wrapper"
              style={{
                backgroundColor: question.bgColor,
              }}
            >
              <FontAwesomeIcon
                icon="arrows-alt"
                className="move-item-handle"
                size={
                  question.w * parent.width < 150 && question.h * parent.height < 150
                    ? '1x'
                    : '2x'
                }
              />
              <TextEditor
                question={question}
                parent={parent}
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                setDirty={setDirty}
                saveQuestion={saveQuestion}
                dragging={dragging}
                width={width}
                pageType={pageType}
              />
              {currentAnswers?.map((el) => (
                <AnswerTextEditor
                  key={el.id}
                  answer={el}
                  question={question}
                  parent={parent}
                  dragging={dragging}
                  width={width}
                  pageType={pageType}
                />
              ))}
              <Button className="add-answer-button" onClick={() => addNewAnswer()}>
                <FontAwesomeIcon icon={['far', 'plus-square']} aria-hidden />
                <span style={{ marginLeft: '10px' }}>Lisää vastausvaihtoehto</span>
              </Button>
              <div className="question-tools">
                <Button
                  onClick={() => scaleThisQuestionUp(question.id)}
                  label="Suurenna kyselyä."
                  className={question.scale >= 2 ? 'disabled' : ''}
                  disabled={question.scale >= 2 ? true : false}
                >
                  <FontAwesomeIcon icon="plus" className="cancel-icon" aria-hidden />
                </Button>
                <span className="scale-display">
                  {scaleToPercent(question.scale)}
                </span>
                <Button
                  onClick={() => scaleThisQuestionDown(question.id)}
                  label="Pienennä kyselyä."
                  className={question.scale <= 0.6 ? 'disabled' : ''}
                  disabled={question.scale <= 0.6 ? true : false}
                >
                  <FontAwesomeIcon
                    icon="minus"
                    className="cancel-icon"
                    aria-hidden
                  />
                </Button>
                <Button
                  onClick={() => setOpenDeleteContentModal(true)}
                  label="Poista kysely."
                  className=""
                >
                  <FontAwesomeIcon
                    icon="times"
                    className="cancel-icon"
                    aria-hidden
                  />
                </Button>
              </div>
            </div>
          </div>
        </Draggable>
      )}
      {openDeleteContentModal && question && (
        <DeleteItemDialogue
          openDeleteContentModal={openDeleteContentModal}
          setOpenDeleteContentModal={setOpenDeleteContentModal}
          deleteItem={deleteThisQuestion}
          itemId={question.id}
          textContent={['Haluatko varmasti poistaa kyselyn?', 'Kyllä, poista']}
        />
      )}
    </>
  )
}

DraggableQuestion.propTypes = {
  questionId: propTypes.number.isRequired,
  parent: propTypes.instanceOf(Object).isRequired,
  editorContent: propTypes.string,
  setEditorContent: propTypes.func.isRequired,
  setDirty: propTypes.func.isRequired,
  saveQuestion: propTypes.func.isRequired,
  pageType: propTypes.number,
}

export default memo(DraggableQuestion)
