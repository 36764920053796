import React, { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import NavigationLink from './NavigationLink'
import { Navbar, Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Button'
import LogOutModal from '../../User/LogOutModal'
import ModalContainer from '../../ModalContainer'
import './styles.scss'

const MainNavigation = () => {
  const [activeLink, setActiveLink] = useState(null)
  const [openLogoutModal, setOpenLogoutModal] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location && location.pathname) {
      const path = location.pathname
      switch (path) {
        case '/omat-kirjat':
          setActiveLink('1')
          break
        case '/':
          setActiveLink('1')
          break
        case '/muokattavat':
          setActiveLink('2')
          break
        case '/palaute':
          setActiveLink('3')
          break
        case '/omat-tiedot':
          setActiveLink('4')
          break
        default:
          setActiveLink(null)
          break
      }
    }
  }, [location, openLogoutModal])

  return (
    <div className="main-nav-container navbar navbar-expand-md">
      {openLogoutModal && (
        <ModalContainer
          modalIsOpen={openLogoutModal}
          closeModal={() => setOpenLogoutModal(false)}
          info
          label="Uloskirjautuminen"
        >
          <LogOutModal
            openModal={openLogoutModal}
            setOpenModal={setOpenLogoutModal}
          />
        </ModalContainer>
      )}
      <Navbar collapseOnSelect bg="light" expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="mr-auto"
            activeKey={activeLink}
            onSelect={(selected) => setActiveLink(selected)}
            fill
          >
            <div className="nav-item-list">
              <NavigationLink
                eventKey="1"
                icon="images"
                text="Omat kirjat"
                linkTo="/omat-kirjat"
                activeLink={activeLink}
              />
              <NavigationLink
                eventKey="2"
                icon="copy"
                text="Muokattavat kirjat"
                linkTo="/muokattavat"
                activeLink={activeLink}
              />
            </div>
            <div className="justify-content-end nav-item-list">
              <NavigationLink
                eventKey="3"
                icon="life-ring"
                text="Palaute"
                linkTo="/palaute"
                activeLink={activeLink}
              />
              <NavigationLink
                eventKey="4"
                icon="user"
                text="Omat tiedot"
                linkTo="/omat-tiedot"
                activeLink={activeLink}
              />
              <div className="nav-link-container">
                <Button
                  className="logout-button logout-link"
                  onClick={() => setOpenLogoutModal(true)}
                  role="link"
                >
                  <FontAwesomeIcon
                    icon="sign-out-alt"
                    className="nav-link-icon"
                    size="2x"
                  />
                  Kirjaudu ulos
                </Button>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default memo(MainNavigation)
