import { convertToObject } from '../../utils'
const initialState = {}
export const answerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_ANSWERS':
      if (!action.data) {
        return {}
      } else {
        return action.data
      }
    case 'ADD_ANSWER': {
      const id = action.data.id
      return {
        ...state,
        [id]: action.data,
      }
    }
    case 'UPDATE_ANSWER': {
      const key = action.data.id
      return {
        ...state,
        [key]: action.data,
      }
    }
    case 'UPDATE_ANSWERS': {
      const answers = { ...state }
      if (answers && action.data) {
        const copyWithAddedTexts = Object.values(answers).concat(
          Object.values(action.data)
        )
        return convertToObject(copyWithAddedTexts)
      }
      return state
    }
    case 'DELETE_ANSWER': {
      const answers = { ...state }
      const filtered = Object.values(answers).filter((p) => p.id !== action.data)
      return convertToObject(filtered)
    }
    case 'DELETE_PAGE': {
      const copy2 = Object.values(state).filter(
        (answer) => answer.pageId !== action.data
      )
      return convertToObject(copy2)
    }
    case 'PURGE':
      return initialState
    default:
      return state
  }
}
