import {
  createBlock,
  updateBlock as apiUpdateBlock,
  deleteBlock as apiDeleteBlock,
  blockScaleUp as apiBlockScaleUp,
  blockScaleDown as apiBlockScaleDown,
} from '../../api/books/blocks'

export const addBlock = (page) => {
  return async (dispatch) => {
    const res = await createBlock(page)
    dispatch({
      type: 'ADD_BLOCK',
      data: res.data,
    })
  }
}

export const addBlockToState = (block) => {
  return async (dispatch) => {
    dispatch({
      type: 'ADD_BLOCK',
      data: block,
    })
  }
}

export const deleteBlock = (id) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      await apiDeleteBlock(id)
    }
    dispatch({
      type: 'DELETE_BLOCK',
      data: id,
    })
  }
}

export const initializeBlocks = (blocks) => {
  return async (dispatch) => {
    dispatch({
      type: 'INIT_BLOCKS',
      data: blocks,
    })
  }
}

export const updateBlock = (updated) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiUpdateBlock(updated)
      dispatch({
        type: 'UPDATE_BLOCK',
        data: res,
      })
    } else {
      dispatch({
        type: 'UPDATE_BLOCK',
        data: updated,
      })
    }
  }
}

export const updateBlockStore = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_BLOCKS',
      data,
    })
  }
}

export const blockScaleUp = (blockId) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiBlockScaleUp(blockId)
      dispatch({
        type: 'BLOCK_SCALE_UP',
        data: res,
      })
    }
  }
}

export const blockScaleDown = (blockId) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiBlockScaleDown(blockId)
      dispatch({
        type: 'BLOCK_SCALE_DOWN',
        data: res,
      })
    }
  }
}
