import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'

const TopNavigation = () => {
  return (
    <div className="top-nav-container">
      <div className="header-content">
        <a className="papunet-logo" href="https://papunet.net/">
          Papunet
        </a>
        {'       '}
        <Link className="papuri-logo" to="/">
          Papuri-kirjatyökalu
        </Link>
      </div>
      <a
        className="info-text"
        href="https://papunet.net/kuvatyokalut/papuri-verkkokirjatyokalu/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="info-link-content">
          <FontAwesomeIcon icon="info-circle" className="info-icon" />
          Tietoa Papurista
        </div>
      </a>
    </div>
  )
}

export default memo(TopNavigation)
