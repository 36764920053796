import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import ActivateAccount from '../components/User/ActivateAccount'
import TopNavigation from '../components/Navigation/TopNavigation'

const ActivateAccountContainer = () => {
  const { userId } = useParams()
  const { activationkey } = useParams()
  return (
    <>
      <TopNavigation />
      <ActivateAccount userId={userId} activationkey={activationkey} />
    </>
  )
}

export default memo(ActivateAccountContainer)
