import axios from 'axios'
const baseUrl = '/api/images'

const addImage = (image) => {
  return axios.post(baseUrl, image)
}

const copyImage = (imageId) => {
  return axios.post(`${baseUrl}/${imageId}/copy`)
}

const deleteImage = (imageId) => {
  return axios.delete(`${baseUrl}/${imageId}`)
}

const getImages = () => {
  return axios.get(baseUrl)
}

const updateImage = async (updatedImage) => {
  const response = await axios.put(`${baseUrl}/${updatedImage.id}`, updatedImage)
  return response.data
}

export { addImage, copyImage, deleteImage, getImages, updateImage }
