import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import Draggable from 'react-draggable'
import { Resizable } from 're-resizable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeleteItemDialogue from '../../DeleteItemDialogue'
import Button from '../../Button'
import TextEditor from '../TextEditor'
import { deleteText, updateText } from '../../../store/texts/textAction'
import '../styles.scss'

const DraggableTextEditor = ({
  boxId,
  parent,
  editorContent,
  setEditorContent,
  setDirty,
  getFontSize,
  saveText,
}) => {
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  const [position, setPosition] = useState(null)
  const [dragging, setDragging] = useState(false)
  const [width, setWidth] = useState(null)
  const textAreaRef = React.useRef()

  const dispatch = useDispatch()
  const textbox = useSelector((state) => state.texts[boxId])
  const getPosition = () => {
    setPosition({
      x: textbox.x * parent.width,
      y: textbox.y * parent.height,
    })
  }

  useEffect(() => {
    if (textbox && parent) {
      getPosition()
    }
  }, [textbox.x, textbox.y, parent])

  useEffect(() => {
    if (!width || width !== textbox.w * parent.width) {
      setWidth(textbox.w * parent.width)
    }
  }, [textbox.w, parent.width])

  const handleResize = () => {
    const rect = nodeRef.current.getBoundingClientRect()
    const updated = {
      ...textbox,
      bgColor: textbox.bgColor,
      w: rect.width / parent.width,
      h: rect.height / parent.height,
    }
    dispatch(updateText(updated))
  }

  const getWidth = () => {
    return textbox.w * parent.width
  }

  const getHeight = () => {
    return textbox.h * parent.height
  }

  const nodeRef = React.useRef(null)

  const handleStop = (e, pos) => {
    updatePosition(pos.x, pos.y)
    setDragging(false)
  }
  const updatePosition = (x, y) => {
    const relativePositionX = x / parent.width
    const relativePositionY = y / parent.height
    const updated = {
      ...textbox,
      bgColor: textbox.bgColor,
      x: relativePositionX,
      y: relativePositionY,
    }
    dispatch(updateText(updated))
  }

  const deleteThisTextArea = (id) => {
    dispatch(deleteText(id))
  }

  const onControlledDrag = (e, pos) => {
    setPosition(pos)
  }

  return (
    <>
      {textbox && parent && position && (
        <Draggable
          position={position}
          onDrag={onControlledDrag}
          onStart={() => setDragging(true)}
          onStop={handleStop}
          scale={1}
          grid={[0.1, 0.1]}
          handle=".move-item-handle"
          cancel=".tox .mce-content-body"
          bounds=".create-page-content"
          nodeRef={nodeRef}
        >
          <div
            className="draggable-container"
            ref={nodeRef}
            style={{
              position: 'absolute',
              zIndex: 100,
            }}
            tabIndex={0}
          >
            <Resizable
              maxHeight={1500}
              maxWidth={1500}
              size={{
                width: getWidth(),
                height: getHeight(),
              }}
              className="resizable-container"
              onResizeStop={handleResize}
              grid={[0.1, 0.1]}
              handleComponent={{
                bottomRight: (
                  <FontAwesomeIcon
                    icon="expand-alt"
                    className="resizable-handle"
                    size={
                      textbox.w * parent.width < 150 &&
                      textbox.h * parent.height < 150
                        ? '1x'
                        : '2x'
                    }
                  />
                ),
              }}
              enable={{
                top: false,
                right: false,
                bottom: false,
                left: false,
                topRight: false,
                bottomRight: true,
                bottomLeft: false,
                topLeft: false,
              }}
            >
              <div ref={textAreaRef} className="textbox-wrapper">
                <TextEditor
                  textbox={textbox}
                  parent={parent}
                  editorContent={editorContent}
                  setEditorContent={setEditorContent}
                  setDirty={setDirty}
                  getFontSize={getFontSize}
                  saveText={saveText}
                  dragging={dragging}
                  width={width}
                />
              </div>

              <Button
                className="delete-item-button"
                onClick={() => setOpenDeleteContentModal(true)}
                label="Poista tekstialue."
              >
                <FontAwesomeIcon icon="times" className="cancel-icon" aria-hidden />
              </Button>
              <FontAwesomeIcon
                icon="arrows-alt"
                className="move-item-handle"
                size={
                  // shrink handle as box gets smaller
                  textbox.w * parent.width < 150 && textbox.h * parent.height < 150
                    ? '1x'
                    : '2x'
                }
              />
            </Resizable>
          </div>
        </Draggable>
      )}
      {openDeleteContentModal && textbox && (
        <DeleteItemDialogue
          openDeleteContentModal={openDeleteContentModal}
          setOpenDeleteContentModal={setOpenDeleteContentModal}
          deleteItem={deleteThisTextArea}
          itemId={textbox.id}
          textContent={['Haluatko varmasti poistaa tekstialueen?', 'Kyllä, poista']}
        />
      )}
    </>
  )
}

DraggableTextEditor.propTypes = {
  boxId: propTypes.number.isRequired,
  parent: propTypes.instanceOf(Object).isRequired,
  editorContent: propTypes.string,
  setEditorContent: propTypes.func.isRequired,
  setDirty: propTypes.func.isRequired,
  getFontSize: propTypes.func.isRequired,
  saveText: propTypes.func.isRequired,
}

DraggableTextEditor.defaultProps = {
  editorContent: null,
}

export default memo(DraggableTextEditor)
