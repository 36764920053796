import axios from 'axios'
const baseUrl = '/api/pages'

const getPageById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`)
  return response.data
}

const updatePage = async (updatedPage) => {
  const response = await axios.put(`${baseUrl}/${updatedPage.id}`, updatedPage)
  return response.data
}

const deletePage = async (pageId) => {
  return await axios.delete(`${baseUrl}/${pageId}`)
}

const createPage = async (bookId, order, type, bgColor) => {
  const newPage = {
    bookId,
    order,
    type,
    bgColor,
  }
  return await axios.post(`${baseUrl}`, newPage)
}

export { getPageById, createPage, updatePage, deletePage }
