import axios from 'axios'
const baseUrl = '/api'

let config = {}

async function generateETag(data) {
  const encoder = new TextEncoder()
  const dataEncoded = encoder.encode(data)
  const hashBuffer = await crypto.subtle.digest('SHA-256', dataEncoded)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

// Generoi ETag ja päivitä config-muuttuja
const dataForETag = 'wnTnFlG+YxQm3vDxpGE57Pj-v2.0.2'
generateETag(dataForETag).then((etag) => {
  config = {
    headers: {
      ETag: etag,
    },
  }
})

const activateNewUser = async (id, data) => {
  try {
    const res = await axios.put(`${baseUrl}/users/${id}/activate`, data)
    return { error: false, message: res.data.success }
  } catch (e) {
    return { error: true, message: e.response.data.message }
  }
}

const registerNewUser = async (email) => {
  try {
    const data = { email: email }
    const res = await axios.post(`${baseUrl}/users/create`, data)
    return { error: false, message: res.data.success }
  } catch (e) {
    return { error: true, message: e.response.data.message }
  }
}

const loginUser = async (formData) => {
  try {
    const res = await axios.post(`${baseUrl}/user/login`, formData, config)
    return res.data
  } catch (e) {
    return { error: true, message: e.response.data.message }
  }
}

const logoutUser = async () => {
  const response = await axios.get(`${baseUrl}/user/logout`)
  return response
}

const getAllUserBooks = async () => {
  try {
    const response = await axios.get(`${baseUrl}/books`)
    return response.data
  } catch {
    logoutUser()
    return {}
  }
}

const getNewPassword = async (data) => {
  try {
    const newpassword = { newpassword: data }
    const res = await axios.post(`${baseUrl}/users/create`, newpassword)
    return { error: false, message: res.data.success }
  } catch (e) {
    return { error: true, message: e.response.data.message }
  }
}

const getUser = async () => {
  try {
    const response = await axios.get(`${baseUrl}/user`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const sendFeedback = async (data) => {
  await axios.post(`${baseUrl}/users/feedback`, data)
}

const deleteUser = async (id) => {
  try {
    return await axios.delete(`${baseUrl}/users/${id}`)
  } catch (e) {
    return { error: true, message: e.response.data.message }
  }
}

const deleteUserMaterialsFromKuvatyokalu = async (id) => {
  try {
    const res = await axios.get(`${baseUrl}/users/kuvatyokalu/${id}`)
    return res
  } catch (e) {
    return { error: true, message: e.response.data.message }
  }
}

const updateUser = async (id, updatedData) => {
  try {
    const res = await axios.put(`${baseUrl}/users/${id}`, updatedData)
    return { error: false, message: res.data.success }
  } catch (e) {
    return { error: true, message: e.response.data.message }
  }
}

export {
  activateNewUser,
  getAllUserBooks,
  getNewPassword,
  getUser,
  loginUser,
  logoutUser,
  registerNewUser,
  sendFeedback,
  deleteUser,
  deleteUserMaterialsFromKuvatyokalu,
  updateUser,
}
