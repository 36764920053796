import { useParams } from 'react-router-dom'
import axios from 'axios'

const validateKeyApiRequest = async (key) => {
  try {
    const validate = await axios.get(`/api/books/opike/validate/${key}`)
    if (validate.status === 200 && validate.data === true) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const useOpikeAuth = () => {
  const params = useParams()
  const { key } = params
  if (!key) {
    return false
  } else {
    let response = validateKeyApiRequest(key)
    return response
  }
}
