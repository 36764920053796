import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Button from '../Button'
import ErrorPage from '../ErrorPage'
import ShareButtons from './ShareButtons'
import Modal from 'react-modal'
import ModalHeader from '../ModalHeader'
import { useDispatch, useSelector } from 'react-redux'
import { getBookById } from '../../api/books/books'
import { updateBook } from '../../store/books/bookAction'
import './styles.scss'

const ShareBook = ({ modalIsOpen, closeModal }) => {
  const { trackEvent } = useMatomo()
  const currentBookId = useSelector((state) => state.books.currentBook)
  const bookInState = useSelector((state) => state.books.books[currentBookId])
  const [error, setError] = useState(null)

  const dispatch = useDispatch()
  const [book, setBook] = useState(null)
  const [showShareButtons, setShowShareButtons] = useState(false)
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      position: 'fixed',
      transform: 'translate(-50%, -50%)',
      height: 'fit-content',
      width: '650px',
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'scroll',
    },
  }

  useEffect(() => {
    if (!book && bookInState) {
      setBook(bookInState)
    } else if (!bookInState && currentBookId) {
      getBookById(currentBookId).then((res) => {
        if (res.status === 403 || res.status === 404) {
          setError(res.message)
        } else {
          setBook(res.data)
        }
      })
    }
  }, [currentBookId])

  useEffect(() => {
    if (book && book.status > 0) {
      setShowShareButtons(true)
    }
  }, [book])

  const cancelSharing = (book) => {
    const updated = { ...book, status: 0 }
    dispatch(updateBook(updated))
    closeShareModal()
    setBook(null)
    setShowShareButtons(false)
  }

  const closeShareModal = () => {
    closeModal()
    setBook(null)
    setShowShareButtons(false)
  }

  const startSharing = () => {
    if (book.status !== 98) {
      const updated = { ...book, status: 1 }
      dispatch(updateBook(updated))
    }
    setShowShareButtons(true)
    trackEvent({ category: 'Kirja', action: 'Kirja jaettu' })
  }

  // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root')

  return (
    <>
      {error && <ErrorPage error={error} setError={setError} />}
      {book && modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeShareModal}
          style={customStyles}
          contentLabel="Jaa linkki"
          closeTimeoutMS={300}
        >
          <div>
            <ModalHeader headerText="Jaa kirja" onClick={closeModal} />
            {book.status < 2 ? (
              <p>
                Kirja voidaan jakaa niin, että sitä pääsee katsomaan ilman
                kirjautumista. Katsomista varten luodaan linkki, jonka kautta kirjaa
                pääsevät katsomaan kaikki, joille linkki on annettu. Linkki ei ole
                kenen tahansa helposti arvattavissa.
              </p>
            ) : (
              <p>
                Linkin avulla voit jakaa muokattavan kirjan alkuperäisessä muodossa.
              </p>
            )}
            <p>Voit tallentaa linkin osoitteen joko tekstinä tai QR-koodina.</p>

            {showShareButtons ? (
              <ShareButtons
                book={book}
                cancelSharing={cancelSharing}
                closeShareModal={closeShareModal}
              />
            ) : (
              <Button
                className="dialogue-button select-button"
                onClick={startSharing}
              >
                Luo linkki
              </Button>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

ShareBook.propTypes = {
  modalIsOpen: propTypes.bool.isRequired,
  closeModal: propTypes.func.isRequired,
}

export default memo(ShareBook)
