import axios from 'axios'
const baseUrl = '/api/blocks'

const getBlockById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`)
  return response.data
}

const updateBlock = async (updatedBlock) => {
  const response = await axios.put(`${baseUrl}/${updatedBlock.id}`, updatedBlock)
  return response.data
}

const deleteBlock = (blockId) => {
  return axios.delete(`${baseUrl}/${blockId}`)
}

const createBlock = (blockObject) => {
  return axios.post(baseUrl, blockObject)
}

const blockScaleUp = async (blockId) => {
  const response = await axios.put(`${baseUrl}/${blockId}/scale-up`)
  return response.data
}

const blockScaleDown = async (blockId) => {
  const response = await axios.put(`${baseUrl}/${blockId}/scale-down`)
  return response.data
}

export {
  getBlockById,
  createBlock,
  updateBlock,
  deleteBlock,
  blockScaleUp,
  blockScaleDown,
}
