import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useOpikeAuth } from './hooks/useOpikeAuth'

const PrivateOpikeRoute = ({ children }) => {
  const auth = useOpikeAuth()
  const [authIsValid, setAuthIsValid] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    auth.then((res) => {
      setAuthIsValid(res)
    })
  }, [])

  useEffect(() => {
    if (authIsValid !== null) {
      setLoading(false)
    }
  }, [authIsValid])

  return loading ? (
    <span>Ladataan...</span>
  ) : authIsValid === true ? (
    children
  ) : (
    <Navigate to="/" replace />
  )
}

PrivateOpikeRoute.propTypes = {
  children: propTypes.oneOfType([propTypes.instanceOf(Object), propTypes.func])
    .isRequired,
}

export default PrivateOpikeRoute
