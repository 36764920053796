import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import PropTypes from 'prop-types'
import ValidatedTextInput from '../ValidatedTextInput'
import Button from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { addMaterial } from '../../store/books/bookAction'
import './styles.scss'

const CreateBook = ({ setModalIsOpen }) => {
  const { trackEvent } = useMatomo()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const navigate = useNavigate()

  const onSubmit = (data) => {
    dispatch(addMaterial(user.id, data.bookName, data.bookDescription))
    setModalIsOpen(false)
    navigate('/muokkaa', { state: { showTemplates: true } })
    trackEvent({ category: 'Kirja', action: 'Uusi kirja' })
  }

  const cancelCreatingStory = () => {
    reset()
    setModalIsOpen(false)
  }

  return (
    <div className="two-part-page-container" style={{ minHeight: '100%' }}>
      <div className="two-part-page-content">
        <div className="instructions">
          <h1 className="page-header">Ohje</h1>
          <div className="instructions-sider-text">
            <p>Aloita uusi kirja syöttämällä perustiedot.</p>
            <p>Tämän jälkeen valitset uuden sivupohjan ensimmäisellä sivulle.</p>
            <p>
              Voit aloittaa uuden kirjan myös muokkaamalla valmiita, muokattavia
              kirjoja. Löydät muokattavat kirjat päävalikosta.
            </p>
          </div>
        </div>
        <div className="create-new-book-container">
          <div className="create-book-content">
            <div className="row-space-between-container">
              <h1 className="page-header">Uusi kirja</h1>
              <Button
                className="close-btn"
                onClick={cancelCreatingStory}
                label="Peruuta kirjan luominen"
              >
                <FontAwesomeIcon
                  icon={['far', 'times-circle']}
                  className="template-icon"
                  size="2x"
                />
              </Button>
            </div>
            <p>Anna kirjalle perustiedot</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ValidatedTextInput
                label="Kirjan nimi"
                name="bookName"
                placeHolder="Kirjoita kirjan nimi..."
                onChange={() => clearErrors()}
                labelClassName="input-label-class"
                inputClassName="text-input-class"
                {...register('bookName', { required: true })}
              />
              <div className="validation-error-text">
                {errors.bookName &&
                  errors.bookName.type === 'required' &&
                  'Kirjoita kirjan nimi.'}
              </div>
              <ValidatedTextInput
                label="Kirjan kuvaus"
                name="bookDescription"
                placeHolder="Anna kirjalle kuvaus..."
                onChange={() => clearErrors()}
                labelClassName="input-label-class"
                inputClassName="text-input-class"
                {...register('bookDescription')}
              />
              <p>Kuvaus näytetään omien kirjojen listassa.</p>
              <Button className="select-button dialogue-button" type="submit">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon icon={['far', 'edit']} className="info-icon" />
                  Luo uusi kirja
                </div>
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

CreateBook.propTypes = {
  setModalIsOpen: PropTypes.func.isRequired,
}

export default memo(CreateBook)
