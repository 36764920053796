import React, { memo, useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateBook } from '../../store/books/bookAction'
import { updateCell } from '../../store/cells/cellAction'
import { addImage, deleteImage } from '../../store/images/imageAction'
import {
  addBlockImage,
  // deleteBlockImage,
} from '../../store/blockImages/blockImageAction'
import { copyImage, updateImage } from '../../api/books/images'
// TODO import { copyBlockImage, updateBlockImage } from '../../api/books/blockImages'
import ModalHeader from '../ModalHeader'
import Recent from './Recent'
import SearchImageBank from './SearchImageBank'
import SearchByCategory from './SearchByCategory'
import UploadFile from '../UploadFile'
import {
  getGridImageSizeAndPosition,
  getSizeAndPositionForImageOrVideo,
  pageTypeIsFree,
  pageTypeIsGrid,
  pageTypeIsNotFreeOrGrid,
  pageTypeIsQuestion,
} from '../../utils'
import { Tabs, Tab } from 'react-bootstrap'
import './styles.scss'

const AddImage = ({
  closeModal,
  openCardModal,
  setOpenCardModal,
  currentCellId,
  selectedBlockId,
}) => {
  const dispatch = useDispatch()
  const currentBookId = useSelector((state) => state.books.currentBook)
  const book = useSelector((state) => state.books.books[currentBookId])
  const cells = useSelector((state) => state.cells)
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])
  const user = useSelector((state) => state.user)
  const [imageTypes, setImageTypes] = useState([
    'arasaac',
    'kuvako',
    'mulberry',
    'drawing',
    'sclera',
    'toisto',
    'photo',
    'sign',
  ])

  const chooseImage = (width, height, url, alt, caption) => {
    if (pageTypeIsNotFreeOrGrid(currentPage.type) && currentPage.images.length > 0) {
      dispatch(deleteImage(currentPage.images[0]))
    }
    dispatch(addImage(width, height, url, alt, caption, currentCellId))
    closeModal()
    if (user && !user.anonymous) {
      const updatedBook = {
        ...book,
        updated: Date.now(),
      }
      dispatch(updateBook(updatedBook))
    }
  }

  const chooseBlockImage = (width, height, url, alt, caption) => {
    dispatch(addBlockImage(width, height, url, alt, caption, selectedBlockId))
    closeModal()
    if (user && !user.anonymous) {
      const updatedBook = {
        ...book,
        updated: Date.now(),
      }
      dispatch(updateBook(updatedBook))
    }
  }

  const copyAndChooseImage = async (image, currentCellId) => {
    if (pageTypeIsNotFreeOrGrid(currentPage.type) && currentPage.images.length > 0) {
      dispatch(deleteImage(currentPage.images[0]))
    }
    const res = await copyImage(image.id)
    const coordinates =
      currentPage && !pageTypeIsGrid(currentPage.type)
        ? getSizeAndPositionForImageOrVideo(currentPage)
        : getGridImageSizeAndPosition(currentPage.type, currentCellId)
    const updated = {
      ...res.data,
      pageId: currentPageId,
      x: coordinates && coordinates.x,
      y: coordinates && coordinates.y,
      z: currentPage.images.length > 0 ? currentPage.images.length + 1 : 1,
      w: coordinates && coordinates.w,
      h: coordinates && coordinates.h,
      resizable:
        pageTypeIsFree(currentPage.type) || pageTypeIsQuestion(currentPage.type)
          ? 1
          : 0,
      draggable:
        pageTypeIsFree(currentPage.type) || pageTypeIsQuestion(currentPage.type)
          ? 1
          : 0,
      alt: image.alt,
      caption: pageTypeIsGrid(currentPage.type) ? image.caption : null,
    }
    await updateImage(updated)
    dispatch({
      type: 'ADD_IMAGE',
      data: updated,
    })
    if (currentCellId) {
      dispatch(updateCell({ ...cells[currentCellId], imageId: updated.id }))
    }
    closeModal()
    const updatedBook = {
      ...book,
      updated: Date.now(),
    }
    dispatch(updateBook(updatedBook))
  }

  const copyAndChooseBlockImage = async (image, currentCellId) => {
    console.log('copyAndChooseBlockImage', image, currentCellId)
    // if (pageTypeIsNotFreeOrGrid(currentPage.type) && currentPage.images.length > 0) {
    //   dispatch(deleteImage(currentPage.images[0]))
    // }
    // const res = await copyImage(image.id)
    // const coordinates =
    //   currentPage && !pageTypeIsGrid(currentPage.type)
    //     ? getSizeAndPositionForImageOrVideo(currentPage)
    //     : getGridImageSizeAndPosition(currentPage.type, currentCellId)
    // const updated = {
    //   ...res.data,
    //   pageId: currentPageId,
    //   x: coordinates && coordinates.x,
    //   y: coordinates && coordinates.y,
    //   z: currentPage.images.length > 0 ? currentPage.images.length + 1 : 1,
    //   w: coordinates && coordinates.w,
    //   h: coordinates && coordinates.h,
    //   resizable: pageTypeIsFree(currentPage.type) ? 1 : 0,
    //   draggable: pageTypeIsFree(currentPage.type) ? 1 : 0,
    //   alt: image.alt,
    //   caption: pageTypeIsGrid(currentPage.type) ? image.caption : null,
    // }
    // await updateImage(updated)
    // dispatch({
    //   type: 'ADD_IMAGE',
    //   data: updated,
    // })
    // if (currentCellId) {
    //   dispatch(updateCell({ ...cells[currentCellId], imageId: updated.id }))
    // }
    // closeModal()
    // const updatedBook = {
    //   ...book,
    //   updated: Date.now(),
    // }
    // dispatch(updateBook(updatedBook))
  }

  return (
    <div className="choose-image-modal-container">
      <ModalHeader
        headerText={selectedBlockId ? 'Valitse kuva blokkiin' : 'Valitse kuva'}
        onClick={closeModal}
      />
      <p>
        {user && !user.anonymous
          ? 'Valitse kuva omalta koneelta tai kuvapankista.'
          : 'Valitse kuva Papunetin kuvapankista.'}
      </p>
      <Tabs defaultActiveKey="search" id="uncontrolled-tab-example">
        <Tab eventKey="search" title="Hae kuvia">
          <SearchImageBank
            chooseImage={selectedBlockId ? chooseBlockImage : chooseImage}
            setOpenCardModal={setOpenCardModal}
            closeModal={closeModal}
            imageTypes={imageTypes}
            setImageTypes={setImageTypes}
          />
        </Tab>
        <Tab eventKey="category" title="Selaa kuvia">
          <SearchByCategory
            chooseImage={selectedBlockId ? chooseBlockImage : chooseImage}
            closeModal={closeModal}
            imageTypes={imageTypes}
            setImageTypes={setImageTypes}
          />
        </Tab>
        {user && !user.anonymous && (
          <Tab eventKey="download" title="Tuo omia kuvia">
            <UploadFile
              closeModal={closeModal}
              text={['Sallitut tiedostomuodot: JPG, GIF, PNG.']}
              type={'image'}
              chooseImage={selectedBlockId ? chooseBlockImage : chooseImage}
              selectedBlockId={selectedBlockId ? selectedBlockId : undefined}
            />
          </Tab>
        )}
        {user && !user.anonymous && (
          <Tab eventKey="recent" title="Viimeksi käytetyt">
            <Recent
              chooseImage={selectedBlockId ? chooseBlockImage : chooseImage}
              copyAndChooseImage={
                selectedBlockId ? copyAndChooseBlockImage : copyAndChooseImage
              }
              openCardModal={openCardModal}
              setOpenCardModal={setOpenCardModal}
              closeModal={closeModal}
              currentCellId={currentCellId}
            />
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

AddImage.propTypes = {
  closeModal: propTypes.func.isRequired,
  openCardModal: propTypes.bool.isRequired,
  setOpenCardModal: propTypes.func.isRequired,
  currentCellId: propTypes.number,
  selectedBlockId: propTypes.number,
}

AddImage.defaultProps = {
  currentCellId: null,
}

export default memo(AddImage)
