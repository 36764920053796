import React, { memo } from 'react'
import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Button from '../../Button'
import ValidatedTextInput from '../../ValidatedTextInput'
import ModalContainer from '../../ModalContainer'
import { pageTypeIsGrid } from '../../../utils'

const AddImagePreview = ({
  openCardModal,
  setOpenCardModal,
  closeModal,
  result,
  chooseImage,
  copyAndChooseImage,
  currentCellId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm()
  const currentPageId = useSelector((state) => state.pages.currentPage)
  const currentPage = useSelector((state) => state.pages.pages[currentPageId])

  const closeWithoutSaving = () => {
    reset()
    setOpenCardModal(false)
  }

  const onSubmit = (data) => {
    setOpenCardModal(false)
    if (result.type === 1 && copyAndChooseImage) {
      if (data && (data.alt || data.caption)) {
        const image = {
          ...result,
          pageId: currentPageId,
          data: data && data.alt ? data.alt : result.alt,
          caption: data && data.caption ? data.caption : null,
        }
        copyAndChooseImage(image, currentCellId)
      } else {
        copyAndChooseImage(result, currentCellId)
      }
    } else {
      let filename = result.filename ? result.filename : result.url
      chooseImage(
        filename,
        result.width,
        result.height,
        data.alt,
        data && data.caption ? data.caption : result.name
      )
    }
    closeModal()
  }
  return (
    <ModalContainer
      modalIsOpen={openCardModal}
      info
      closeModal={() => setOpenCardModal(false)}
      label="Esikatsele ja lisää tekstivastine."
    >
      <div className="image-text-input-container">
        <h3>Esikatsele</h3>
        <div className="add-alt-preview-image-container">
          {result && (result.id || result.uid) ? (
            <img
              src={result.filename ? result.filename : result.url}
              alt=""
              width="200px"
              height="auto"
            />
          ) : (
            <p className="image-preview-alternative-text">
              Ei saatavilla esikatselua.
            </p>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {currentPage && pageTypeIsGrid(currentPage.type) && (
            <>
              <ValidatedTextInput
                onChange={() => {
                  clearErrors()
                }}
                label="Muokkaa kuvatekstiä."
                name="caption"
                labelClassName="input-label-class"
                inputClassName="text-input-class"
                value={result.caption || result.name}
                {...register('caption', { maxLength: 255 })}
              />
              <div className="validation-error-text">
                {errors.caption &&
                  errors.caption.type === 'maxLength' &&
                  'Kuvateksti voi olla korkeintaan 255 merkkiä pitkä.'}
              </div>
            </>
          )}
          <ValidatedTextInput
            onChange={() => {
              clearErrors()
            }}
            label="Lisää tekstivastine."
            name="alt"
            labelClassName="input-label-class"
            inputClassName="text-input-class"
            {...register('alt', { maxLength: 255 })}
          />
          <div className="validation-error-text">
            {errors.alt &&
              errors.alt.type === 'maxLength' &&
              'Tekstivastine voi olla korkeintaan 255 merkkiä pitkä.'}
          </div>
          <small>
            Tekstivastine on vapaaehtoinen. Se näkyy ainoastaan ruudunlukijaohjelman
            käyttäjille.
          </small>
          <div className="modal-button-container">
            <Button type="submit" className="dialogue-button select-button">
              Lisää
            </Button>
            <Button
              className="dialogue-button gray-button"
              onClick={closeWithoutSaving}
            >
              Peruuta
            </Button>
          </div>
        </form>
      </div>
    </ModalContainer>
  )
}

AddImagePreview.propTypes = {
  openCardModal: propTypes.bool.isRequired,
  setOpenCardModal: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired,
  result: propTypes.instanceOf(Object).isRequired,
  chooseImage: propTypes.func.isRequired,
  copyAndChooseImage: propTypes.func,
  currentCellId: propTypes.number,
}

AddImagePreview.defaultProps = {
  copyAndChooseImage: null,
  currentCellId: null,
}

export default memo(AddImagePreview)
