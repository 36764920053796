import React, { memo, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  getVideoThumbnail,
  pageLayoutIsHorizontal,
  pageTypeIsGrid,
} from '../../../utils'
import PageThumbnailContents from '../PageThumbnailContents'
import './styles.scss'
import { useViewport } from '../../../hooks/useViewport'
import { getParent } from '../../../utils'

const PageThumbnail = ({ page, showPage, index }) => {
  const [parent, setParent] = useState(null)
  const { width, height } = useViewport()
  const parentRef = React.useRef()
  const loading = useSelector((state) => state.root.loading)
  const images = useSelector((state) => state.images.images)
  const texts = useSelector((state) => state.texts)
  const videos = useSelector((state) => state.videos)
  const cells = useSelector((state) => state.cells)
  const blocks = useSelector((state) => state.blocks)
  const questions = useSelector((state) => state.questions)

  useEffect(() => {
    if (parentRef) {
      setParent(getParent(parentRef))
    }
  }, [parentRef, width, height])

  const getClasses = () => {
    if (page && page.type === 6) {
      return 'grid-template thumbnail-grid-2x2'
    } else if (page && page.type === 7) {
      return 'grid-template thumbnail-grid-3x2'
    } else if (page && page.type === 8) {
      return 'grid-template thumbnail-grid-4x3'
    }
    // DEMO: Määrittää sivupohjavalinta-modalissa painikkeen ikonin
    // else if (page && page.type === 9) {
    //   return 'grid-template thumbnail-grid-4x3'
    // }
    return 'no-grid-template'
  }

  const getHeight = (element) => {
    if (element.w === 0.3 && element.h === 0.3) {
      return 'auto'
    } else {
      return element.h * parent.height || element.height
    }
  }
  return (
    <div
      className="thumbnail-container"
      onClick={() => showPage(page.id)}
      onKeyPress={() => showPage(page.id)}
      style={{ position: 'absolute' }}
    >
      <div
        className={
          pageLayoutIsHorizontal(page.type)
            ? 'thumbnail-content'
            : 'thumbnail-content-vertical-layout'
        }
        style={{ backgroundColor: page.bgColor }}
        ref={parentRef}
      >
        {parent && page && !loading && (
          <div className={getClasses()}>
            {page.images.length > 0 && page.cells.length < 1 && (
              <PageThumbnailContents
                items={page.images.map((id) => images[id])}
                type="image"
                parent={parent}
                pageType={page.type}
              />
            )}
            {page.cells.length > 0 && (
              <PageThumbnailContents
                items={page.cells.map((id) => cells[id])}
                type="cell"
                parent={parent}
                pageType={page.type}
              />
            )}
            {page.blocks.length ? (
              <PageThumbnailContents
                items={page.blocks.map((id) => blocks[id])}
                type="block"
                parent={parent}
                pageType={page.type}
              />
            ) : null}
            {page.questions.length ? (
              <PageThumbnailContents
                items={page.questions.map((id) => questions[id])}
                type="question"
                parent={parent}
                pageType={page.type}
              />
            ) : null}
            {page.videos && page.videos.length > 0 && videos[page.videos[0]] && (
              <div
                style={{
                  width: videos[page.videos[0]].w * parent.width,
                  height: getHeight(videos[page.videos[0]]),
                  position: 'absolute',
                  top: videos[page.videos[0]].y * parent.height,
                  left: videos[page.videos[0]].x * parent.width,
                }}
              >
                <img
                  src={getVideoThumbnail(videos[page.videos[0]])}
                  width="100%"
                  height="100%"
                  alt=""
                  className={
                    videos[page.videos[0]].draggable
                      ? undefined
                      : 'thumbnail-video-content'
                  }
                />
              </div>
            )}
            {page.texts.length > 0 && !pageTypeIsGrid(page.type) && (
              <PageThumbnailContents
                items={page.texts.map((id) => texts[id])}
                type="text"
                parent={parent}
                pageType={page.type}
              />
            )}
          </div>
        )}
      </div>
      <div className="page-number-container">
        <span className="page-number">{index + 1}</span>
      </div>
    </div>
  )
}

PageThumbnail.propTypes = {
  page: propTypes.instanceOf(Object).isRequired,
  showPage: propTypes.func.isRequired,
  index: propTypes.number.isRequired,
}

export default memo(PageThumbnail)
