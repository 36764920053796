import React from 'react'
import propTypes from 'prop-types'
import { Pagination } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PaginationContainer = ({
  results,
  currentPaginationPage,
  setCurrentPaginationPage,
  pagesToDisplay,
  paginationPages,
}) => {
  let nextPage
  return (
    <div className="pagination-buttons-container">
      {results && results && results.length > 0 && (
        <Pagination>
          <Pagination.Prev
            className="pagination-btn arrow-btn"
            role="button"
            onClick={
              currentPaginationPage > 1
                ? () => setCurrentPaginationPage(currentPaginationPage - 1)
                : () => {}
            }
            aria-label="edellinen sivu"
          >
            <FontAwesomeIcon icon="arrow-left" className="pagination-icon" />
          </Pagination.Prev>

          {pagesToDisplay.map((number) => {
            if (!nextPage) {
              nextPage = number
            }
            if (number !== nextPage) {
              nextPage = number + 1
              return (
                <Pagination.Ellipsis
                  key={number}
                  className="pagination-btn pagination-ellipsis"
                />
              )
            } else {
              nextPage = number + 1
            }
            return (
              <Pagination.Item
                role="button"
                tabIndex={0}
                key={number}
                active={currentPaginationPage === number}
                activeLabel={`sivu ${currentPaginationPage}`}
                onClick={() => setCurrentPaginationPage(number)}
                className="pagination-btn"
              >
                {number}
              </Pagination.Item>
            )
          })}
          <Pagination.Next
            role="button"
            className="pagination-btn"
            onClick={
              currentPaginationPage < paginationPages.length
                ? () => setCurrentPaginationPage(currentPaginationPage + 1)
                : () => {}
            }
            aria-label="seuraava sivu"
            tabIndex={0}
          >
            <FontAwesomeIcon icon="arrow-right" className="pagination-icon" />
          </Pagination.Next>
        </Pagination>
      )}
    </div>
  )
}

PaginationContainer.propTypes = {
  results: propTypes.instanceOf(Object).isRequired,
  currentPaginationPage: propTypes.number.isRequired,
  setCurrentPaginationPage: propTypes.func.isRequired,
  pagesToDisplay: propTypes.instanceOf(Object).isRequired,
  paginationPages: propTypes.instanceOf(Object).isRequired,
}

export default PaginationContainer
