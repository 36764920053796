import {
  createQuestion,
  updateQuestion as apiUpdateQuestion,
  deleteQuestion as apiDeleteQuestion,
  questionScaleUp as apiQuestionScaleUp,
  questionScaleDown as apiQuestionScaleDown,
} from '../../api/books/questions'

export const addQuestion = (question) => {
  return async (dispatch) => {
    const res = await createQuestion(question)
    dispatch({
      type: 'ADD_QUESTION',
      data: res.data,
    })
  }
}

export const addQuestionToState = (question) => {
  return async (dispatch) => {
    dispatch({
      type: 'ADD_QUESTION',
      data: question,
    })
  }
}

export const deleteQuestion = (id) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      await apiDeleteQuestion(id)
    }
    dispatch({
      type: 'DELETE_QUESTION',
      data: id,
    })
  }
}

export const initializeQuestions = (questions) => {
  return async (dispatch) => {
    dispatch({
      type: 'INIT_QUESTIONS',
      data: questions,
    })
  }
}

export const updateQuestion = (updated) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiUpdateQuestion(updated)
      dispatch({
        type: 'UPDATE_QUESTION',
        data: res,
      })
    } else {
      dispatch({
        type: 'UPDATE_QUESTION',
        data: updated,
      })
    }
  }
}

export const updateQuestionStore = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_QUESTIONS',
      data,
    })
  }
}

export const questionScaleUp = (questionId) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiQuestionScaleUp(questionId)
      dispatch({
        type: 'QUESTION_SCALE_UP',
        data: res,
      })
    }
  }
}

export const questionScaleDown = (questionId) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiQuestionScaleDown(questionId)
      dispatch({
        type: 'QUESTION_SCALE_DOWN',
        data: res,
      })
    }
  }
}
