import axios from 'axios'
const baseUrl = '/api/books'

const getBookById = async (id) => {
  try {
    const response = await axios.get(`${baseUrl}/${id}`)
    return response
  } catch (e) {
    return e.response.data
  }
}

const getOpikeBookByUrl = async (url) => {
  try {
    const response = await axios.get(`${baseUrl}/opike/book/${url}`)
    return response
  } catch (e) {
    return e.response.data
  }
}

const getBookByIdAndUrl = async (id, url) => {
  try {
    const response = await axios.get(`${baseUrl}/lue/${id}/${url}`)
    return response.data
  } catch (e) {
    return e.response.data
  }
}

const getAllBookPages = async (books) => {
  const booksArray = await Promise.all(
    books.map((book) => {
      return getBookById(book.id)
    })
  )
  return booksArray
}

const copyBook = async (id, name, description) => {
  try {
    return await axios.post(`${baseUrl}/${id}/copy`, {
      id: id,
      name: name,
      desc: description,
    })
  } catch (e) {
    return e.response.data
  }
}

const createBook = (authorId, name, description) => {
  try {
    const newBook = {
      authorId,
      name,
      description,
      bgColor: 16777215,
      status: 0,
    }
    return axios.post(baseUrl, newBook)
  } catch (e) {
    return e.response.data
  }
}

const updateBook = async (updatedBook) => {
  const response = await axios.put(`${baseUrl}/${updatedBook.id}`, updatedBook)
  return response.data
}

const deleteBook = async (bookId) => {
  return axios.delete(`${baseUrl}/${bookId}`)
}

const deleteAllUserBooks = (books) => {
  const promises = books.map(async (b) => {
    await deleteBook(b.id)
  })
  return promises
}

const getPublicEditableBooks = async () => {
  return await axios.get(`${baseUrl}/editable`)
}

export {
  copyBook,
  getAllBookPages,
  getBookById,
  getOpikeBookByUrl,
  getBookByIdAndUrl,
  getPublicEditableBooks,
  createBook,
  updateBook,
  deleteBook,
  deleteAllUserBooks,
}
