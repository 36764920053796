import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../../Button'
import ValidatedTextInput from '../../../ValidatedTextInput'
import { updateUserInState } from '../../../../store/user/userAction'
import { updateUser } from '../../../../api/users/users'
import { useForm } from 'react-hook-form'

const EditEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm()
  const user = useSelector((state) => state.user)
  const loading = useSelector((state) => state.root.loading)
  const dispatch = useDispatch()
  const [response, setResponse] = useState(null)

  const onSubmit = async (data) => {
    try {
      setResponse(null)
      if (user && !user.error) {
        const updatedData = {
          username: data.email.trim().toLowerCase(),
          email: data.email.trim().toLowerCase(),
        }
        const res = await updateUser(user.id, updatedData)
        dispatch(updateUserInState())
        setResponse(res)
        reset()
      }
    } catch (e) {
      setResponse('Sähköpostin vaihto ei onnistunut.')
    }
  }

  return (
    <>
      {!loading && (
        <div className="edit-account-container edit-email-container">
          <h3>Vaihda sähköpostiosoite</h3>
          <form
            className="edit-account-form input-and-save-container"
            onSubmit={handleSubmit(onSubmit)}
          >
            <ValidatedTextInput
              label="Sähköpostiosoite"
              name="email"
              placeHolder="Kirjoita sähköpostiosoite..."
              onChange={() => clearErrors()}
              labelClassName="input-label-class"
              inputClassName="text-input-field"
              {...register('email', {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                maxLength: 254,
              })}
            />
            <div className="validation-error-text">
              {errors.email &&
                errors.email.type === 'required' &&
                'Kirjoita sähköpostiosoite.'}
              {errors.email &&
                errors.email.type === 'pattern' &&
                'Sähköpostiosoite ei kelpaa.'}
              {errors.email &&
                errors.email.type === 'maxLength' &&
                'Sähköpostiosoite on liian pitkä.'}
            </div>
            <p
              className={
                response && response.error
                  ? 'backend-response-text red-text'
                  : 'backend-response-text success-text'
              }
            >
              {response && response.message}
            </p>
            <Button
              className="dialogue-button select-button edit-account-button"
              type="submit"
            >
              <div className="edit-account-button-content">
                <FontAwesomeIcon
                  icon={['far', 'save']}
                  className="save-edited-icon"
                />
                Tallenna
              </div>
            </Button>
          </form>
        </div>
      )}
    </>
  )
}

export default memo(EditEmail)
