import React, { memo, useEffect } from 'react'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Button from '../Button'
import ModalContainer from '../ModalContainer'
import { updateCell } from '../../store/cells/cellAction'
import { deleteBlockImage } from '../../store/blockImages/blockImageAction'

const DeleteItemDialogue = ({
  openDeleteContentModal,
  setOpenDeleteContentModal,
  deleteItem,
  itemId,
  cell,
  textContent,
  deletingBlockImage,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (cell && !cell.imageId) {
      deleteItem(itemId)
    }
  }, [cell])

  const deleteItemAndCloseModal = () => {
    if (deletingBlockImage) {
      dispatch(deleteBlockImage(itemId))
    } else if (cell) {
      dispatch(updateCell({ ...cell, imageId: null, soundId: null }))
      // item is then deleted in use effect
    } else {
      deleteItem(itemId)
    }
    setOpenDeleteContentModal(false)
  }

  return (
    <ModalContainer
      modalIsOpen={openDeleteContentModal}
      closeModal={() => setOpenDeleteContentModal(false)}
      label="Haluatko poistaa sisällön"
      info
    >
      <div className="info-modal-content">
        <p className="modal-text">{textContent[0]}</p>
        <div className="modal-button-container">
          <Button
            className="dialogue-button danger-button"
            onClick={deleteItemAndCloseModal}
          >
            {textContent[1]}
          </Button>
          <Button
            className="dialogue-button gray-button"
            onClick={() => setOpenDeleteContentModal(false)}
          >
            Peruuta
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}

DeleteItemDialogue.propTypes = {
  openDeleteContentModal: propTypes.bool.isRequired,
  setOpenDeleteContentModal: propTypes.func.isRequired,
  deleteItem: propTypes.func,
  itemId: propTypes.number,
  cell: propTypes.instanceOf(Object),
  textContent: propTypes.instanceOf(Array).isRequired,
  deletingBlockImage: propTypes.bool,
}

DeleteItemDialogue.defaultProps = {
  itemId: null,
  cell: null,
}

export default memo(DeleteItemDialogue)
