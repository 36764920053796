import {
  addCell as apiAddCell,
  updateCell as apiUpdateCell,
} from '../../api/books/cells'
import { anonymousGetId } from '../../utils'

export const addCell = (cell) => {
  return async (dispatch, getState) => {
    const state = getState()
    const user = state.user
    if (!user.anonymous) {
      const res = await apiAddCell(cell)
      dispatch({
        type: 'ADD_CELL',
        data: res.data,
      })
    } else {
      const cellWithId = { ...cell, id: anonymousGetId(state.cells) }
      dispatch({
        type: 'ADD_CELL',
        data: cellWithId,
      })
    }
  }
}

export const initializeCells = (cells) => {
  return async (dispatch) => {
    dispatch({
      type: 'INIT_CELLS',
      data: cells,
    })
  }
}

export const updateCell = (updated) => {
  return async (dispatch, getState) => {
    const state = getState()
    if (state.user && !state.user.anonymous) {
      const res = await apiUpdateCell(updated)
      dispatch({
        type: 'UPDATE_CELL',
        data: res,
      })
    } else {
      dispatch({
        type: 'UPDATE_CELL',
        data: updated,
      })
    }
  }
}

export const updateCellStore = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_CELLS',
      data,
    })
  }
}
