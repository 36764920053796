import React from 'react'
import propTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useAuth } from './hooks/useAuth'

const PrivateRoute = ({ children }) => {
  const auth = useAuth()
  return auth ? children : <Navigate to="/" />
}

PrivateRoute.propTypes = {
  children: propTypes.oneOfType([propTypes.instanceOf(Object), propTypes.func])
    .isRequired,
}

export default PrivateRoute
