import React, { memo } from 'react'
import LoginPage from '../components/User/LoginPage'
import TopNavigation from '../components/Navigation/TopNavigation'

const LoginPagesContainer = () => {
  return (
    <>
      <TopNavigation />
      <LoginPage />
    </>
  )
}

export default memo(LoginPagesContainer)
