import React, { memo, useState } from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddImage from '../../../AddImage'
import Button from '../../../Button'
import EditCell from '../EditCell'
import DeleteItemDialogue from '../../../DeleteItemDialogue'
import ModalContainer from '../../../ModalContainer'
import '../styles.scss'

const Cell = ({ parent, cell, moveItem, removeImage, maxIndex /*pageType*/ }) => {
  const [openDeleteContentModal, setOpenDeleteContentModal] = useState(false)
  const [openEditCellModal, setOpenEditCellModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openCardModal, setOpenCardModal] = useState(false)
  const images = useSelector((state) => state.images.images)

  const getWidth = () => {
    return cell.w * parent.width
  }

  const getHeight = () => {
    return cell.h * parent.height
  }

  return (
    <>
      {images && cell.imageId !== null && images[cell.imageId] ? (
        <div
          style={{
            width: parent && getWidth(),
            height: parent && getHeight(),
            padding: '2%',
          }}
          className="uploaded-image-container"
        >
          {/* DEMO: Ehtolause, joka asettaa ruudukkopohjan ruudukoissa tekstin kuvan yläpuolelle
           {pageType !== 9 ? ( */}
          <div className="cell-image-caption">
            {images[cell.imageId].caption && <p>{images[cell.imageId].caption}</p>}
          </div>
          {/* ) : null}{' '} */}
          {/* eslint-disable-line prettier/prettier */}
          <Button
            className="edit-image-button"
            onClick={() => setOpenEditCellModal(true)}
            role="link"
            label="muokkaa kuvan tekstivastinetta"
          >
            <FontAwesomeIcon
              icon={['far', 'edit']}
              size={
                cell.w * parent.width < 150 && cell.h * parent.height < 150
                  ? 'xs'
                  : '1x'
              }
            />
          </Button>
          <Button
            className="delete-item-button"
            onClick={() => setOpenDeleteContentModal(true)}
            role="link"
            label="Poista kuva."
          >
            <FontAwesomeIcon icon="times" className="cancel-icon" />
          </Button>
          {cell.soundId && (
            <FontAwesomeIcon
              icon="volume-up"
              className="audio-cell-icon"
              size="1x"
            />
          )}
          {cell.position > 0 && (
            <Button
              className="arrow-button move-left-arrow-button"
              onClick={() => moveItem(cell, 'left')}
              label="Siirrä vasemmalle."
            >
              <FontAwesomeIcon
                icon="arrow-left"
                className="icon"
                style={{ marginRight: '5px' }}
              />
            </Button>
          )}
          {cell.position < maxIndex && (
            <Button
              className="arrow-button move-right-arrow-button"
              onClick={() => moveItem(cell, 'right')}
              label="Siirrä oikealle."
            >
              <FontAwesomeIcon
                icon="arrow-right"
                className="icon"
                style={{ marginRight: '5px' }}
              />
            </Button>
          )}
          <img
            src={images[cell.imageId].filename}
            alt={images[cell.imageId].alt ? images[cell.imageId].alt : ''}
            height="85%"
            width="100%"
            className="uploaded-image-content"
          />
          {/* DEMO: Ehtolause, joka asettaa ruudukkopohjan ruudukoissa tekstin kuvan alapuolelle
          {pageType && pageType === 9 ? (
            <div className="cell-image-caption">
              {images[cell.imageId].caption && <p>{images[cell.imageId].caption}</p>}
            </div>
          ) : null}{' '} */}
          {/* eslint-disable-line prettier/prettier */}
        </div>
      ) : (
        <div
          style={{
            width: parent && getWidth(),
            height: parent && getHeight(),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button className="add-image-button" onClick={() => setOpenModal(true)}>
            <div>
              <FontAwesomeIcon icon={['far', 'image']} className="add-image-icon" />
              <span className="tool-button-text add-image-button-text">
                Lisää kuva
              </span>
            </div>
          </Button>
        </div>
      )}
      {openDeleteContentModal && cell.imageId && (
        <DeleteItemDialogue
          openDeleteContentModal={openDeleteContentModal}
          setOpenDeleteContentModal={setOpenDeleteContentModal}
          deleteItem={removeImage}
          itemId={cell.imageId}
          cell={cell}
          textContent={['Haluatko varmasti poistaa kuvan?', 'Kyllä, poista']}
        />
      )}
      {openEditCellModal && (
        <EditCell
          openEditImageModal={openEditCellModal}
          setOpenEditImageModal={setOpenEditCellModal}
          image={images[cell.imageId]}
          cell={cell}
        />
      )}
      {openModal && (
        <ModalContainer
          modalIsOpen={openModal}
          closeModal={() => setOpenModal(false)}
          label="Valitse kuva tai video."
          styleName="choose-image"
        >
          <AddImage
            closeModal={() => setOpenModal(false)}
            openCardModal={openCardModal}
            setOpenCardModal={setOpenCardModal}
            currentCellId={cell.id}
          />
        </ModalContainer>
      )}
    </>
  )
}

Cell.propTypes = {
  parent: propTypes.instanceOf(Object).isRequired,
  cell: propTypes.instanceOf(Object).isRequired,
  removeImage: propTypes.func,
  moveItem: propTypes.func,
  maxIndex: propTypes.number.isRequired,
  pageType: propTypes.number,
}

Cell.defaultProps = {
  removeImage: null,
  moveItem: null,
}

export default memo(Cell)
