import React from 'react'
import propTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../Button'

const ModalHeader = ({ headerText, onClick }) => {
  return (
    <div className="header-container">
      <h1>{headerText}</h1>
      <Button className="close-btn" onClick={onClick} label="Sulje">
        <FontAwesomeIcon
          icon={['far', 'times-circle']}
          className="template-icon"
          size="2x"
        />
      </Button>
    </div>
  )
}

ModalHeader.propTypes = {
  headerText: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
}

export default ModalHeader
