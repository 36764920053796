import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../Button'
import LoginForm from '../LoginForm'
import ModalContainer from '../../ModalContainer'
import RegistrationModal from '../RegistrationModal'
import etusivu from './img/etusivu.png'
import './styles.scss'

const LoginPage = () => {
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false)

  return (
    <div id="login-container">
      <div className="frontpage-left-content">
        <LoginForm />
        <div className="login-form-container">
          <div>
            <h2 className="login-page-h2">Rekisteröidy</h2>
            <p>
              Jos sinulla ei ole tunnusta työkaluun, rekisteröidy ja tilaa tunnukset
              tästä.
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              className="login-page-button login-button"
              onClick={() => setOpenRegistrationModal(true)}
            >
              <div className="icon-button-content">
                <FontAwesomeIcon icon={['far', 'edit']} className="info-icon" />
                Rekisteröidy
              </div>
            </Button>
            <a
              href="https://papunet.net/tietosuojaseloste_tyokalut.html"
              className="link-button"
              tabIndex={0}
            >
              Tietosuoja&shy;seloste
            </a>
          </div>
        </div>
        <div className="login-form-container">
          <div>
            <h2 className="login-page-h2">Kokeile</h2>
            <p>
              Voit myös kokeilla työkalua ilman kirjautumista. Kokeilukirjaa ei voi
              tallentaa tai lähettää.
            </p>
          </div>
          <Link className="link-button login-page-button" to="/kokeile">
            <div className="icon-button-content">
              <FontAwesomeIcon icon={['far', 'edit']} className="info-icon" />
              Kokeile
            </div>
          </Link>
        </div>
        {openRegistrationModal && (
          <ModalContainer
            modalIsOpen={openRegistrationModal}
            closeModal={() => setOpenRegistrationModal(false)}
            label="Rekisteröidy"
          >
            <RegistrationModal setOpenRegistrationModal={setOpenRegistrationModal} />
          </ModalContainer>
        )}
      </div>
      <div className="frontpage-right-content">
        <img
          className="frontpage-image"
          src={etusivu}
          alt="Kuva Papurin käyttöliittymästä, jossa keltainen auringonkukka ja teksti auringonkukka."
          width="100%"
          height="auto"
        />
        <h2>Tervetuloa käyttämään uudistunutta Papuria!</h2>
        <p id="papuri-introduction">
          Papuri on työkalu, jolla voi tehdä verkkokirjoja. Kirjoissa voi käyttää
          tekstiä, kuvaa, videota ja äänitiedostoja.
        </p>
        <p>
          Papurilla voit luoda esimerkiksi omia tarinoita, selkokielisiä
          tietokirjoja, toimintaohjeita tai valokuva-albumeita. Mitä ikinä
          keksitkään.
        </p>
      </div>
    </div>
  )
}

export default memo(LoginPage)
